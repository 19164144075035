import {BusOption, DriverOption, useAppDispatch, useAppSelector} from "../../../../hooks";
import React from "react";
import {
    selectHasRestTimeLessThan9HoursWarning,
    selectPrimaryResourceNameById,
    selectSecondaryResourceNameById
} from "../store/selectors";
import {ResourceType, SaveWorkSheet, WorkScheduleItemStatus, WorkSheet} from "../../../../API/types";
import {createWorkSheet, generateWorkSheet, updateWorkSheet} from "../store/actions";
import {Box, TableBody, TableCell, TableRow, Tooltip} from "@mui/material";
import dayjs from "dayjs";
import Select from "../components/Select";
import {Check, DoneAll, WarningAmberRounded} from "@mui/icons-material";
import routes from "../../../../routes";
import {blue, green, grey} from "@mui/material/colors";
import {selectToggledResourceType} from "../../../../store/viewSlice";
import {getOppositeResourceType} from "../../../../utils/utils";
import {TableCellProps} from "@mui/material/TableCell/TableCell";
import {selectRegion} from "../../../../store/regionSlice";
import ActionIcon from "../../../../components/Icon/ActionIcon";
import NavIcon from "../../../../components/Icon/NavIcon";
import StaticIcon from "../../../../components/Icon/StaticIcon";
import {formatTimeAtDate} from "../../../../utils/dateUtils";
import {selectBusesWithDefects} from "../../../../store/workScheduleItemSlice";

const getJointStartTime = (primary: WorkSheet, secondary: WorkSheet | null) => {
    if (!secondary) {
        return primary.startTime ? formatTimeAtDate(primary.startTime, primary.date) : null;
    }
    if (primary.startTime !== secondary.startTime && primary.startTime && secondary.startTime) {
        return `${formatTimeAtDate(secondary.startTime, secondary.date)} [${formatTimeAtDate(primary.startTime, primary.date)}]`;
    }

    return secondary.startTime ? formatTimeAtDate(secondary.startTime, secondary.date) : null;
};

const getJointEndTime = (primary: WorkSheet, secondary: WorkSheet | null) => {
    if (!secondary) {
        return primary.endTime ? formatTimeAtDate(primary.endTime, primary.date) : null;
    }
    if (primary.endTime !== secondary.endTime && primary.endTime && secondary.endTime) {
        return `${formatTimeAtDate(secondary.endTime, secondary.date)} [${formatTimeAtDate(primary.endTime, primary.date)}]`;
    }

    return secondary.endTime ? formatTimeAtDate(secondary.endTime, secondary.date) : null;
};

const isAnyWorkSheetConfirmed = (workSheet: WorkSheet): boolean => {
    let isConfirmed = workSheet.status === WorkScheduleItemStatus.CONFIRMED;

    if (!isConfirmed) {
        for (const connectedWorkSheet of workSheet.connectedWorkSheets) {
            if (connectedWorkSheet.status === WorkScheduleItemStatus.CONFIRMED) {
                isConfirmed = true;
                break;
            }
        }
    }

    return isConfirmed;
};

const renderDisabledResourceCell = (resourceName: string) => {
    return (
        <Tooltip placement="top" arrow title={
            'Muutmine ei ole lubatud, sest sõiduleht või sõidulehele määratud juhi/bussiga sel päeval seotud '
            + 'sõiduleht on tagastatud staatuses'
        }>
            <Box sx={{display: 'flex', alignItems: 'center', pl: 1, minHeight: '30px'}}>{resourceName}</Box>
        </Tooltip>
    )
};

interface WorkGroupCodeTableCellProps extends TableCellProps {
    workSheet: WorkSheet | null,
    selectedRegionId: number,
}

function getOtherRegionBackground(primaryWorkSheet: WorkSheet) {
    let stripeColor: string = grey[200];
    if (primaryWorkSheet.status === WorkScheduleItemStatus.GENERATED) {
        stripeColor = green[100];
    } else if (primaryWorkSheet.status === WorkScheduleItemStatus.CONFIRMED) {
        stripeColor = blue[100];
    }
    return {
        background: `repeating-linear-gradient(
            -45deg,
            transparent 0px,
            transparent 10px,
            ${stripeColor} 10px,
            ${stripeColor} 15px
        )`
    };
}

const WorkGroupCodeTableCell = ({workSheet, selectedRegionId, ...otherProps}: WorkGroupCodeTableCellProps) => {
    const region = useAppSelector(state => selectRegion(state, workSheet?.regionId ?? selectedRegionId));

    if (!workSheet || !workSheet.workGroupId) {
        return <TableCell {...otherProps}><Box sx={{color: 'text.secondary', fontStyle: 'italic'}}>puudub</Box></TableCell>
    }

    if (workSheet.regionId !== selectedRegionId) {
        const sx = getOtherRegionBackground(workSheet);

        return <Tooltip arrow placement="top" title={region?.name}>
            <TableCell sx={{...sx}}{...otherProps}>{workSheet.workGroupCode}</TableCell>
        </Tooltip>;
    } else {
        return <TableCell {...otherProps}>{workSheet.workGroupCode}</TableCell>;
    }
};

const SecondaryResource = ({isFirst, isLast, primaryWorkSheet, connectedWorkSheet, secondaryResourceOptions, isResourceSelectDisabled}: {
    isFirst: boolean,
    isLast: boolean,
    primaryWorkSheet: WorkSheet,
    connectedWorkSheet: WorkSheet | null,
    secondaryResourceOptions: DriverOption[] | BusOption[],
    isResourceSelectDisabled: boolean
}) => {
    const secondaryResourceName = useAppSelector((state) => selectSecondaryResourceNameById(state, connectedWorkSheet?.resourceId ?? 0));

    const date = useAppSelector(state => state.view.date);
    const selectedRegionId = useAppSelector(state => state.region.selected);
    const dispatch = useAppDispatch();
    const resourceType = useAppSelector(selectToggledResourceType);
    const busesWithDefects = useAppSelector(selectBusesWithDefects);

    const handleChangeResource = (event: React.ChangeEvent<HTMLInputElement>) => {
        const data: SaveWorkSheet = {
            workGroupId: connectedWorkSheet?.workGroupId ?? null,
            resourceId: event.target.value !== '' ? parseInt(event.target.value) : null,
            resourceType: getOppositeResourceType(resourceType),
            date: date,
            regionId: selectedRegionId,
            status: connectedWorkSheet?.status ?? WorkScheduleItemStatus.SCHEDULED,
        };
        if (connectedWorkSheet?.id) {
            dispatch(updateWorkSheet({id: connectedWorkSheet.id, ...data}));
        } else {
            dispatch(createWorkSheet(data));
        }
    };

    const startTime = isFirst
        ? getJointStartTime(primaryWorkSheet, connectedWorkSheet)
        : connectedWorkSheet?.startTime ? dayjs(connectedWorkSheet.startTime).format('HH:mm') : null;
    const endTime = isLast
        ? getJointEndTime(primaryWorkSheet, connectedWorkSheet)
        : connectedWorkSheet?.endTime ? dayjs(connectedWorkSheet.endTime).format('HH:mm') : null;
    const distance = connectedWorkSheet?.distance
        ? (connectedWorkSheet.distance > 0 ? connectedWorkSheet.distance : null)
        : (isFirst && isLast && primaryWorkSheet.distance && primaryWorkSheet.distance > 0) ? primaryWorkSheet.distance : null;
    const displayDistance = distance ? `${distance.toLocaleString('et-EE', {maximumFractionDigits: 2})} km` : null;

    return (
        <>
            <WorkGroupCodeTableCell
                workSheet={connectedWorkSheet}
                selectedRegionId={selectedRegionId}
            />
            <TableCell>
                {connectedWorkSheet &&
                    (isResourceSelectDisabled ?
                        renderDisabledResourceCell(secondaryResourceName)
                        :
                        <Select
                            value={connectedWorkSheet.resourceId ?? null}
                            onChange={handleChangeResource}
                            options={connectedWorkSheet.resourceId ?
                                [
                                    {
                                        id: connectedWorkSheet.resourceId,
                                        name: secondaryResourceName,
                                        defects: connectedWorkSheet.resourceType === ResourceType.VEHICLE
                                            ? busesWithDefects.find(bus => bus.id === connectedWorkSheet.resourceId)?.defects ?? undefined
                                            : undefined
                                    } as (DriverOption | BusOption),
                                    ...secondaryResourceOptions,
                                ] : secondaryResourceOptions
                            }
                            resourceType={connectedWorkSheet.resourceType}
                        />
                    )
                }
            </TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{startTime}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{endTime}</TableCell>
            <TableCell>{displayDistance}</TableCell>
        </>
    )
};

export default function WorkSheetListRow({workSheet, availableDrivers, availableBuses}: {
    workSheet: WorkSheet,
    availableDrivers: DriverOption[],
    availableBuses: BusOption[],
}) {
    const hasRestTimeLessThan9HoursWarning = useAppSelector(state => selectHasRestTimeLessThan9HoursWarning(state, workSheet));
    const resourceType = useAppSelector(selectToggledResourceType);
    const date = useAppSelector(state => state.view.date);
    const selectedRegionId = useAppSelector(state => state.region.selected);
    const busesWithDefects = useAppSelector(selectBusesWithDefects);
    const dispatch = useAppDispatch();

    const primaryResourceName = useAppSelector((state) => selectPrimaryResourceNameById(state, workSheet?.resourceId ?? 0));

    const primaryResourceOptions = resourceType === ResourceType.DRIVER ? availableDrivers : availableBuses;
    const secondaryResourceOptions = resourceType === ResourceType.VEHICLE ? availableDrivers : availableBuses;

    const isResourceSelectDisabled = isAnyWorkSheetConfirmed(workSheet);

    const handleChangeResource = (event: React.ChangeEvent<HTMLInputElement>) => {
        const data: SaveWorkSheet = {
            workGroupId: workSheet?.workGroupId ?? null,
            resourceId: event.target.value !== '' ? parseInt(event.target.value) : null,
            resourceType: resourceType,
            date: date,
            regionId: selectedRegionId,
            status: workSheet?.status ?? WorkScheduleItemStatus.SCHEDULED,
        };
        if (workSheet?.id) {
            dispatch(updateWorkSheet({id: workSheet.id, ...data}));
        } else {
            dispatch(createWorkSheet(data));
        }
    };

    const connectedRows: (WorkSheet | null)[] =
        [...(workSheet.connectedWorkSheets ?? [])]
            .sort((a, b) =>
                !a.startTime ? 1 : (!b.startTime ? -1 : (a.startTime.localeCompare(b.startTime)))
            );

    if (connectedRows.length === 0) {
        connectedRows.push(null);
    }

    const handleGenerateWorkSheetClick = () => {
        if (!workSheet.id) {
            throw new Error('Cannot generate work sheet without');
        }
        if (workSheet.status === WorkScheduleItemStatus.SCHEDULED || confirm('Sõiduleht on juba genereeritud. Kas soovid uuesti genereerida?')) {
            dispatch(generateWorkSheet({id: workSheet.id, date: workSheet.date}));
        }
    };

    const rowSpan = connectedRows.length;

    return (
        <TableBody sx={{
            backgroundColor: workSheet.status === WorkScheduleItemStatus.GENERATED
                ? green[50]
                : workSheet.status === WorkScheduleItemStatus.CONFIRMED
                    ? blue[50]
                    : undefined
        }}>
            {connectedRows.map((row, index) =>
                <TableRow key={`${workSheet.id}_${workSheet.workGroupId}_${row?.id}_${index}`}>
                    {index === 0 &&
                        <TableCell rowSpan={rowSpan}>
                            <Box displayPrint="none">
                            {hasRestTimeLessThan9HoursWarning
                                ?
                                <Tooltip title="Kahe tööpäeva vaheline aeg jääb alla 9 tunni." arrow placement="top">
                                    <WarningAmberRounded sx={{verticalAlign: 'middle', color: 'warning.light'}} />
                                </Tooltip>
                                :
                                workSheet.status === WorkScheduleItemStatus.CONFIRMED
                                    ? <DoneAll sx={{verticalAlign: 'middle', color: 'primary.main'}} />
                                    : workSheet.status === WorkScheduleItemStatus.GENERATED ?
                                        <Check sx={{verticalAlign: 'middle', color: 'secondary.main'}} />
                                        : ''
                            }
                            </Box>
                        </TableCell>
                    }
                    {index === 0 &&
                        <WorkGroupCodeTableCell
                            workSheet={workSheet}
                            selectedRegionId={selectedRegionId}
                            rowSpan={rowSpan}
                        />
                    }
                    {index === 0 &&
                        <TableCell rowSpan={rowSpan}>
                            {isResourceSelectDisabled ?
                                renderDisabledResourceCell(primaryResourceName)
                                :
                                <Select
                                    value={workSheet.resourceId}
                                    onChange={handleChangeResource}
                                    options={workSheet.resourceId ?
                                        [
                                            {
                                                id: workSheet.resourceId,
                                                name: primaryResourceName,
                                                defects: workSheet.resourceType === ResourceType.VEHICLE
                                                    ? busesWithDefects.find(bus => bus.id === workSheet.resourceId)?.defects ?? undefined
                                                    : undefined
                                            } as (DriverOption | BusOption),
                                            ...primaryResourceOptions,
                                        ] : primaryResourceOptions
                                    }
                                    resourceType={workSheet.resourceType}
                                />
                            }
                        </TableCell>
                    }
                    <SecondaryResource
                        isFirst={index === 0}
                        isLast={index + 1 === rowSpan}
                        primaryWorkSheet={workSheet}
                        connectedWorkSheet={row}
                        secondaryResourceOptions={secondaryResourceOptions}
                        isResourceSelectDisabled={isResourceSelectDisabled}
                    />
                    {index === 0 &&
                        <TableCell sx={{textAlign: 'right', width: 105}} rowSpan={rowSpan}>
                            <Box displayPrint="none">
                                {(workSheet.status === WorkScheduleItemStatus.GENERATED || workSheet.status === WorkScheduleItemStatus.CONFIRMED) && workSheet.id &&
                                    <NavIcon type="EDIT" id={workSheet.id} route={routes.AUTHENTICATED.WORK_SHEETS.DETAILS} />
                                }
                                {workSheet.id ?
                                    <ActionIcon
                                        type="GENERATE"
                                        id={workSheet.id}
                                        handleClick={handleGenerateWorkSheetClick}
                                        tooltipTitle={workSheet.status === WorkScheduleItemStatus.SCHEDULED ? 'Genereeri sõiduleht' : 'Genereeri uuesti'}
                                        disabled={!workSheet.id || workSheet.status === WorkScheduleItemStatus.CONFIRMED || !workSheet.workGroupId}
                                    />
                                    :
                                    <StaticIcon type="GENERATE" tooltipTitle="Genereeri sõiduleht" disabled={true} />
                                }
                            </Box>
                        </TableCell>
                    }
                </TableRow>
            )}
        </TableBody>
    );
}
