import React, {useEffect, useState} from 'react';
import {Box, useMediaQuery} from '@mui/material';
import {TransportContract} from "../../../../API/types";
import {GridColDef} from '@mui/x-data-grid';
import {filterByActive, filterByValue} from "../../../../utils/utils";
import theme from "../../../../theme";
import {ActiveToggleButtonType, activeToggleButtonValues, Permission} from "../../../../types";
import {useNavigate, useOutletContext} from 'react-router-dom';
import DataGrid from "../../../../components/DataGrid/DataGrid";
import routes from "../../../../routes";
import {getDisplayDate} from "../../../../utils/dateUtils";
import NavLink from "../../../../components/NavLink/NavLink";
import NavIcon from "../../../../components/Icon/NavIcon";
import StaticIcon from "../../../../components/Icon/StaticIcon";
import {SearchContextType} from "../../../../layouts/SearchLayoutWrapper";
import {useAuth} from "../../../../contexts/AuthContext";
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import ListView from "../../../../layouts/ListViewWrapper";
import {fetchTransportContractsByUser, selectAllTransportContracts} from "../../../../store/transportContractsSlice";


export default function TransportContracts() {
    const dispatch = useAppDispatch();
    const { authenticatedUser } = useAuth();
    const navigate = useNavigate();
    const {searchInput, setSearchInput, paginationModel, setPaginationModel} = useOutletContext<SearchContextType>();
    const rows = useAppSelector(selectAllTransportContracts);
    const [toggleButtonValue, setToggleButtonValue] = useState<ActiveToggleButtonType>('AKTIIVSED');
    const isScreenSmall = useMediaQuery(theme.breakpoints.down('lg'));

    useEffect(() => {
        dispatch(fetchTransportContractsByUser(authenticatedUser))
    }, [authenticatedUser]);

    const handleAddTransportContract = () => {
        navigate(routes.AUTHENTICATED.TRANSPORT_CONTRACTS.ADD);
    }

    const handleToggleButtonChange = (value: string) => {
        if (activeToggleButtonValues.includes(value as ActiveToggleButtonType)) setToggleButtonValue(value as ActiveToggleButtonType);
    }

    const getFilteredRows = (rows: TransportContract[]) => {
        return filterByActive(filterByValue(rows, searchInput), toggleButtonValue);
    }

    const columns: GridColDef[] = [
        {
            field: 'contractId',
            headerName: 'Lepingu nr',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 120 : 140,
            renderCell: (params) =>
                <NavLink id={params.row.id} value={params.row.contractId} route={routes.AUTHENTICATED.TRANSPORT_CONTRACTS.EDIT} navPermission={Permission.UpdateTransportContracts} />
        },
        {
            field: 'profitCenter',
            headerName: 'Tulemusüksus',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 110 : 130,
        },
        {
            field: 'dateOfSigning',
            headerName: 'Sõlmimise kuupäev',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 120 : 160,
            renderCell: (params) => params.value ?
                <Box style={{minWidth: 'fit-content'}}>{getDisplayDate(params.value)}</Box> : <></>
        },
        {
            field: 'partnerName',
            headerName: 'Partneri nimi',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 120 : 160,
        },
        {
            field: 'validFrom',
            headerName: 'Kehtivuse algus',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 120 : 140,
            renderCell: (params) => params.value ?
                <Box style={{minWidth: 'fit-content'}}>{getDisplayDate(params.value)}</Box> : <></>
        },
        {
            field: 'validTo',
            headerName: 'Kehtivuse lõpp',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 120 : 140,
            renderCell: (params) => params.value ?
                <Box style={{minWidth: 'fit-content'}}>{getDisplayDate(params.value)}</Box> : <></>
        },
        {
            field: 'regionName',
            headerName: 'Piirkond',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 90 : 120,
        },
        {
            field: 'active',
            headerName: 'Aktiivne',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 80 : 100,
            renderCell: params => params.value ? <StaticIcon type="CHECK" /> : ''
        },
        {
            field: 'comment',
            headerName: 'Kommentaar',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 140 : 300,
        },
        {
            field: 'Menüü',
            headerName: '',
            sortable: false,
            filterable: false,
            align: 'right',
            flex: 1,
            minWidth: 50,
            renderCell: (params) =>
                <NavIcon type="EDIT" id={params.row.id} route={routes.AUTHENTICATED.TRANSPORT_CONTRACTS.EDIT} navPermission={Permission.UpdateTransportContracts} />
        }
    ];

    return (
        <ListView
            headerProps={{
                title: 'Veolepingud',
                searchProps: {input: searchInput, setInput: setSearchInput},
                buttonProps: {
                    title: 'Lisa veoleping',
                    onClick: handleAddTransportContract,
                    permission: Permission.UpdateTransportContracts
                },
                toggleProps: {
                    options: activeToggleButtonValues.map(value => value.toString()),
                    value: toggleButtonValue,
                    handleChange: handleToggleButtonChange
                },
            }}
            isLoading={!rows}
        >
            <DataGrid
                rows={getFilteredRows(rows ?? [])}
                columns={columns}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
            />
        </ListView>
    );
}
