import NewWorkGroupVersionDialog
    from "../../../../../scenes/authenticated/workGroups/listView/components/NewVersionDialog";
import React from "react";
import {WorkGroup} from "../../../../../API/workGroup/types";
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {clearDialogData, selectDialogData, updateWorkGroupValidDatesAndActivities} from "../../../../../store/plannerSlice";
import {selectSelectedDay} from "../../../../../scenes/authenticated/workSheets/store/selectors";
import {findClosestItemByValidRange, isOnDay} from "../../../../../utils/dateUtils";
import {setToast} from "../../../../../store/toastSlice";
import dayjs from "dayjs";


const NewWorkGroupVersionWrapper = () => {
    const dispatch = useAppDispatch();
    const dialogData = useAppSelector(selectDialogData).divideWorkGroup;
    const selectedDate = useAppSelector(selectSelectedDay);

    if (!dialogData) return null;

    const handleSuccess = (workGroup: WorkGroup) => {
        if (workGroup.versions) {
            const closestVersion = findClosestItemByValidRange(workGroup.versions, selectedDate);
            if (isOnDay((dayjs(selectedDate)), closestVersion.validFrom, closestVersion.validTo)) {
                dispatch(updateWorkGroupValidDatesAndActivities({
                    workGroupId: workGroup.id,
                    validFrom: closestVersion.validFrom,
                    validTo: closestVersion.validTo,
                    activities: closestVersion.activities,
                }));
                handleCloseDialog();
                return;
            }
        }

        dispatch(setToast({type: 'error', text: 'Töögrupi versioneerimisel tekkis viga.'}));
    };

    const handleCloseDialog = () => dispatch(clearDialogData());

    return (
        <NewWorkGroupVersionDialog dialogData={dialogData} handleSuccess={handleSuccess} handleCloseDialog={handleCloseDialog} />
    );
};

export default NewWorkGroupVersionWrapper;
