import {Dayjs} from "dayjs";
import axios from "axios";
import {BusReport, BusStopUsagesReport, OverUnderTimeReport, TransportContractPayReport, TripReport} from "./types";
import {handleError} from "../utils";
import {WorkGroup} from "../workGroup/types";


export const getLineReport = (regionId: number|string, startDate: Dayjs, endDate: Dayjs) =>
    axios.get<TripReport[]>(`/api/regions/${regionId}/line-report?startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}`)
        .then(result => result.data)
        .catch(handleError);

export const getBusReport = (regionId: number|string, startDate: Dayjs, endDate: Dayjs) =>
    axios.get<BusReport[]>(`/api/regions/${regionId}/bus-report?startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}`)
        .then(result => result.data)
        .catch(handleError);

export const getDriverWorkGroupsReport = (regionId: number|string, startDate: Dayjs, endDate: Dayjs) =>
    axios.get<WorkGroup[]>(`/api/regions/${regionId}/work-groups?type=DRIVER&from=${startDate.format('YYYY-MM-DD')}&to=${endDate.format('YYYY-MM-DD')}`)
        .then(result => result.data)
        .catch(handleError);

export const getOverUnderTimeReport = (yearMonth: string) =>
    axios.get<OverUnderTimeReport[]>(`/api/reports/over-under-time/${yearMonth}`)
        .then(result => result.data)
        .catch(handleError);

export const getTransportContractPayReport = (yearMonth: string, transportContractId: number) =>
    axios.get<TransportContractPayReport[]>(`/api/reports/transport-contract-pay/${yearMonth}/${transportContractId}`)
        .then(result => result.data)
        .catch(handleError);

export const getBusStopUsagesReport = (_: number|string, startDate: Dayjs, endDate: Dayjs) =>
    axios.get<BusStopUsagesReport>(`/api/reports/bus-stop-usages?from=${startDate.format('YYYY-MM-DD')}&to=${endDate.format('YYYY-MM-DD')}`)
        .then(result => result.data)
        .catch(handleError);
