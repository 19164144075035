import React, {useState} from 'react';
import {useAppSelector} from "../../../../../../../hooks";
import {selectDefectsWithSearchFieldsByBusId} from "../../../../../../../store/defectsSlice";
import {Box, Collapse, Typography} from "@mui/material";
import DefectsCard from "./DefectCard";
import Button from "../../../../../../../components/Button/Button";
import {Add} from "@mui/icons-material";
import DefectFormWrapper from "../../../../../defects/detailsView/components/DefectFormWrapper";
import CardBase from "../../../components/CardBase";
import {BusDetails} from "../../../../../../../API/bus/types";


interface DefectsTabProps {
    bus: BusDetails;
}

const DefectsTab = ({bus}: DefectsTabProps) =>  {
    const defectsOfCurrentBus = useAppSelector(state => selectDefectsWithSearchFieldsByBusId(state, bus.id));
    const [openDefectForm, setOpenDefectForm] = useState(false);

    const handleOpenCloseDefectForm = () => setOpenDefectForm(prevState => !prevState);

    return (
        <Box pt={2} maxWidth="100%">
            <Box>
                <Collapse in={openDefectForm}>
                    <CardBase sx={{m: 0, pb: 2}}>
                        <Typography variant="h6" pl={1} pb={1}>Uus rike</Typography>
                        <DefectFormWrapper
                            defect={undefined}
                            isDriverView={true}
                            handleSuccess={handleOpenCloseDefectForm}
                            handleCancel={handleOpenCloseDefectForm}
                            defaultBus={bus}
                        />
                    </CardBase>
                </Collapse>
                <Collapse in={!openDefectForm}>
                    <Box sx={{display: 'flex', flexDirection: 'column', pb: 1}}>
                        <Button text="Lisa rike" startIcon={<Add />} onClick={handleOpenCloseDefectForm} styles={{width: '100%'}} />
                        {defectsOfCurrentBus.length === 0 && <Typography color="text.secondary" p={1}>Rikked puuduvad</Typography>}
                    </Box>
                </Collapse>
            </Box>
            {defectsOfCurrentBus.length > 0 &&
                defectsOfCurrentBus.map(defect => <DefectsCard key={defect.id} defect={defect} />)
            }
        </Box>
    );
};

export default DefectsTab;
