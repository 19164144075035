import React, {useContext, useMemo} from 'react';
import {Box, ListItem, Tooltip, Typography, useMediaQuery, useTheme} from '@mui/material';
import {useLocation} from 'react-router-dom';
import {useNavigate} from 'react-router';
import {SidebarContext} from '../../../contexts/SidebarContext';
import {doesUserHavePermission} from "../../Visible/Visible";
import {useAuth} from "../../../contexts/AuthContext";
import menuItemGroups, {DASHBOARD, MenuItem} from "./items";
import {AuthenticatedUser} from "../../../types";
import {ArrowLeftRounded, ChevronLeft} from "@mui/icons-material";


interface SidebarMenuItemProps extends MenuItem {
    isChild?: boolean;
}

const isMenuItemVisible = (item: MenuItem, authenticatedUser: AuthenticatedUser | null) =>
    item.permission === undefined || item.permission && doesUserHavePermission(authenticatedUser, item.permission);

function SidebarMenu() {
    const { authenticatedUser } = useAuth();
    const { isSidebarOpen, toggleSidebar, expandedSidebarMenuItems, toggleExpandedSidebarMenuItem } = useContext(SidebarContext);
    const theme = useTheme();
    const isScreenSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const menuItems = useMemo(() => {
        const permittedItems: MenuItem[] = [];

        menuItemGroups.forEach((item) => {
            if (item.children) {
                const permittedChildren: MenuItem[] = [];

                item.children?.forEach(child => {
                    if (isMenuItemVisible(child, authenticatedUser)) permittedChildren.push(child);
                });

                if (permittedChildren.length === 1) permittedItems.push(permittedChildren[0]);
                if (permittedChildren.length > 1) permittedItems.push({...item, children: permittedChildren});
            } else {
                if (isMenuItemVisible(item, authenticatedUser)) permittedItems.push(item);
            }
        });

        return [DASHBOARD, ...(permittedItems.length === 1 && permittedItems[0].children ? permittedItems[0].children : permittedItems)];
    }, [menuItemGroups, authenticatedUser]);

    const isActive = (path: string, route: string) =>
        (route === '/' ? route === path : path.includes(route.split('/')[1]));

    const SidebarMenuItem = ({name, route, icon, isChild, children}: SidebarMenuItemProps) => {
        const Icon = icon;
        const router = useLocation();
        const navigate = useNavigate();
        const isGroupOpen = useMemo(() => expandedSidebarMenuItems.includes(name), [expandedSidebarMenuItems]);
        const type = !route && children ? 'group' : 'item';
        const active = route
            ? isActive(router.pathname, route)
            : !!children?.find(child => child.route && isActive(router.pathname, child.route));

        return (<>
            <ListItem disableGutters sx={{
                display: 'flex',
                pt: type === 'group' ? 0.5 : 0.2,
                pb: 0,
                mt: type === 'group' ? 0.5 : 0,
                borderTop: '1px solid',
                borderColor: type === 'group' ? 'divider' : 'transparent'
            }}>
                <Tooltip title={isSidebarOpen ? undefined : name} arrow placement="right">
                    <Box
                        onClick={() => {
                            if (route) {
                                navigate(route);
                                if (isScreenSmall) toggleSidebar();
                            } else {
                                toggleExpandedSidebarMenuItem(name);
                            }
                        }}
                        sx={{
                            transition: theme.transitions.create('height', {
                                easing: theme.transitions.easing.easeIn,
                                duration: '2s',
                            }),
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minHeight: '24px',
                            maxHeight: '24px',
                            width: 'calc(100% - 8px)',
                            padding: 0.8,
                            cursor: 'pointer',
                            borderLeft: `4px solid ${active && (!isGroupOpen || type === 'item') ? theme.palette.primary.main : 'transparent'}`,
                            borderRight: '4px solid transparent',
                            backgroundColor: active ? theme.palette.action.hover : '',
                            mx: '4px',
                            borderRadius: '3px',
                            overflow: 'hidden',
                            '&:hover': {
                                backgroundColor: theme.palette.action.selected,
                            },
                        }}
                    >
                        <Box sx={{position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: '24px'}}>
                            {!isSidebarOpen && type === 'group' &&
                                <ArrowLeftRounded sx={{
                                    fontSize: '28px',
                                    color: 'text.secondary',
                                    transform: `rotate(${isGroupOpen ? '' : '-'}90deg)`,
                                    position: 'absolute',
                                    right: '-14px',
                                    bottom: '-2px',
                                }} />
                            }
                            <Box sx={{ml: isSidebarOpen ? (isChild ? 4 : 0) : (type === 'group' ? -1.5 : 0), maxHeight: '24px'}}>
                                <Icon sx={{fontSize: '24px', color: theme.palette.text.secondary}} />
                            </Box>
                        </Box>
                        {isSidebarOpen &&
                            <Box sx={{display: 'flex', justifyContent: 'space-between', flex: 1}}>
                                <Typography variant="body1" pl={1} >
                                    {name}
                                </Typography>
                                {children && <ChevronLeft sx={{color: 'text.secondary', transform: `rotate(${isGroupOpen ? '' : '-'}90deg)`}} />}
                            </Box>
                        }
                    </Box>
                </Tooltip>
            </ListItem>
            {children && isGroupOpen && children.map(item => (<SidebarMenuItem key={item.name} {...item} isChild />))}
        </>);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            {menuItems.map(item => (<SidebarMenuItem key={item.name} {...item} />))}
        </Box>
    );
}

export default SidebarMenu;
