import React, {useMemo} from "react";
import {Box, DialogActions} from "@mui/material";
import {Add, Clear} from "@mui/icons-material";
import Button from "../../../../../../components/Button/Button";
import {TripDefinition} from "../../../../../../API/types";
import {Form} from "formik";
import Autocomplete from "../../../../../../components/Form/Select/AutoComplete";
import LoadingField from "../../../../../../components/Form/LoadingField/LoadingField";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks";
import {clearDialogData} from "../../../../../../store/plannerSlice";
import {TripDefinitionWorkItem as WorkScheduleTripDefinitionWorkItem, CharterTripWorkItem as WorkScheduleCharterTripWorkItem} from "../../../../../../store/workScheduleItemSlice";
import {getDisplayTimeRange, getStartAndEndTimeWithModifierFromDateTimeStr} from "../../../../../../utils/dateUtils";
import {selectDate} from "../../../../../../store/viewSlice";


type OtherRegionOption = WorkScheduleTripDefinitionWorkItem | TripDefinition | WorkScheduleCharterTripWorkItem;

export interface OtherRegionWorkGroupTripDefinitionFormProps {
    options: OtherRegionOption[] | undefined;
    isLoading: boolean;
    name: string;
}

export default function OtherRegionTripForm({options, isLoading, name}: OtherRegionWorkGroupTripDefinitionFormProps) {
    const dispatch = useAppDispatch();
    const currentDay = useAppSelector(selectDate);

    const getDateTimeRange = (option: OtherRegionOption) => {
        if ('startDateTime' in option && 'endDateTime' in option) {
            return ' [' + getDisplayTimeRange(
                getStartAndEndTimeWithModifierFromDateTimeStr(option.startDateTime, option.endDateTime, currentDay)
            ) + ']';
        } else if ('startTime' in option && 'endTime' in option && 'startTimeIsOnNextDay' in option && 'endTimeIsOnNextDay' in option) {
            return ' [' + getDisplayTimeRange({
                startTime: option.startTime,
                startTimeIsOnNextDay: option.startTimeIsOnNextDay,
                endTime: option.endTime,
                endTimeIsOnNextDay: option.endTimeIsOnNextDay
            }) + ']';
        } else {
            return '';
        }
    };

    const getOptionLabel = (option: OtherRegionOption): string => {
        if ('code' in option && option.code) {
            return option.code + getDateTimeRange(option);
        }
        if ('charterTripId' in option && option.charterTripId) {
            return option.charterTripId + getDateTimeRange(option);
        }

        return ''
    };

    const groupBy = useMemo(() => {
        if (
            options?.some(option => 'code' in option && option.code)
            && options?.some(option => 'charterTripId' in option && option.charterTripId)
        ) {
            return (option: OtherRegionOption) => {
                if ('charterTripId' in option && option.charterTripId) {
                    return 'Tellimusveod';
                }
                if ('code' in option && option.code) {
                    return 'Reisid';
                }

                return '';
            } ;
        }

        return undefined;
    }, [options]);

    return (
        <Form>
            {!isLoading && options ?
                <Autocomplete
                    options={options.toSorted(option => 'code' in option && option.code ? 1 : -1)}
                    name={name}
                    label="Reis"
                    getOptionLabel={getOptionLabel}
                    groupBy={groupBy}
                />
                :
                <LoadingField />
            }
            <DialogActions sx={{justifyContent: 'center', maxWidth: '100%', py: 3}}>
                <Box maxWidth="50%">
                    <Button
                        text="Loobu"
                        variant="outlined"
                        onClick={() => dispatch(clearDialogData())}
                        startIcon={<Clear />}
                    />
                </Box>
                <Box maxWidth="50%">
                    <Button text="Lisa" type="submit" startIcon={<Add />} disabled={isLoading} />
                </Box>
            </DialogActions>
        </Form>
    );
}
