import React, {ReactNode} from "react";
import {useDrag} from "react-dnd";
import {StartAndEndTimeWithModifierAndId} from "../../../types";
import {Box, Typography} from "@mui/material";
import {MIN_CARD_WIDTH, PLANNER_ROW_HEIGHT} from "../../../constants";
import PlannerTooltip from "./Tooltip/PlannerTooltip";
import {WorkGroupItemType} from "../../../../../API/workGroup/types";
import {useAppSelector} from "../../../../../hooks";
import {selectSelectedOppositeGroupId} from "../../../../../store/plannerSlice";
import {EntityId} from "@reduxjs/toolkit";


interface ItemCardItem extends StartAndEndTimeWithModifierAndId {
    xPos: number;
    width: number;
    type: WorkGroupItemType;
}

interface ItemCardProps {
    onDrop?: (itemId: number, targetGroupId: number) => void;
    isRowDisabled: boolean;
    isItemDraggingDisabled?: boolean,
    tooltipContent: ReactNode;
    tooltipActions?: ReactNode;
    color: string;
    title: string;
    subtitle?: string | ReactNode;
    currentGroupId: EntityId;
    oppositeGroupMarker?: ReactNode;
    groupOfOppositeTypeId?: number,
    item: ItemCardItem;
}

const ItemCard = ({
    onDrop,
    isRowDisabled,
    isItemDraggingDisabled = false,
    tooltipContent,
    tooltipActions,
    color,
    title,
    subtitle,
    oppositeGroupMarker,
    groupOfOppositeTypeId,
    item
}: ItemCardProps) => {
    const selectedOppositeGroupId = useAppSelector(selectSelectedOppositeGroupId);

    const [{ isDragging }, drag] = useDrag<StartAndEndTimeWithModifierAndId | {id: number}, any, { isDragging: boolean }>({
        type: item.type,
        item: item,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult<{id: number}>();
            if (dropResult !== null && onDrop) {
                onDrop(item.id, dropResult.id);
            }
        },
    });

    return (
        <Box key={item.id} sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            boxSizing: 'border-box',
            mt: `-${PLANNER_ROW_HEIGHT - 2}px`,
        }}>
            <PlannerTooltip
                isDragging={isDragging}
                isRowDisabled={isRowDisabled}
                content={tooltipContent}
                actions={tooltipActions}
            >
                <Box ref={isRowDisabled || isItemDraggingDisabled || onDrop === undefined ? undefined : drag} sx={{
                    position: 'relative',
                    zIndex: 10,
                    height: `${PLANNER_ROW_HEIGHT - 6}px`,
                    borderRadius: '5px 15px 15px 5px',
                    my: '2px',
                    lineHeight: '15px',
                    left: item.xPos,
                    maxWidth: item.width,
                    minWidth: item.width,
                    background: color,
                    boxSizing: 'border-box',
                    cursor: onDrop && !isRowDisabled && !isItemDraggingDisabled ? 'pointer' : undefined,
                    opacity: isDragging ? 0.5 : !isRowDisabled && selectedOppositeGroupId && selectedOppositeGroupId !== groupOfOppositeTypeId ? 0.7 : 1
                }} aria-label={title}>
                    <Box sx={{position: 'relative', padding: item.width > MIN_CARD_WIDTH ? '6px 4px' : '6px 1px'}}>
                        {oppositeGroupMarker}
                        <Typography component="div" sx={{color: 'white', fontWeight: 'bolder', fontSize: '12px', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                            {title.toUpperCase()}
                        </Typography>
                        {subtitle &&
                            <Typography component="div" sx={{color: 'white', fontSize: '10px', fontWeight: 'bolder', letterSpacing: -0.1}}>
                                {subtitle}
                            </Typography>
                        }
                    </Box>
                </Box>
            </PlannerTooltip>
        </Box>
    );
};

export default ItemCard;