import {WorkSheetDetails as ApiWorkSheetDetails, WorkSheetWorkItem} from "../../../../../API/workSheets/types";
import {ResourceType} from "../../../../../API/workGroup/types";
import {useAppSelector} from "../../../../../hooks";
import {selectBusOrDriverIdByResourceId} from "../../store/selectors";
import React, {useMemo} from "react";
import {Box} from "@mui/material";
import {BusUsageWithDistance} from "../../../dashboard/DriverDashboard/types";
import {OdometerReadingWithBusId} from "../../../../../API/types";
import {selectAllBuses} from "../../../../../store/busSlice";
import {getBusUsages, getReadingRows} from "../utils";
import OdometerReadings from "./OdometerReadings";
import {AddReadingDialogData} from "./dialogs/AddReadingDialog";
import WorkItems from "./WorkItems";
import DayLengthWarning from "./alerts/DayLengthWarning";
import DistanceDifferenceWarning from "./alerts/DistanceDifferenceWarning";
import DriverComment from "./alerts/DriverComment";


export interface OdometerReadingRow extends OdometerReadingWithBusId {
    type: 'Algnäit' | 'Lõppnäit' | 'Näit' | 'Tankimine';
    licencePlateNumber?: string;
    expectedTotalDistance?: number;
    distanceDiff?: number;
}

export const WorkSheetDetails = ({workSheet, secondaryWorkSheets, handleDelete, handleEdit, handleSplit, handleEditOdometerReading, handleAddOdometerReading, handleDeleteOdometerReading}: {
    workSheet: ApiWorkSheetDetails,
    secondaryWorkSheets: ApiWorkSheetDetails[],
    handleDelete: (workItem: WorkSheetWorkItem) => void,
    handleEdit: (workItem: WorkSheetWorkItem) => void,
    handleSplit: (workItem: WorkSheetWorkItem) => void,
    handleEditOdometerReading: (reading: OdometerReadingWithBusId) => void;
    handleAddOdometerReading: (dialogData: AddReadingDialogData) => void;
    handleDeleteOdometerReading: (reading: OdometerReadingWithBusId) => void;
}) => {
    const buses = useAppSelector(selectAllBuses);
    const busOrDriverId = useAppSelector(state => selectBusOrDriverIdByResourceId(state, workSheet.resourceId, workSheet.resourceType));

    const sortedWorkItems = useMemo(() => {
        return [...workSheet.workItems]
            .sort((a, b) => a.startDateTime.localeCompare(b.startDateTime))
            .map((item, index, array) => ({
                ...item,
                warning: array[index-1]?.endDateTime > item.startDateTime,
            }));
    }, [workSheet]);

    const [odometerReadingRows, busUsages] = useMemo(() => {
        if (workSheet.resourceType === ResourceType.VEHICLE) return [[], []];
        const busUsages: BusUsageWithDistance[] = getBusUsages(sortedWorkItems, secondaryWorkSheets, workSheet.id);
        return [getReadingRows(workSheet, busUsages, buses, busOrDriverId), busUsages];
    }, [sortedWorkItems]);

    const handleAddOdometerReadingClick = () => {
        if (busOrDriverId && sortedWorkItems.length > 0) {
            handleAddOdometerReading({
                workSheet: workSheet,
                busUsages: busUsages,
                driverId: busOrDriverId,
            });
        }
    };

    return (
        <>
            <Box sx={{display: 'flex', flexWrap: 'wrap', flexDirection: {xs: 'column', sm: 'row'}}}>
                <DayLengthWarning workSheet={workSheet} />
                <DistanceDifferenceWarning workSheet={workSheet} odometerReadingRows={odometerReadingRows} />
                <DriverComment workSheet={workSheet} />
            </Box>
            <WorkItems
                workSheet={workSheet}
                secondaryWorkSheets={secondaryWorkSheets}
                workItems={sortedWorkItems}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
                handleSplit={handleSplit}
            />
            <OdometerReadings
                workSheet={workSheet}
                secondaryWorkSheets={secondaryWorkSheets}
                odometerReadingRows={odometerReadingRows}
                handleEditOdometerReading={handleEditOdometerReading}
                handleAddOdometerReading={handleAddOdometerReadingClick}
                handleDeleteOdometerReading={handleDeleteOdometerReading}
            />
        </>
    );
};