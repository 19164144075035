import React from 'react';
import {Add, Check} from "@mui/icons-material";
import {Chip} from "@mui/material";

export interface FilterChipProps {
    selected: boolean;
    setSelected: React.Dispatch<React.SetStateAction<boolean>>;
    text: string;
}

const FilterChip = ({selected, setSelected, text}: FilterChipProps) => {
    const handleSelectClick = () => setSelected(prevState => !prevState);

    return (
        selected
            ? <Chip icon={<Check fontSize="small" />} label={text} variant="outlined" onClick={handleSelectClick} sx={{ backgroundColor: 'common.white', height: 30 }} />
            : <Chip icon={<Add fontSize="small" />} label={text} variant="outlined" onClick={handleSelectClick} sx={{ height: 30 }}/>
    );
};

export default FilterChip;
