import React from 'react';
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import {Moving} from "@mui/icons-material";
import Button from "./Button";
import {useNavigate} from "react-router";
import {Box} from '@mui/material';
import theme from "../../theme";


interface NavigationButtonProps {
    text: string | ReactJSXElement;
    route: string;
}

export default function NavigationButton({text, route}: NavigationButtonProps) {
    const navigate = useNavigate();

    return (
        <Box sx={{ml: {xs: 0, sm: 2}, mt: {xs: 1, sm: 0}, [theme.breakpoints.down('sm')]: {width: '100%'}}}>
            <Button
                text={text}
                startIcon={<Moving />}
                color="secondary"
                onClick={() => navigate(route)}
                styles={{color: 'secondary.main', borderColor: 'secondary.main'}}
            />
        </Box>
    );
}
