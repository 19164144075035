import {
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice,
    EntityId,
    EntityState
} from "@reduxjs/toolkit";
import {BusDetails, OdometerReading, OdometerReadingRequest} from "../API/bus/types";
import {WorkSheetWorkItem} from "../API/workSheets/types";
import {ResourceType, WorkGroup} from "../API/workGroup/types";
import {getCurrentDriverWorkSheets} from "../API/driver/api";
import {Dayjs} from "dayjs";
import {CurrentDriverWorkSheetsResponse} from "../API/driver/types";
import {AppDispatch, RootState} from "../store";
import {WorkSheetCardData} from "../scenes/authenticated/dashboard/DriverDashboard/types";
import {calculateHoursFromWorkSheetWorkItems} from "../scenes/authenticated/workSchedule/utils";
import {
    deleteOdometerReading as apiDeleteOdometerReading,
    saveOdometerReading,
    updateOdometerReading as apiUpdateOdometerReading,
    updateWorkSheetComment as apiUpdateWorkSheetComment
} from "../API";
import {setToast} from "./toastSlice";
import {DriverWorkSheet} from "../API/workSchedule/types";
import {createDefect, fetchDefectById} from "./defectsSlice";
import {getWorkSheetsAffectedByDefect} from "../scenes/authenticated/dashboard/DriverDashboard/utils";

const busesAdapter = createEntityAdapter<BusDetails>();
const workSheetsAdapter = createEntityAdapter<DriverWorkSheet>();
const workItemsAdapter = createEntityAdapter<WorkSheetWorkItem>();
const workGroupsAdapter = createEntityAdapter<WorkGroup>();

interface CurrentDriverWorkSheetsState {
    loading: boolean;
    buses: EntityState<BusDetails>;
    workSheets: EntityState<DriverWorkSheet>;
    workItems: EntityState<WorkSheetWorkItem>;
    workGroups: EntityState<WorkGroup>;
}

const initialState: CurrentDriverWorkSheetsState = {
    loading: true,
    buses: busesAdapter.getInitialState(),
    workSheets: workSheetsAdapter.getInitialState(),
    workItems: workItemsAdapter.getInitialState(),
    workGroups: workGroupsAdapter.getInitialState(),
};

export const currentDriverWorkSheetsSlice = createSlice({
    name: 'currentDriverWorkSheets',
    initialState: initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchCurrentDriverWorkSheets.fulfilled, (state, action) => {
            state.loading = false;
            busesAdapter.setAll(state.buses, action.payload.buses);
            const workSheets = action.payload.workSheets;
            workSheetsAdapter.setAll(state.workSheets, workSheets);
            workItemsAdapter.setAll(state.workItems, action.payload.workItems);
            workGroupsAdapter.setAll(state.workGroups, action.payload.workGroups);
        });
        builder.addCase(fetchCurrentDriverWorkSheets.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(updateWorkSheetComment.fulfilled, (state, action) => {
            workSheetsAdapter.updateOne(state.workSheets, {
                id: action.payload.id,
                changes: {
                    driverComment: action.payload.comment,
                },
            });
        });
        builder.addCase(addOdometerReading.fulfilled, (state, action) => {
            const bus = busesAdapter
                .getSelectors<CurrentDriverWorkSheetsState>(state => state.buses)
                .selectById(state, action.payload.busId);
            const odometerReadings = bus?.odometerReadings ?? [];
            busesAdapter.updateOne(state.buses, {
                id: action.payload.busId,
                changes: {
                    odometerReadings: [...odometerReadings, action.payload.odometerReading]
                }
            })
        });
        builder.addCase(updateOdometerReading.fulfilled, (state, action) => {
            const bus = busesAdapter
                .getSelectors<CurrentDriverWorkSheetsState>(state => state.buses)
                .selectById(state, action.payload.busId);
            const odometerReadings = bus?.odometerReadings ?? [];
            busesAdapter.updateOne(state.buses, {
                id: action.payload.busId,
                changes: {
                    odometerReadings: [...odometerReadings.filter(reading => reading.id !== action.payload.odometerReading.id), action.payload.odometerReading]
                }
            })
        });
        builder.addCase(deleteOdometerReading.fulfilled, (state, action) => {
            const bus = busesAdapter
                .getSelectors<CurrentDriverWorkSheetsState>(state => state.buses)
                .selectById(state, action.payload.busId);
            const odometerReadings = bus?.odometerReadings ?? [];
            busesAdapter.updateOne(state.buses, {
                id: action.payload.busId,
                changes: {
                    odometerReadings: odometerReadings.filter(reading => reading.id !== action.payload.readingId)
                }
            })
        });
        // update active defects of a driver work sheet when the driver updates a defect in the defects tab
        builder.addCase(fetchDefectById.fulfilled, (state, action) => {
            const workSheets = workSheetsAdapter
                .getSelectors<CurrentDriverWorkSheetsState>(state => state.workSheets)
                .selectAll(state);
            const updatedWorkSheets = getWorkSheetsAffectedByDefect(workSheets, action.payload.defect);

            workSheetsAdapter.updateMany(
                state.workSheets,
                updatedWorkSheets.map(workSheet => ({
                    id: workSheet.id,
                    changes: {
                        defectsOnDay: workSheet.defectsOnDay,
                    }
                }))
            );
        });
        // update active defects of a driver work sheet when the driver adds a defect in the defects tab
        builder.addCase(createDefect.fulfilled, (state, action) => {
            const workSheets = workSheetsAdapter
                .getSelectors<CurrentDriverWorkSheetsState>(state => state.workSheets)
                .selectAll(state);
            const updatedWorkSheets = getWorkSheetsAffectedByDefect(workSheets, action.payload.defect);

            workSheetsAdapter.updateMany(
                state.workSheets,
                updatedWorkSheets.map(workSheet => ({
                    id: workSheet.id,
                    changes: {
                        defectsOnDay: workSheet.defectsOnDay,
                    }
                }))
            );
        });
    },
});

// Actions
export const fetchCurrentDriverWorkSheets = createAsyncThunk<
    CurrentDriverWorkSheetsResponse,
    Dayjs
>(
    'currentDriverWorkSheets/fetchAll',
    (date) =>  getCurrentDriverWorkSheets(date),
);

export const updateWorkSheetComment = createAsyncThunk<
    {id: number, comment: string},
    {id: number, comment: string},
    { dispatch: AppDispatch }
>(
    'currentDriverWorkSheets/updateComment',
    async ({id, comment}, { dispatch }) => {
        try {
            await apiUpdateWorkSheetComment(id, comment);
            dispatch(setToast({ type: 'success', text: 'Kommentaar salvestatud' }));

            return {id, comment};
        } catch (error) {
            dispatch(setToast({ type: 'error', text: 'Kommentaari salvestamisel tekkis tõrge' }));

            throw error;
        }
    },
);

export const addOdometerReading = createAsyncThunk<
    {busId: number, odometerReading: OdometerReading},
    {busId: number, data: OdometerReadingRequest, handleClose: () => void},
    { dispatch: AppDispatch }
>(
    'currentDriverWorkSheets/addOdometerReading',
    async ({busId, data, handleClose}, { dispatch }) => {
        try {
            const odometerReading = await saveOdometerReading(busId, data);
            const successMessage = data.fuelType
                ? 'Tankimine salvestatud'
                : 'Odomeetri näit salvestatud';
            dispatch(setToast({type: 'success', text: successMessage}));
            handleClose();
            return {busId, odometerReading};
        } catch (error) {
            dispatch(setToast({type: 'error', text: 'Odomeetri näidu salvestamisel tekkis viga'}));

            throw error;
        }
    },
);

export const updateOdometerReading = createAsyncThunk<
    {busId: number, odometerReading: OdometerReading},
    {busId: number, readingId: number, driverId: number, data: OdometerReadingRequest, handleClose: () => void},
    { dispatch: AppDispatch }
>(
    'currentDriverWorkSheets/updateOdometerReading',
    async ({busId, readingId, driverId, data, handleClose}, { dispatch }) => {
        try {
            await apiUpdateOdometerReading(readingId, data);
            const successMessage = data.fuelType
                ? 'Tankimine uuendatud'
                : 'Odomeetri näit uuendatud';
            dispatch(setToast({type: 'success', text: successMessage}));
            handleClose();
            return {busId: busId, odometerReading: {...data, id: readingId, driverId: driverId}};
        } catch (error) {
            dispatch(setToast({
                type: 'error',
                text: `${data.fuelType ? 'Tankimise' : 'Odomeetri näidu'} näidu uuendamisel tekkis viga`,
            }));

            throw error;
        }
    },
);

export const deleteOdometerReading = createAsyncThunk<
    {busId: number, readingId: number},
    {busId: number, readingId: number, data: OdometerReadingRequest},
    { dispatch: AppDispatch }
>(
    'currentDriverWorkSheets/deleteOdometerReading',
    async ({busId, readingId, data}, { dispatch }) => {
        try {
            await apiDeleteOdometerReading(readingId);
            const successMessage = data.fuelType
                ? 'Tankimine kustutatud'
                : 'Odomeetri näit kustutatud';
            dispatch(setToast({type: 'success', text: successMessage}));
            return {busId: busId, readingId: readingId};
        } catch (error) {
            dispatch(setToast({
                type: 'error',
                text: `${data.fuelType ? 'Tankimise' : 'Odomeetri näidu'} näidu kustutamisel tekkis viga`,
            }));

            throw error;
        }
    },
);

// Selectors
export const {
    selectById: selectCurrentDriverBusById,
    selectAll: selectAllCurrentDriverBuses,
} = busesAdapter.getSelectors<RootState>(state => state.currentDriverWorkSheets.buses);
export const {
    selectById: selectCurrentDriverWorkSheetById,
    selectAll: selectAllCurrentDriverWorkSheets,
} = workSheetsAdapter.getSelectors<RootState>(state => state.currentDriverWorkSheets.workSheets);
export const {
    selectAll: selectAllCurrentDriverWorkItems,
    selectById: selectCurrentDriverWorkItemById,
} = workItemsAdapter.getSelectors<RootState>(state => state.currentDriverWorkSheets.workItems);
export const {
    selectById: selectCurrentDriverWorkGroupById,
    selectAll: selectAllCurrentDriverWorkGroups,
} = workGroupsAdapter.getSelectors<RootState>(state => state.currentDriverWorkSheets.workGroups);

const selectBusByBusWorkSheetId = createSelector(
    selectAllCurrentDriverWorkSheets,
    selectAllCurrentDriverBuses,
    (_: RootState, workSheetId: number | undefined) => workSheetId,
    (workSheets, buses, workSheetId) => {
        if (!workSheetId) {
            return undefined;
        }
        const workSheet = workSheets.find(item => item.id === workSheetId);
        if (!workSheet) {
            return undefined;
        }

        return buses.find(bus => bus.id === workSheet.resourceId);
    }
);

export const selectCurrentDriverBusByBusWorkSheetId = (state: RootState, workSheetId?: number) => {
    return selectBusByBusWorkSheetId(state, workSheetId);
};

export const selectCurrentDriverBusByLicencePlateNumber = (state: RootState, licencePlateNumber: string) => {
    const buses = selectAllCurrentDriverBuses(state);

    return buses.find(bus => bus.licencePlateNumber.toLowerCase() === licencePlateNumber.toLowerCase());
};

export const selectCurrentDriverSelectedWorkSheet = createSelector(
    (_: RootState, id?: EntityId) => id,
    selectAllCurrentDriverWorkSheets,
    (id, workSheets): DriverWorkSheet | undefined => {
        if (!id) {
            return undefined;
        }

        return workSheets.find(item => item.id === Number(id));
    }
);

export const selectCurrentDriverWorkSheetItems = createSelector(
    (_: RootState, workSheetId: EntityId) => workSheetId,
    selectAllCurrentDriverWorkItems,
    (workSheetId, workItems) => {
        return workItems.filter(item => item.driverWorkSheetId === workSheetId);
    }
);

export const selectWorkSheetCards = createSelector(
    selectAllCurrentDriverWorkSheets,
    selectAllCurrentDriverWorkItems,
    selectAllCurrentDriverWorkGroups,
    selectAllCurrentDriverBuses,
    (workSheets, workItems, workGroups, buses): WorkSheetCardData[] => {
        return workSheets
            .filter(item => item.resourceType === ResourceType.DRIVER)
            .map(item => {
                const workSheetWorkItems = workItems
                    .filter(workItem => item.workItemIds?.includes(workItem.id));
                const {startDateTime, endDateTime} = calculateHoursFromWorkSheetWorkItems(workSheetWorkItems);
                const workGroup = workGroups.find(workGroup => workGroup.id === item.workGroupId);

                const usedBuses: BusDetails[] = [];
                item.busUsages.forEach(busUsage => {
                    if (!usedBuses.some(usedBus => usedBus.id === busUsage.busId)) {
                        const usedBus = buses.find(bus => bus.id === busUsage.busId);
                        if (usedBus) usedBuses.push(usedBus);
                    }
                });

                return {
                    id: item.id ?? 0,
                    startDate: item.startDate,
                    workGroupId: item.workGroupId,
                    date: item.startDate,
                    startDateTime: startDateTime,
                    endDateTime: endDateTime,
                    workGroupCode: workGroup?.code ?? '',
                    workGroupComment: workGroup?.comment ?? '',
                    connectedBusLicencePlateNumbers: usedBuses.map(bus => bus.licencePlateNumber),
                };
            })
            .sort((a, b) => a.date.localeCompare(b.date));
    }
);

export const selectDefectByWorkSheetId = (state: RootState, workSheetId: number, defectId?: number) =>
    selectCurrentDriverWorkSheetById(state, workSheetId)?.defectsOnDay.find(defect => defect.id === defectId);

export const selectCurrentDriverWorkSheetLoading = (state: RootState) => state.currentDriverWorkSheets.loading;

export default currentDriverWorkSheetsSlice.reducer;