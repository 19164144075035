import React from 'react';
import {Box} from "@mui/material";
import PhonePrefix from "./components/PhonePrefixInput";
import NumberTextField from "../NumberTextField/NumberTextField";


interface PhoneInputProps {
    name: string;
    label: string;
    prefixName: string;
    disabled?: boolean;
}

const PhoneInput = ({name, label, prefixName, disabled}: PhoneInputProps) => {

    return (
        <Box sx={{display: 'flex', width: '100%'}}>
            <PhonePrefix name={prefixName} disabled={disabled} />
            <NumberTextField name={name} label={label} disabled={disabled} />
        </Box>
    )
}

export default PhoneInput;
