import {WorkScheduleItemType} from "../../../../../../API/workSchedule/types";
import {blue, green, orange, purple, teal, yellow} from "@mui/material/colors";

export const getItemBgColor = (item?: WorkScheduleItemType): string | undefined => {
    switch (item) {
        case WorkScheduleItemType.VACATION:
            return yellow[100];
        case WorkScheduleItemType.UNPAID_LEAVE:
            return orange[100];
        case WorkScheduleItemType.SICK_LEAVE:
            return blue[100];
        case WorkScheduleItemType.WORK_GROUP:
            return green[100];
        case WorkScheduleItemType.TRAINING:
            return teal[100];
        case WorkScheduleItemType.WORK_REQUEST:
        case WorkScheduleItemType.FREE_DAY_REQUEST:
        case WorkScheduleItemType.SPECIAL_REQUEST:
        case WorkScheduleItemType.REPAIR:
        case WorkScheduleItemType.MAINTENANCE:
        case WorkScheduleItemType.AWAY:
            return purple[100];
        default:
            return undefined;
    }
};

export const shortenWorkGroupCode = (code: string) => code.split('_')[0].substring(0, 4);
