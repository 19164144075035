import {WorkScheduleItemStatus, WorkScheduleItemType} from "../../../../../../API/workSchedule/types";
import {
    BusWorkScheduleActivityTypes,
    BusWorkScheduleRequestTypes,
    DriverWorkScheduleRequestTypes,
    WorkScheduleActivityTypes,
    WorkScheduleItemForm
} from "../../../types";
import {WorkScheduleItemDialogData} from "./WorkScheduleItemDialog";
import Select from "../../../../../../components/Form/Select/Select";
import {getWorkScheduleItemTypeTranslationFromStr} from "../../../../../../utils/enumTranslations";
import Autocomplete from "../../../../../../components/Form/Select/AutoComplete";
import DatePicker from "../../../../../../components/Form/DateTimePicker/DatePicker";
import TextField from "../../../../../../components/Form/TextField/TextField";
import {Box, Typography} from "@mui/material";
import React from "react";
import {useField} from "formik";
import {Dayjs} from "dayjs";
import IconButton from "@mui/material/IconButton";
import {Delete} from "@mui/icons-material";
import {isMultiDateType} from "../../../utils";
import {useAppSelector} from "../../../../../../hooks";
import {ResourceType} from "../../../../../../API/types";
import {selectIsRowActive, selectUnscheduledWorkGroupsOnDay} from "../../../store/selectors";
import {WorkScheduleItem, WorkScheduleItemWithHours} from "../../../../../../store/workScheduleItemSlice";
import {selectToggledResourceType} from "../../../../../../store/viewSlice";

export default function ActivityForm({data, activity, request, handleDelete, minDate, maxDate}: {
    data: WorkScheduleItemDialogData,
    activity?: WorkScheduleItemWithHours,
    request?: WorkScheduleItem,
    handleDelete: (workScheduleItem: WorkScheduleItem) => void,
    minDate: Dayjs,
    maxDate: Dayjs,
}) {
    const [field] = useField<WorkScheduleItemForm>('activity');
    const unscheduledWorkGroups = useAppSelector(state => selectUnscheduledWorkGroupsOnDay(state, data.date));
    const isRowActive = useAppSelector(state => selectIsRowActive(state, data.resourceId));
    const resourceType = useAppSelector(selectToggledResourceType);

    const handleDeleteClick = () => {
        if (activity) {
            handleDelete(activity);
        }
    };

    const selectedWorkGroup = activity?.workGroupId && activity?.workGroupCode ? {
        id: activity.workGroupId,
        code: activity.workGroupCode,
    } : null;

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', minWidth: '210px'}}>
            {(activity || request) &&
                <Box sx={{display: 'flex', alignItems: 'center', verticalAlign: 'baseline', justifyContent: 'space-between'}}>
                    <Typography variant="body2" color="text.secondary">Tegevus</Typography>
                    <IconButton size="small" aria-label="Kustuta" onClick={handleDeleteClick}
                                disabled={activity && activity.status !== WorkScheduleItemStatus.SCHEDULED}
                                sx={{visibility: !activity ? 'hidden' : undefined}}>
                        <Delete />
                    </IconButton>
                </Box>
            }
            <Select name="activity.type"
                    label="Tegevuse tüüp"
                    options={resourceType === ResourceType.DRIVER ? Object.values(WorkScheduleActivityTypes.concat(DriverWorkScheduleRequestTypes)) : Object.values(BusWorkScheduleActivityTypes.concat(BusWorkScheduleRequestTypes)) }
                    translationFunction={getWorkScheduleItemTypeTranslationFromStr}
                    translationEnumType={WorkScheduleItemType}
                    disabled={typeof activity?.type !== "undefined" || !isRowActive}
            />
            {field.value.type === WorkScheduleItemType.WORK_GROUP
                ? <Autocomplete
                    name="activity.workGroup"
                    label="Töögrupp"
                    options={selectedWorkGroup ? [selectedWorkGroup, ...unscheduledWorkGroups] : unscheduledWorkGroups}
                    autoFocus={true}
                    autoHighlight={true}
                    openOnFocus={!activity && !request}
                    getOptionLabel={option => option.code}
                    disabled={!isRowActive || (activity && activity.status !== WorkScheduleItemStatus.SCHEDULED)}
                /> : null
            }
            {isMultiDateType(field.value.type)
                ? <>
                    <DatePicker name="activity.startDate"
                                label="Algus"
                                minDate={minDate.add(1, 'day')}
                                maxDate={maxDate.subtract(1, 'day')}
                                disabled={!isRowActive} />
                    <DatePicker name="activity.endDate"
                                label="Lõpp"
                                minDate={minDate.add(1, 'day')}
                                maxDate={maxDate.subtract(1, 'day')}
                                disabled={!isRowActive} />
                </>
                : null
            }
            <TextField name="activity.comment" label="Kommentaar" multiline minRows={2} maxRows={5} disabled={!isRowActive} />
        </Box>
    );
}
