import {Box, useTheme} from '@mui/material';
import React from 'react';
import {WORK_GROUP_ITEM_TYPES} from "../../../../../../API/types";
import {PLANNER_ROW_HEIGHT} from "../../../../constants";
import {useDrop} from 'react-dnd';
import TimelineBackground from "../../../Timeline/TimelineBackgroundRow";
import {red} from '@mui/material/colors';
import {useAppSelector} from "../../../../../../hooks";
import {
    selectPlannerItemGroupById,
    selectPlannerTimelineMinWidth,
} from "../../../../../../store/plannerSlice";
import {EntityId} from "@reduxjs/toolkit";
import {StartAndEndTimeWithModifierAndId} from "../../../../types";
import TripCardWrapper from "../../../ItemCard/Wrappers/TripCardWrapper";
import ActivityCardWrapper from "../../../ItemCard/Wrappers/ActivityCardWrapper";
import WorkItemCardWrapper from "../../../ItemCard/Wrappers/WorkItemCardWrapper";
import {shallowEqual} from "react-redux";
import {canItemDrop} from "../../../../utils/collisionUtils";
import {selectAllItemTimesInPlannerItemGroupMemoized} from "../../../../store/selectors";
import {selectIsPlannerRowDisabled} from "../../../../../../store/workScheduleItemSlice";


interface PlannerItemGroupRowProps {
    groupId: EntityId;
    index: number;
}

const PlannerItemGroupRow = ({groupId, index}: PlannerItemGroupRowProps) => {
    const theme = useTheme();
    const plannerTimelineMinWidth = useAppSelector(selectPlannerTimelineMinWidth);
    const plannerItemGroup = useAppSelector(state => selectPlannerItemGroupById(state, groupId), shallowEqual);
    const allItemTimesInPlannerGroup = useAppSelector(state => selectAllItemTimesInPlannerItemGroupMemoized(state, groupId));
    const isRowDisabled = useAppSelector(state => selectIsPlannerRowDisabled(state, groupId));

    const [{canDrop, isOver}, drop] = useDrop({
        accept: WORK_GROUP_ITEM_TYPES,
        drop: () => ({id: groupId}),
        canDrop: (item: StartAndEndTimeWithModifierAndId) =>
            isRowDisabled ? false : canItemDrop(item, allItemTimesInPlannerGroup),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    return (plannerItemGroup ?
        <Box sx={{
            minHeight: `${PLANNER_ROW_HEIGHT - (index === 0 ? 2 : 0)}px`,
            maxHeight: `${PLANNER_ROW_HEIGHT - (index === 0 ? 2 : 0)}px`,
            minWidth: `${plannerTimelineMinWidth}px`,
            borderTop: index === 0 ? '' : `2px solid ${theme.palette.divider}`,
            boxSizing: 'border-box'
        }} ref={drop} aria-label={`planner-item-group-${groupId}`}>
            <Box sx={{
                minWidth: `${plannerTimelineMinWidth}px`,
                height: `${PLANNER_ROW_HEIGHT - 2}px`,
                backgroundColor: isOver && !canDrop ? red[50] : ''
            }}>
                <TimelineBackground groupId={groupId} />
            </Box>
            <Box sx={{minWidth: 'fit-parent', opacity: isRowDisabled ? 0.5 : 1}}>
                {plannerItemGroup.tripDefinitionIds.map(tripId =>
                    <TripCardWrapper key={tripId} tripSegmentId={tripId} currentGroupId={groupId} />
                )}
                {plannerItemGroup.activityIds.map(activityId =>
                    <ActivityCardWrapper key={activityId} activityId={activityId} currentGroupId={groupId} />
                )}
                {plannerItemGroup.workItemIds.map(workItemId =>
                    <WorkItemCardWrapper key={workItemId} workItemId={workItemId} currentGroupId={groupId} />
                )}
            </Box>
        </Box>
        :
        <></>
    );
};

export default PlannerItemGroupRow;
