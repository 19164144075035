import React, {useEffect, useMemo, useState} from 'react';
import {Box, Tooltip, useMediaQuery} from '@mui/material';
import {Contract, Driver, DriverContract} from "../../../../API/types";
import {GridColDef, GridValueGetterParams} from '@mui/x-data-grid';
import {
    filterByActive,
    filterByRegionsForUser,
    filterByValue,
    getRegionsDisplayStr,
    sortByStartDate
} from "../../../../utils/utils";
import theme from "../../../../theme";
import {ActiveToggleButtonType, activeToggleButtonValues, Permission} from "../../../../types";
import {useNavigate, useOutletContext} from 'react-router-dom';
import {getContractTranslation} from "../../../../utils/enumTranslations";
import DataGrid from "../../../../components/DataGrid/DataGrid";
import routes from "../../../../routes";
import {DriverContractTypeContractions} from "../../workSchedule/types";
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import {selectAllRegions} from "../../../../store/regionSlice";
import {loadDrivers} from "../../../../API";
import {setToast} from "../../../../store/toastSlice";
import {getDisplayDate} from "../../../../utils/dateUtils";
import NavLink from "../../../../components/NavLink/NavLink";
import NavIcon from "../../../../components/Icon/NavIcon";
import StaticIcon from "../../../../components/Icon/StaticIcon";
import {useAuth} from "../../../../contexts/AuthContext";
import {SearchContextType} from "../../../../layouts/SearchLayoutWrapper";
import ListView from "../../../../layouts/ListViewWrapper";

interface DisplayDriver extends Driver {
    regions: string;
}

const getLatestContract = (contracts: DriverContract[]): DriverContract | undefined => {
    if (contracts.length === 0) {
        return undefined;
    }

    return [...contracts].sort(sortByStartDate)[contracts.length - 1];
}


export default function Drivers() {
    const dispatch = useAppDispatch();
    const regions = useAppSelector(selectAllRegions);
    const { authenticatedUser } = useAuth();
    const navigate = useNavigate();
    const {searchInput, setSearchInput, paginationModel, setPaginationModel} = useOutletContext<SearchContextType>();
    const [drivers, setDrivers] = useState<Driver[] | undefined>(undefined);
    const [toggleButtonValue, setToggleButtonValue] = useState<ActiveToggleButtonType>('AKTIIVSED');
    const isScreenSmall = useMediaQuery(theme.breakpoints.down('xl'));

    useEffect(() => {
        if (authenticatedUser) {
            loadDrivers()
                .then(result => {
                    setDrivers(filterByRegionsForUser(result, authenticatedUser))
                })
                .catch(() => dispatch(setToast({type: 'error', text: 'Bussijuhtide andmete pärimisel ilmnes viga'})));
        }
    }, [authenticatedUser]);

    const rows: DisplayDriver[] | undefined = useMemo(() => drivers?.map(driver => ({
        ...driver,
        regions: getRegionsDisplayStr(driver.regionIds, regions)
    })), [drivers, regions]);

    const handleAddDriver = () => navigate(routes.AUTHENTICATED.DRIVERS.ADD);

    const handleToggleButtonChange = (value: string) => {
        if (activeToggleButtonValues.includes(value as ActiveToggleButtonType)) setToggleButtonValue(value as ActiveToggleButtonType);
    }

    const getFilteredRows = (rows: Driver[]) => {
        return filterByActive(filterByValue(rows, searchInput), toggleButtonValue);
    }

    const columns: GridColDef[] = [
        {
            field: 'personalId',
            headerName: 'Isikukood',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 110 : 130,
            renderCell: (params) =>
                <NavLink id={params.row.id} value={params.row.personalId} route={routes.AUTHENTICATED.DRIVERS.EDIT} navPermission={Permission.UpdateDrivers} />
        },
        {
            field: 'firstName',
            headerName: 'Eesnimi',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 80 : 120,
        },
        {
            field: 'lastName',
            headerName: 'Perekonnanimi',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 100 : 140,
        },
        {
            field: 'phoneWithPrefix',
            headerName: 'Telefon',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 120 : 140,
            minWidth: 100,
            valueGetter: (params: GridValueGetterParams) => `${params.row.phonePrefix} ${params.row.phone}`
        },
        {
            field: 'email',
            headerName: 'E-post',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 150 : 200,
            minWidth: 100,
        },
        {
            field: 'contractType',
            headerName: 'Lepingutüüp',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 70 : 120,
            valueGetter: (params: GridValueGetterParams) => getLatestContract(params.row.contracts)?.type,
            renderCell: (params) => {
                if (params.value) {
                    return (
                        <Tooltip title={getContractTranslation(params.value as Contract)} placement="top" arrow>
                            {<Box>{DriverContractTypeContractions.get(params.value as Contract)}</Box> ?? <></>}
                        </Tooltip>
                    );
                } else {
                    return <></>;
                }
            },
        },
        {
            field: 'contractId',
            headerName: 'Lepingu nr',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 90 : 100,
            valueGetter: (params: GridValueGetterParams) => getLatestContract(params.row.contracts)?.number,
        },
        {
            field: 'contractStartDate',
            headerName: 'Lepingu algus',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 120 : 130,
            valueGetter: (params: GridValueGetterParams) => getLatestContract(params.row.contracts)?.startDate,
            renderCell: (params) => params.value ?
                <Box style={{minWidth: 'fit-content'}}>{getDisplayDate(params.value)}</Box> : <></>
        },
        {
            field: 'contractEndDate',
            headerName: 'Lepingu lõpp',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 110 : 120,
            valueGetter: (params: GridValueGetterParams) => getLatestContract(params.row.contracts)?.endDate,
            renderCell: (params) => params.value ?
                <Box style={{minWidth: 'fit-content'}}>{getDisplayDate(params.value)}</Box> : <></>
        },
        {
            field: 'nominalWeeklyWorkingHours',
            headerName: 'Tunnid nädalas',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 80 : 130,
            valueGetter: (params: GridValueGetterParams) => getLatestContract(params.row.contracts)?.nominalWeeklyWorkingHours,
        },
        {
            field: 'regions',
            headerName: 'Piirkonnad',
            sortable: false,
            filterable: false,
            width: isScreenSmall ? 150 : 200,
            minWidth: 100
        },
        {
            field: 'active',
            headerName: 'Aktiivne',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 70 : 80,
            renderCell: params => params.value ? <StaticIcon type="CHECK" /> : ''
        },
        {
            field: 'Menüü',
            headerName: '',
            sortable: false,
            filterable: false,
            align: 'right',
            flex: 1,
            minWidth: 50,
            renderCell: (params) =>
                <NavIcon type="EDIT" id={params.row.id} route={routes.AUTHENTICATED.DRIVERS.EDIT} navPermission={Permission.UpdateDrivers} />
        }
    ];

    return (
        <ListView
            headerProps={{
                title: 'Bussijuhid',
                searchProps: {input: searchInput, setInput: setSearchInput},
                buttonProps: {title: 'Lisa juht', onClick: handleAddDriver, permission: Permission.UpdateDrivers},
                toggleProps: {
                    options: activeToggleButtonValues.map(value => value.toString()),
                    value: toggleButtonValue,
                    handleChange: handleToggleButtonChange
                }
            }}
            isLoading={!rows}
        >
            <DataGrid
                rows={getFilteredRows(rows ?? [])}
                columns={columns}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
            />
        </ListView>
    );
}
