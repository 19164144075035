import {TransportContractPayReport} from "../../../../API/reports/types";
import {Dayjs} from "dayjs";
import {Cell, Columns, Row, SheetData} from "write-excel-file";
import {BORDER_COLOR} from "../constants";


const getHeaderCell = (value: string): Cell => ({value: value, fontWeight: 'bold', bottomBorderColor: BORDER_COLOR, wrap: true});
const getNumberCell = (value: number | undefined): Cell => ({value: value ?? 0, type: Number, format: '0.00'});

export const formatTransportContractPayReportData = (data: TransportContractPayReport[], startDate: Dayjs, endDate: Dayjs, contractId?: string): [SheetData, Columns] => {
    const titleRow: Row = [{value: `Periood ${startDate.format('DD.MM.YYYY')} - ${endDate.format('DD.MM.YYYY')} ${contractId ? `(${contractId})` : ''}`, span: 3}];

    const columnHeaders: Row = [
        getHeaderCell('Eesnimi'),
        getHeaderCell('Perekonnanimi'),
        getHeaderCell('Tunnid'),
        getHeaderCell('Palk'),
    ];

    const dataRows: Row[] = [];

    data.forEach(line => {
        dataRows.push([
            {value: line.driverFirstName, type: String},
            {value: line.driverLastName, type: String},
            getNumberCell(line.totalHours),
            getNumberCell(line.totalPay),
        ]);
    });

    return [[titleRow, [], columnHeaders, ...dataRows], [{width: 15}, {width: 15}, {width: 10}, {width: 10}]];
};
