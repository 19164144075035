import React, {useState} from 'react';
import {getAvlAssignments} from "../../../API/avlAssignments/api";
import ListView from "../../../layouts/ListViewWrapper";
import {GridColDef} from "@mui/x-data-grid";
import DataGrid from "../../../components/DataGrid/DataGrid";
import {AvlAssignmentRow} from "./types";
import {getAvlSyncStatusTranslation} from "../../../utils/enumTranslations";
import {getDisplayDate, getDisplayNullableDateTime} from "../../../utils/dateUtils";
import {useOutletContext} from "react-router-dom";
import {SearchContextType} from "../../../layouts/SearchLayoutWrapper";
import {filterByValue} from "../../../utils/utils";
import {useLocalStorage} from "usehooks-ts";
import {AvlSyncStatus} from "../../../API/avlAssignments/types";
import {FilterChipProps} from "../../../components/FilterToolbar/components/FilterChip";
import {Dayjs} from "dayjs";
import FilterToolbar from "./FilterToolbar";

export default function AvlAssignments() {

    const [rows, setRows] = useState<AvlAssignmentRow[]>([]);
    const {searchInput, setSearchInput, paginationModel, setPaginationModel} = useOutletContext<SearchContextType>();
    const [selectedStatuses, setSelectedStatuses] = useLocalStorage<AvlSyncStatus[]>('avlAssignments.selectedStatuses', [AvlSyncStatus.ERROR]);

    const statusIsSelected = (status: AvlSyncStatus) => selectedStatuses.includes(status);

    const getStatusSelectedValue = (status: AvlSyncStatus, value: (boolean | ((prevState: boolean) => boolean))) => {
        if (typeof value === 'boolean') {
            return value;
        } else {
            const prevState = statusIsSelected(status);
            return value(prevState);
        }
    };

    const setStatusSelected = (status: AvlSyncStatus) => {
        return (value: (boolean | ((prevState: boolean) => boolean))): void => {
            if (getStatusSelectedValue(status, value)) {
                if (!statusIsSelected(status)) {
                    setSelectedStatuses([...selectedStatuses, status]);
                }
            } else {
                setSelectedStatuses([...selectedStatuses.filter(item => item !== status)]);
            }
        };
    }

    const getStatusSelected = (status: AvlSyncStatus): FilterChipProps => {
        return {
            selected: statusIsSelected(status),
            setSelected: setStatusSelected(status),
            text: getAvlSyncStatusTranslation(status)
        };
    }

    const getFilterChips = (): FilterChipProps[] => {
        let status: keyof typeof AvlSyncStatus;
        const result: FilterChipProps[] = [];
        for (status in AvlSyncStatus) {
            result.push(getStatusSelected(AvlSyncStatus[status]));
        }

        return result;
    }

    const filterByStatus = (rows: AvlAssignmentRow[]): AvlAssignmentRow[] => {
        return rows.filter(r => statusIsSelected(r.status));
    }

    const fetchAssignments = (date: Dayjs) => {
        getAvlAssignments(date).then((data) => {
            const rows = data.map(assignment => {
                return {
                    ...assignment,
                    id: `${assignment.tripId}-${assignment.date}`,
                    date: getDisplayDate(assignment.date),
                    statusTranslation: getAvlSyncStatusTranslation(assignment.status),
                    lastSyncTime: getDisplayNullableDateTime(assignment.lastSyncTime),
                };
            });
            setRows(rows);
        });
    }

    const columns: GridColDef[] = [
        {
            field: 'tripCode',
            headerName: 'Reisi kood',
            sortable: true,
            filterable: false,
            width: 120,
        },
        {
            field: 'date',
            headerName: 'Kuupäev',
            sortable: true,
            filterable: false,
            width: 120,
        },
        {
            field: 'statusTranslation',
            headerName: 'Staatus',
            sortable: true,
            filterable: false,
            width: 120,
        },
        {
            field: 'lastSyncTime',
            headerName: 'Viimati saadetud',
            sortable: true,
            filterable: true,
            width: 150,
        },
        {
            field: 'lastMessage',
            headerName: 'Viimane tulemus',
            sortable: true,
            filterable: false,
            flex: 1
        }
    ];

    return (
        <ListView headerProps={{
            title: 'Töökäsud'
        }}>
            <DataGrid
                sorting={{
                    sortModel: [{ field: 'lastSyncTime', sort: 'desc' }],
                }}
                rows={filterByValue(filterByStatus(rows), searchInput)}
                columns={columns}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                filterToolbar={
                    <FilterToolbar
                        inputSearch={{value: searchInput, updateValue: setSearchInput}}
                        filterChips={getFilterChips()}
                        fetchData={fetchAssignments}
                    />
                }
            />
        </ListView>
    );
}