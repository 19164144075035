import {BusChange, BusUsageWithDistance} from "./types";
import {BusUsage, DriverWorkSheet} from "../../../../API/workSchedule/types";
import {WorkSheetWorkItem} from "../../../../API/workSheets/types";
import dayjs from "dayjs";
import {Defect} from "../../../../store/defectsSlice";

export const getBusChanges = (busUsages: BusUsageWithDistance[]): BusChange[] => {
    const result: BusChange[] = [];
    const usages = busUsages.slice();
    usages.sort((a,b) => a.startDateTime.localeCompare(b.startDateTime));
    if (usages.length > 1) {
        let previousUsage = usages[0];
        usages.slice(1).forEach((usage) => {
            result.push({
                previousBusUsage: previousUsage,
                nextBusUsage: usage,
                busIdFrom: previousUsage.busId,
                busIdTo: usage.busId,
                previousUsageEndDateTime: previousUsage.endDateTime,
                previousDriverFirstName: usage.previousDriverFirstName,
                previousDriverLastName: usage.previousDriverLastName,
                nextUsageStartDateTime: usage.startDateTime,
                nextDriverFirstName: previousUsage.nextDriverFirstName,
                nextDriverLastName: previousUsage.nextDriverLastName
            });
            previousUsage = usage;
        });
    }

    return result;
};

export const getBusUsageDistance = (workItems: WorkSheetWorkItem[], busUsage: BusUsage) => {
    const busStartTime = dayjs(busUsage.startDateTime);
    const busEndTime = dayjs(busUsage.endDateTime);

    const itemsWithinBusUsage =  workItems.filter(workItem =>
        !busStartTime.isSame(dayjs(workItem.endDateTime)) && !busEndTime?.isSame(dayjs(workItem.startDateTime))
        && !busStartTime.isAfter(dayjs(workItem.endDateTime)) && !busEndTime?.isBefore(dayjs(workItem.startDateTime)));

    return itemsWithinBusUsage.reduce((totalDistance, workItem) => totalDistance + (workItem.distance ?? 0), 0);
};


export const getWorkSheetsAffectedByDefect = (workSheets: DriverWorkSheet[], savedDefect: Defect): DriverWorkSheet[] => {
    const workSheetsAffectedByDefect = workSheets.filter(workSheet =>
        workSheet.busUsages.find(usage => usage.busId === savedDefect.bus.id)
        && !dayjs(workSheet.startDate).isBefore(dayjs(savedDefect.createdAt), 'd')
        && (!savedDefect.repairedAt || workSheet.startDate <= savedDefect.repairedAt)
        && (!savedDefect.expectedRepairFinishedDateTime || workSheet.startDate <= savedDefect.expectedRepairFinishedDateTime)
    );
    const updatedWorkSheets: DriverWorkSheet[] = [];

    workSheetsAffectedByDefect.forEach(workSheet => {
        const existingDefect = workSheet.defectsOnDay.find(defect => defect.id === savedDefect.id);
        if (existingDefect) {
            updatedWorkSheets.push({
                ...workSheet,
                defectsOnDay: [
                    ...workSheet.defectsOnDay.filter(defect => defect.id !== savedDefect.id),
                    {...existingDefect, description: savedDefect.description}
                ]
            })
        } else {
            updatedWorkSheets.push({
                ...workSheet,
                defectsOnDay: [
                    ...workSheet.defectsOnDay,
                    {
                        ...savedDefect,
                        busId: savedDefect.bus.id,
                        from: savedDefect.createdAt,
                        to: savedDefect.repairedAt ?? savedDefect.expectedRepairFinishedDateTime
                    }
                ]
            })
        }
    });

    return updatedWorkSheets;
};
