import React from "react";
import {Box, Typography} from "@mui/material";
import theme from "../../../theme";
import {ScheduleSetting} from "../../../API/types";
import {getScheduleSettingTranslationFromStr} from "../../../utils/enumTranslations";
import Select from "../Select/Select";
import Checkbox from "../Checkbox/Checkbox";

interface RepetitionRulesInputProps {
    name: string;
    disabled?: boolean;
}

export default function RepetitionRulesInput({name, disabled}: RepetitionRulesInputProps) {
    return (
        <>
            <Box sx={{
                display: 'flex', flexDirection: 'row', width: {xs: '100%', sm: '450px'}, maxWidth: '100%', flexFlow: 'wrap',
                border: '1px solid rgba(0,0,0,0.23)', borderRadius: '4px', p: 1, my: 1, boxSizing: 'border-box'
            }}>
                <Box width="100%">
                    <Typography color={disabled ? 'text.disabled' : 'text.secondary'} sx={{
                        width: 'fit-content', fontSize: '12px', px: 0.5, mt: -2, backgroundColor: theme.palette.common.white
                    }}>
                        Kehtivus nädalapäeviti*
                    </Typography>
                </Box>
                <Checkbox name={`${name}.mon`} label="E" disabled={disabled} />
                <Checkbox name={`${name}.tue`} label="T" disabled={disabled} />
                <Checkbox name={`${name}.wed`} label="K" disabled={disabled} />
                <Checkbox name={`${name}.thu`} label="N" disabled={disabled} />
                <Checkbox name={`${name}.fri`} label="R" disabled={disabled} />
                <Checkbox name={`${name}.sat`} label="L" disabled={disabled} />
                <Checkbox name={`${name}.sun`} label="P" disabled={disabled} />
            </Box>
            <Select
                name={`${name}.scheduleSettings.SCHOOL_HOLIDAY`}
                label="Kehtivus sügisesel, talvisel ja kevadisel koolivaheajal*"
                options={Object.values(ScheduleSetting)}
                translationFunction={getScheduleSettingTranslationFromStr}
                translationEnumType={ScheduleSetting}
                disabled={disabled}
            />
            <Select
                name={`${name}.scheduleSettings.PUBLIC_HOLIDAY`}
                label="Kehtivus riigipühal*"
                options={Object.values(ScheduleSetting)}
                translationFunction={getScheduleSettingTranslationFromStr}
                translationEnumType={ScheduleSetting}
                disabled={disabled}
            />
            <Select
                name={`${name}.scheduleSettings.SUMMER_HOLIDAY`}
                label="Kehtivus suvisel koolivaheajal*"
                options={Object.values(ScheduleSetting)}
                translationFunction={getScheduleSettingTranslationFromStr}
                translationEnumType={ScheduleSetting}
                disabled={disabled}
            />
        </>
    );
}