import axios from "axios";
import {
    Bus,
    BusDetails,
    OdometerReadingRequest,
    OdometerReading,
    UpdateBusRequest,
} from "./types";
import {handleError} from "../utils";
import {Defect, DefectSimple} from "../defects/types";


export const loadBuses = () =>
    axios.get<Bus[]>('/api/buses')
        .then(result => result.data);

export const loadBus = (id: string | number) =>
    axios.get<BusDetails>(`/api/buses/${id}`)
        .then(result => result.data);

export const createBus = (data: UpdateBusRequest) =>
    axios.post<BusDetails>('/api/buses', data)
        .then(result => result.data)
        .catch(handleError);

export const updateBus = (id: string | number, data: UpdateBusRequest) =>
    axios.put<BusDetails>('/api/buses', { id: id, ...data })
        .then(result => result.data)
        .catch(handleError);

export const saveOdometerReading = (id: string | number, data: OdometerReadingRequest) =>
    axios.post<OdometerReading>(`/api/buses/${id}/odometer-readings`, data)
        .then(result => result.data)
        .catch(handleError);

export const updateOdometerReading = (readingId: number, data: OdometerReadingRequest) =>
    axios.put<OdometerReading>(`/api/buses/odometer-readings/${readingId}`, data)
        .then(result => result.data)
        .catch(handleError);

export const deleteOdometerReading = (readingId: number) =>
    axios.delete(`/api/buses/odometer-readings/${readingId}`)
        .then(result => result.data)
        .catch(handleError);

export const getDefectsByBusId = (busId: string | number) =>
    axios.get<Defect[]>(`/api/buses/${busId}/defects`)
        .then(result => result.data)
        .catch(handleError);

export const getRelevantDefects = (busId: number, date: string) =>
    axios.get<DefectSimple[]>(`/api/buses/${busId}/relevant-defects?date=${date}`)
        .then(result => result.data)
        .catch(handleError);