import React from 'react';
import {Box, SpeedDial, SpeedDialAction, useTheme} from "@mui/material";
import {AirlineStopsRounded, AltRoute, Edit, LeakAdd, Menu as MenuIcon, MultipleStop} from "@mui/icons-material";
import {EntityId} from "@reduxjs/toolkit";
import {addPreparationAndFinishingTime, setDialogData} from "../../../../../store/plannerSlice";
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {selectDisplayWorkGroupByIdMemoized} from "../../../store/selectors";
import {WorkGroupActivity, WorkGroupTripDefinition} from "../../../../../API/workGroup/types";


const OPTION_ICON_WIDTH = 45;
const MENU_ICON_WIDTH = 20;

interface WorkGroupCardMenuProps {
    groupId: EntityId;
}

const WorkGroupCardMenu = ({groupId}: WorkGroupCardMenuProps) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const workGroup = useAppSelector(state => selectDisplayWorkGroupByIdMemoized(state, groupId));
    if (!workGroup) return <></>;

    const workGroupHasOppositeWorkGroups =
        [...workGroup.activities, ...workGroup.tripDefinitions].find(
            (item: WorkGroupTripDefinition | WorkGroupActivity) =>
                item.workGroupsOfOppositeType?.length && item.workGroupsOfOppositeType.length > 0
        );

    const dialOptions = [
        <SpeedDialAction
            key={1}
            icon={<Edit sx={{fontSize: '30px'}} />}
            tooltipTitle="Muuda töögrupi andmeid"
            arrow
            onClick={() => dispatch(setDialogData({
                addOrEditWorkGroupDetails: {workGroup: workGroup}
            }))}
        />,
        <SpeedDialAction
            key={2}
            icon={<MultipleStop sx={{fontSize: '30px'}} />}
            tooltipTitle="Lisa ettevalmistus- ja lõpetusaeg"
            arrow
            onClick={() => dispatch(addPreparationAndFinishingTime(groupId))}
        />,
        ...(workGroupHasOppositeWorkGroups ? [] :
            [<SpeedDialAction
                key={3}
                icon={<LeakAdd sx={{fontSize: '30px'}} />}
                tooltipTitle="Loo vastastöögrupp samade tegevustega"
                arrow
                onClick={() => dispatch(setDialogData({
                    copyOppositeWorkGroup: {workGroup: workGroup}
                }))}
            />]
        ),
        <SpeedDialAction
            key={4}
            icon={<AltRoute sx={{fontSize: '30px'}} />}
            tooltipTitle="Poolita töögrupp"
            arrow
            onClick={() => dispatch(setDialogData({
                splitWorkGroup: {workGroupId: workGroup.id}
            }))}
        />,
        <SpeedDialAction
            key={5}
            icon={<AirlineStopsRounded sx={{fontSize: '30px'}} />}
            tooltipTitle="Alusta uut versiooni"
            arrow
            onClick={() => dispatch(setDialogData({
                divideWorkGroup: {originalWorkGroup: workGroup}
            }))}
        />
    ];

    return (
        <Box sx={{position: 'relative'}}>
            <SpeedDial
                ariaLabel="work-group-menu"
                icon={<MenuIcon sx={{fontSize: '25px'}} />}
                direction="right"
                transitionDuration={{enter: 0}}
                sx={{
                    position: 'absolute',
                    zIndex: 30,
                    top: -18,
                    right: -(MENU_ICON_WIDTH + OPTION_ICON_WIDTH * dialOptions.length),
                    bottom: 'auto',
                    left: 'auto',
                    '& .MuiFab-primary': {
                        border: '1px solid',
                        borderColor: theme.palette.divider,
                        color: 'text.secondary',
                        backgroundColor: 'common.white',
                        width: 35,
                        height: 35,
                        boxShadow: '0px 0px 5px lightgrey',
                        ':hover': {
                            backgroundColor: 'action.hover'
                        }
                    },
                    transform: 'scale(0.6)',
                }}>
                    {dialOptions}
            </SpeedDial>
        </Box>
    );
};

export default WorkGroupCardMenu;
