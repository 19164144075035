import React, {useContext, useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import {Box, DialogActions, DialogContent, Typography} from "@mui/material";
import {WorkGroup} from '../../../../../API/types';
import {loadWorkGroupsOnDate} from "../../../../../API";
import {ToastContext} from "../../../../../contexts/ToastContext";
import Button from "../../../../../components/Button/Button";
import {Add, Clear} from "@mui/icons-material";
import DialogTitle from "@mui/material/DialogTitle";
import {Form, Formik, FormikHelpers} from 'formik';
import * as Yup from "yup";
import {validationSchema} from "../../../../../utils/formValidation";
import {getResourceTypeTranslation} from "../../../../../utils/enumTranslations";
import Autocomplete from "../../../../../components/Form/Select/AutoComplete";
import {useAppSelector} from "../../../../../hooks";
import {selectSelectedRegion} from "../../../../../store/regionSlice";
import {selectDate} from "../../../../../store/viewSlice";
import {AddOppositeWorkGroupForm} from "./types";
import {getOppositeResourceType} from "../../../../../utils/utils";

const oppositeWorkGroupsValidationSchema = Yup.object().shape({
    workGroup: validationSchema('Töögrupp').fields.objectRequired,
});

const defaultValues: AddOppositeWorkGroupForm = {
    workGroup: null
};

interface AddOppositeWorkGroupProps {
    isOpen: boolean;
    handleClose: () => void;
    handleSubmit: (form: AddOppositeWorkGroupForm, formHelpers: FormikHelpers<AddOppositeWorkGroupForm>) => void;
    title: string;
    workGroup: WorkGroup;
}

export default function AddOppositeWorkGroupDialog({isOpen, handleClose, handleSubmit, title, workGroup }: AddOppositeWorkGroupProps) {
    const { addToast } = useContext(ToastContext);
    const selectedRegion = useAppSelector(selectSelectedRegion);
    const selectedDate = useAppSelector(selectDate);

    const [workGroupOptions, setWorkGroupOptions] = useState<WorkGroup[]>();
    const oppositeResourceType = getOppositeResourceType(workGroup.type);

    useEffect(() => {
        loadWorkGroupOptions();
    }, []);

    const loadWorkGroupOptions = () => {
        if (selectedRegion) {
            loadWorkGroupsOnDate(selectedRegion.id, oppositeResourceType, selectedDate)
                .then((result => {
                    setWorkGroupOptions(result);
                }))
                .catch(() => {
                    setWorkGroupOptions([]);
                    addToast({type: 'error', text: 'Töögruppide andmete pärimisel ilmnes viga'});
                });
        }
    };

    return (workGroupOptions ?
        <Dialog open={isOpen} onClose={handleClose}>
            <Box sx={{width: '400px', maxWidth: '100%', mt: 1, p: {xs: 0, sm: 3}}}>
                <DialogTitle>
                    <Typography variant="h5" component="div">
                        {getResourceTypeTranslation(oppositeResourceType, 'genitive')} töögrupi lisamine
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {title}
                    </Typography>
                </DialogTitle>
                <Formik
                    initialValues={defaultValues}
                    validationSchema={oppositeWorkGroupsValidationSchema}
                    onSubmit={handleSubmit}
                >
                    <Form>
                        <DialogContent sx={{pt: 0}}>
                            <Autocomplete
                                options={workGroupOptions}
                                name="workGroup"
                                label="Töögrupp"
                                getOptionLabel={option => option.code}
                                noOptionsText={
                                    getResourceTypeTranslation(workGroup.type, 'genitive')
                                    + ' töögrupi kordumisreeglitega sobivaid ' +
                                    getResourceTypeTranslation(oppositeResourceType, 'genitive').toLowerCase() +
                                    ' töögruppe ei leitud'
                                }
                            />
                        </DialogContent>
                        <DialogActions sx={{justifyContent: 'center', maxWidth: '100%'}}>
                            <Box maxWidth="50%">
                                <Button
                                    text="Loobu"
                                    variant="outlined"
                                    onClick={handleClose}
                                    startIcon={<Clear />}
                                />
                            </Box>
                            <Box maxWidth="50%">
                                <Button text="Lisa" type="submit" startIcon={<Add />} />
                            </Box>
                        </DialogActions>
                    </Form>
                </Formik>
            </Box>
        </Dialog>
        :
        <></>
    );
}
