import React from "react";
import {StartAndEndTimeWithModifier} from "../../../../../types";
import TooltipRowWithLink from "../components/TooltipRowWithLink";
import routes from "../../../../../../../routes";
import StartAndEndTime from "../components/StartAndEndTime";
import Distance from "../components/Distance";
import OppositeWorkSheet from "../components/OppositeWorkSheet";
import TooltipRow from "../components/TooltipRow";


interface CharterTripTooltipContentProps extends StartAndEndTimeWithModifier {
    id: string;
    code: string;
    route: string;
    distance?: number;
    workSheetsOfOppositeType?: number[];
}

const CharterTripTooltipContent = ({id, code, route, distance, workSheetsOfOppositeType, startTime, startTimeIsOnNextDay, endTime, endTimeIsOnNextDay}: CharterTripTooltipContentProps) => {
    return (
        <>
            <TooltipRowWithLink
                text={code}
                route={routes.AUTHENTICATED.CHARTER_TRIPS.EDIT.replace(':id', id)}
            />
            <StartAndEndTime startTime={startTime} startTimeIsOnNextDay={startTimeIsOnNextDay} endTime={endTime} endTimeIsOnNextDay={endTimeIsOnNextDay} />
            <TooltipRow>{route}</TooltipRow>
            <Distance distance={distance} />
            <OppositeWorkSheet workSheetIds={workSheetsOfOppositeType ?? []} />
        </>
    )
};

export default CharterTripTooltipContent;