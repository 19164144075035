import {createAsyncThunk, EntityId} from "@reduxjs/toolkit";
import dayjs from "dayjs";
import {AppDispatch, RootState} from "../../../../store";
import {
    ApiError,
    ResourceType,
    SaveWorkScheduleItem,
    SaveWorkSheet,
    WorkScheduleItemStatus,
    WorkScheduleItemType
} from "../../../../API/types";
import {
    createWorkScheduleItem, deleteWorkScheduleItem,
    generateWorkSheet as apiGenerateWorkSheet,
    getWorkSheetsByRegionAndDateWithPrevAndNextDay,
    updateWorkScheduleItem
} from "../../../../API";
import {GenerateWorkSheetRequest} from "../../../../API//types";
import {setToast} from "../../../../store/toastSlice";
import {mapErrors} from "../../../../utils/errorMapping";
import {WorkGroup, WorkGroupItem} from "../../../../store/workGroupSlice";
import {WorkItem, WorkScheduleItem} from "../../../../store/workScheduleItemSlice";
import {DefectSimple} from "../../../../API/defects/types";

export const fetchWorkSheets = createAsyncThunk<
    {
        workGroups: WorkGroup[],
        workGroupItems: WorkGroupItem[],
        workScheduleItems: WorkScheduleItem[],
        workItems: WorkItem[],
        defects: DefectSimple[],
    },
    void,
    {
        state: RootState,
        dispatch: AppDispatch,
    }
>(
    'workSheets/list',
    async (_, {getState}): Promise<any> => {
        const state = getState();
        const date = dayjs(state.view.date);
        const selectedRegionId = getState().region.selected;

        const result = await getWorkSheetsByRegionAndDateWithPrevAndNextDay(selectedRegionId, date);

        return {
            workGroups: result.workGroups.map(workGroup => ({
                    id: workGroup.id,
                    versionId: workGroup.versionId,
                    validFrom: workGroup.validFrom,
                    validTo: workGroup.validTo,
                    code: workGroup.code,
                    type: workGroup.type,
                    workGroupItemIds: [
                        ...workGroup.activityIds.map(id => `activity-${id}`),
                        ...workGroup.tripDefinitionIds.map(id => `trip-definition-${id}`),
                    ]
                })),
            workGroupItems: [
                ...result.tripDefinitions.map(item => ({
                    id: item.id,
                    type: item.type,
                    startTime: item.startTime,
                    startTimeIsOnNextDay: item.startTimeIsOnNextDay,
                    endTime: item.endTime,
                    endTimeIsOnNextDay: item.endTimeIsOnNextDay,
                    distance: item.distance,
                })),
                ...result.activities.map(item => ({
                    id: item.id,
                    type: item.type,
                    startTime: item.startTime,
                    startTimeIsOnNextDay: item.startTimeIsOnNextDay,
                    endTime: item.endTime,
                    endTimeIsOnNextDay: item.endTimeIsOnNextDay,
                    distance: item.distance,
                })),
            ],
            workScheduleItems: result.workScheduleItems.map(item => ({
                id: item.id,
                workGroupId: item.workGroupId,
                type: item.type,
                resourceId: item.resourceId,
                resourceType: item.resourceType,
                startDate: item.startDate,
                endDate: item.endDate,
                status: item.status,
                regionId: item.regionId,
                comment: item.comment ?? '',
                confirmedAt: item.confirmedAt,
                workItemIds: item.workItemIds ?? [],
            })),
            workItems: result.workItems.map(item => ({
                id: item.id ?? 0,
                type: item.type,
                startDateTime: item.startDateTime,
                endDateTime: item.endDateTime,
                distance: item.distance,
            })),
            defects: result.defects,
        };
    }
);

export const generateWorkSheet = createAsyncThunk<
    {
        workScheduleItems: WorkScheduleItem[],
        workItems: WorkItem[],
    },
    GenerateWorkSheetRequest
>(
    'workSheets/generate',
    async (generateWorkSheetRequest, { dispatch }): Promise<any> => {
        try {
            const result = await apiGenerateWorkSheet(generateWorkSheetRequest);
            dispatch(setToast({type: 'success', text: 'Sõiduleht genereeritud'}));

            return {
                workScheduleItems: result.workScheduleItems.map(item => ({
                    id: item.id,
                    workGroupId: item.workGroupId,
                    type: item.type,
                    resourceId: item.resourceId,
                    resourceType: item.resourceType,
                    startDate: item.startDate,
                    endDate: item.endDate,
                    status: item.status,
                    regionId: item.regionId,
                    comment: item.comment ?? '',
                    confirmedAt: item.confirmedAt,
                    workItemIds: item.workItemIds ?? [],
                })),
                workItems: result.workItems.map(item => ({
                    id: item.id ?? 0,
                    type: item.type,
                    startDateTime: item.startDateTime,
                    endDateTime: item.endDateTime,
                    distance: item.distance,
                })),
            };
        } catch (error) {
            dispatch(setToast({type: 'error', text: mapErrors(error as ApiError) ?? 'Sõidulehe genereerimisel tekkis viga'}));
            throw error;
        }
    }
);

export const createWorkSheet = createAsyncThunk<
    {
        workScheduleItemId: EntityId,
        newId?: number,
        resourceId?: number,
    },
    SaveWorkSheet,
    {
        state: RootState,
        dispatch: AppDispatch,
    }
>(
    'workSheets/create',
    async (workSheet): Promise<any> => {
        const saveItem: SaveWorkScheduleItem = {
            busId: workSheet.resourceType === ResourceType.VEHICLE ? (workSheet.resourceId ?? undefined) : undefined,
            comment: '',
            driverContractId: workSheet.resourceType === ResourceType.DRIVER ? (workSheet.resourceId ?? undefined) : undefined,
            endDate: workSheet.date,
            startDate: workSheet.date,
            status: workSheet.status,
            type: WorkScheduleItemType.WORK_GROUP,
            workGroupId: workSheet.workGroupId,

        };
        const result = await createWorkScheduleItem(saveItem);

        return {
            workScheduleItemId: `${undefined}-${workSheet.workGroupId}-${workSheet.date}`,
            newId: result.id,
            resourceId: workSheet.resourceId ?? undefined,
        }
    }
);

export const updateWorkSheet = createAsyncThunk<
    {
        workScheduleItemId: EntityId,
        resourceId?: number,
        hasNewId?: boolean,
    },
    SaveWorkSheet,
    {
        state: RootState,
        dispatch: AppDispatch,
    }
>(
    'workSheets/update',
    async (workSheet, {dispatch}): Promise<any> => {
        if (!workSheet.id) {
            dispatch(setToast({type: 'error', text: 'Sõidulehe salvestamisel tekkis viga'}));
            throw new Error();
        }
        if (!workSheet.resourceId && workSheet.workGroupId && workSheet.status === WorkScheduleItemStatus.SCHEDULED) {
            try {
                await deleteWorkScheduleItem(workSheet.id);
            } catch (e) {
                dispatch(setToast({type: 'error', text: 'Sõidulehe kustutamisel tekkis viga'}));
                throw e;
            }

            return {
                workScheduleItemId: `${workSheet.id}-${workSheet.workGroupId ?? undefined}-${workSheet.date}`,
                hasNewId: true,
            }
        } else {
            const saveItem: SaveWorkScheduleItem = {
                busId: workSheet.resourceType === ResourceType.VEHICLE ? (workSheet.resourceId ?? undefined) : undefined,
                comment: '',
                driverContractId: workSheet.resourceType === ResourceType.DRIVER ? (workSheet.resourceId ?? undefined) : undefined,
                endDate: workSheet.date,
                startDate: workSheet.date,
                status: workSheet.status,
                type: WorkScheduleItemType.WORK_GROUP,
                workGroupId: workSheet.workGroupId,
                resourceType: workSheet.resourceType,
                regionId: workSheet.regionId,

            };

            await updateWorkScheduleItem(workSheet.id, saveItem)
                .catch(error => {
                    dispatch(setToast({type: 'error', text: mapErrors(error) ?? 'Sõidulehe salvestamisel tekkis viga'}));
                    throw error;
                });

            return {
                workScheduleItemId: `${workSheet.id}-${workSheet.workGroupId ?? undefined}-${workSheet.date}`,
                resourceId: workSheet.resourceId ?? undefined,
            };
        }
    }
);
