import React from 'react';
import {useAppSelector} from "../../../../../hooks";
import {selectDisplayWorkGroupActivityById} from "../../../store/selectors";
import {EntityId} from "@reduxjs/toolkit";
import {shallowEqual} from "react-redux";
import ActivityCard from "../ActivityCard";
import {selectIsPlannerRowDisabled} from "../../../../../store/workScheduleItemSlice";


interface ActivityCardWrapperProps {
    activityId: EntityId;
    currentGroupId: EntityId;
}

const ActivityCardWrapper = ({activityId, currentGroupId}: ActivityCardWrapperProps) => {
    const activity = useAppSelector(state => selectDisplayWorkGroupActivityById(state, activityId), shallowEqual);
    const workGroupOfOppositeTypeId = activity && activity.workGroupsOfOppositeType && activity.workGroupsOfOppositeType.length > 0
        ? activity.workGroupsOfOppositeType[0].id : undefined;
    const isRowDisabled = useAppSelector(state => selectIsPlannerRowDisabled(state, currentGroupId));

    return (activity ?
        <ActivityCard
            activity={activity}
            currentGroupId={currentGroupId}
            isRowDisabled={isRowDisabled}
            groupOfOppositeTypeId={workGroupOfOppositeTypeId}
        />
        :
        <></>
    )
};

export default ActivityCardWrapper;
