import {Navigate} from "react-router-dom";
import routes from "../../routes";
import React, {ReactElement} from "react";
import {useAuth} from "../../contexts/AuthContext";
import {useLocation} from "react-router";
import Loader from "../../components/Loader/Loader";


interface ProtectedLayoutWrapperProps {
    isLoading: boolean;
    children: ReactElement;
}

const ProtectedLayoutWrapper = ({ isLoading, children }: ProtectedLayoutWrapperProps) => {
    const location = useLocation();
    const { validateAuthentication } = useAuth();
    const isAuthenticated = validateAuthentication();

    return isLoading && !isAuthenticated
        ? <Loader />
        : isAuthenticated
            ? children
            : <Navigate to={routes.UNAUTHENTICATED.ROOT} state={{ from: location }} />;
}

export default ProtectedLayoutWrapper;
