import React from "react";
import {WorkGroupVersion} from "../../../../../API/workGroup/types";
import {MenuItem, SxProps, TextField as MuiTextField} from "@mui/material";
import {PAGE_HEADER_ITEM_HEIGHT} from "../../../../../components/PageHeader/PageHeader";
import {getDisplayValidDatesRange} from "../../../../../utils/dateUtils";


const VersionSelect = ({versions, selectedVersion, handleChange, sx}: {
    versions: WorkGroupVersion[];
    selectedVersion: WorkGroupVersion | undefined;
    handleChange: (version: WorkGroupVersion | undefined) => void;
    sx: SxProps,
}) => {
    const options = versions.sort((a,b) => a.validFrom.localeCompare(b.validFrom)) ?? [];
    return (
        <MuiTextField
            value={selectedVersion?.id}
            onChange={(event) => {
                handleChange(options.find(wgv => wgv.id === Number(event.target.value)));
            }}
            select
            size="small"
            label="Versioon"
            InputProps={{
                style: {height: PAGE_HEADER_ITEM_HEIGHT}
            }}
            sx={{
                ...sx,
                width: {xs: '100%', sm: 'auto'},
                my: 0.5,
            }}
        >
            {options.map(option =>
                <MenuItem key={option.id} value={option.id}>
                    {getDisplayValidDatesRange(option)}
                </MenuItem>
            )}
        </MuiTextField>
    );
};

export default VersionSelect;