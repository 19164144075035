import React, {ReactNode} from "react";
import Loader from "./Loader";

interface LoaderWithChildrenProps {
    loading: boolean;
    children: ReactNode;
}

export default function LoaderWithChildren({loading, children}: LoaderWithChildrenProps) {
    if (loading) {
        return (
            <Loader />
        );
    }

    return <>{children}</>;
}
