import React, {useState} from "react";
import CardBase from "../../../components/CardBase";
import {blue} from "@mui/material/colors";
import {Box, Collapse, Tooltip, Typography} from "@mui/material";
import BusLabel from "../../../components/BusLabel";
import {ArrowRightAlt, WarningAmberRounded} from "@mui/icons-material";
import TimeLabel from "../../../components/TimeLabel";
import {BusChange} from "../../../types";
import {useAppSelector} from "../../../../../../../hooks";
import {
    selectCurrentDriverBusById,
} from "../../../../../../../store/currentDriverWorkSheetsSlice";
import {formatName} from "../../../../../workSchedule/utils";

interface BusChangeCardProps {
    busChange: BusChange;
}

const BusChangeCard = ({busChange}: BusChangeCardProps) => {
    const [cardOpen, setCardOpen] = useState(false);
    const busFrom = useAppSelector(state => selectCurrentDriverBusById(state, busChange.busIdFrom));
    const busTo = useAppSelector(state => selectCurrentDriverBusById(state, busChange.busIdTo));
    if (!busFrom || !busTo) return null;

    const handleClick = () => setCardOpen(prevState => !prevState);

    return (
        <CardBase sx={{backgroundColor: blue[50]}} onClick={handleClick}>
            <Box sx={{display: 'flex', justifyContent: 'space-between', pb: 0.5}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <BusLabel licencePlateNumbers={[busFrom.licencePlateNumber]} />
                    <ArrowRightAlt sx={{fontSize: '16px', px: 0.5, color: 'text.secondary'}} />
                    <BusLabel licencePlateNumbers={[busTo.licencePlateNumber]} />
                </Box>
                <Typography component="div" sx={{display: 'flex', alignItems: 'center'}}>
                    <Tooltip title="Bussivahetus" arrow placement="top">
                        <WarningAmberRounded color="info" sx={{fontSize: '18px', pr: 0.5, mb: 0.2}} />
                    </Tooltip>
                    <TimeLabel startDateTime={busChange.previousUsageEndDateTime} endDateTime={busChange.nextUsageStartDateTime} />
                </Typography>
            </Box>
                <Collapse in={cardOpen} >
                    { busFrom && busChange.nextDriverFirstName &&
                        <Typography component="div" variant="body2" sx={{pb: 0.5}}>
                            {busFrom.licencePlateNumber} võtab vastu {formatName({
                                firstName: busChange.nextDriverFirstName ?? '',
                                lastName: busChange.nextDriverLastName ?? ''
                            })}
                        </Typography>
                    }
                    { busTo && busChange.previousDriverFirstName &&
                        <Typography component="div" variant="body2">
                            {busTo.licencePlateNumber} annab üle {formatName({
                                firstName: busChange.previousDriverFirstName ?? '',
                                lastName: busChange.previousDriverLastName ?? ''
                            })}
                        </Typography>
                    }
                </Collapse>
        </CardBase>
    );
};

export default BusChangeCard;
