import React from 'react';
import {PLANNER_ROW_HEIGHT, PLANNER_SIDEBAR_WIDTH} from "../../constants";
import {MAX_DRIVER_WORK_GROUP_DURATION_HOURS} from "../../../../constants";
import {Box, Link, Tooltip, Typography, useTheme} from "@mui/material";
import Repetition from "../../../RepetitionRules/RepetitionRules";
import routes from "../../../../routes";
import {WarningAmberRounded} from '@mui/icons-material';
import {EntityId} from "@reduxjs/toolkit";
import {Region, RepetitionRules} from "../../../../API/types";
import {useAppSelector} from "../../../../hooks";
import {selectPlannerType} from "../../../../store/plannerSlice";
import {PlannerType} from "../../types";
import WorkGroupCardMenu from "./components/WorkGroupCardMenu";
import WorkSheetCardMenu from "./components/WorkSheetCardMenu";
import grey from '@mui/material/colors/grey';
import GroupCardTooltip from "./components/GroupCardTooltip";


interface PlannerItemGroupCardProps {
    groupId: EntityId;
    index: number;
    workGroupCode: string;
    workGroupId?: number;
    workSheetId?: string;
    resourceName?: string;
    repetition?: RepetitionRules;
    isDurationOverLimit?: boolean;
    otherRegion?: Region,
    isRowDisabled?: boolean;
}

const getBackGround = (otherRegion: Region | undefined) => {
    if (otherRegion) {
        const stripeColor = grey[100];
        return {
            background: `repeating-linear-gradient(
                -45deg,
                transparent 0px,
                transparent 10px,
                ${stripeColor} 10px,
                ${stripeColor} 15px
            )`
        };
    } else {
        return {};
    }
};

export const PlannerItemGroupCard = ({
    groupId,
    workGroupCode,
    workGroupId,
    workSheetId,
    resourceName,
    index,
    repetition,
    isDurationOverLimit,
    otherRegion,
    isRowDisabled = false
}: PlannerItemGroupCardProps) => {
    const theme = useTheme();
    const plannerType = useAppSelector(selectPlannerType);

    return (
        <GroupCardTooltip groupId={groupId} otherRegion={otherRegion}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                width: `${PLANNER_SIDEBAR_WIDTH}px`,
                maxWidth: `${PLANNER_SIDEBAR_WIDTH}px`,
                minHeight: `${PLANNER_ROW_HEIGHT - (index === 0 ? 2 : 0)}px`,
                maxHeight: `${PLANNER_ROW_HEIGHT - (index === 0 ? 2 : 0)}px`,
                borderTop: index === 0 ? '' : `2px solid ${theme.palette.divider}`,
                boxSizing: 'border-box',
            }}>
                <Box sx={{
                    width: '100%',
                    height: '100%',
                    ...getBackGround(otherRegion),
                    p: '4px',
                    boxSizing: 'border-box',
                }}>
                    <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%'}}>
                        <Box sx={{
                            display: 'flex',
                            maxWidth: `${PLANNER_SIDEBAR_WIDTH - (plannerType === PlannerType.WORK_GROUP ? 30 : 0)}px`,
                            overflow: 'hidden',
                            opacity: isRowDisabled ? 0.5 : 1,
                            color: isDurationOverLimit ? 'warning.light' : '',
                        }}>
                            <Typography component="div" sx={{
                                fontSize: '15px',
                                width: '100%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                color: 'inherit',
                            }}>
                                {workGroupId ?
                                    <Link
                                        target="_blank"
                                        href={routes.AUTHENTICATED.WORK_GROUPS.EDIT.replace(':id', workGroupId.toString())}
                                        sx={{color: 'inherit'}}
                                    >
                                        {workGroupCode}
                                    </Link>
                                    :
                                    workGroupCode
                                }
                            </Typography>
                            {isDurationOverLimit &&
                                <Tooltip
                                    title={`Tööpäeva pikkus ületab lubatud ${MAX_DRIVER_WORK_GROUP_DURATION_HOURS} tunni piiri`}
                                    arrow
                                    placement="top"
                                >
                                    <WarningAmberRounded sx={{pl: 0.2, fontSize: '18px', lineHeight: '10px', mb: -0.2}} />
                                </Tooltip>
                            }
                        </Box>
                        {plannerType === PlannerType.WORK_GROUP && <WorkGroupCardMenu groupId={groupId} />}
                        {plannerType === PlannerType.WORK_SHEET && !isRowDisabled && <WorkSheetCardMenu groupId={groupId} />}
                    </Box>
                    {repetition &&
                        <Typography align="left" component="div">
                            <Repetition repetition={repetition} fontSize="small" />
                        </Typography>
                    }
                    {plannerType === PlannerType.WORK_SHEET &&
                        <Typography sx={{
                            fontSize: '14px',
                            lineHeight: '14px',
                            maxWidth: `${PLANNER_SIDEBAR_WIDTH - 10}px`,
                            whiteSpace: 'nowrap',
                            opacity: isRowDisabled ? 0.5 : 1
                        }}>
                            {resourceName && workSheetId ?
                                <Link
                                    target="_blank"
                                    href={routes.AUTHENTICATED.WORK_SHEETS.DETAILS.replace(':id', workSheetId)}
                                    sx={{
                                        lineHeight: '14px',
                                        color: 'inherit',
                                        maxWidth: `${PLANNER_SIDEBAR_WIDTH}px`,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: 'inline-block'
                                    }}
                                >
                                    {resourceName}
                                </Link>
                                :
                                resourceName ?? 'määramata' // TODO - make a dropdown to choose
                            }
                        </Typography>
                    }
                </Box>
            </Box>
        </GroupCardTooltip>
    );
};

export default PlannerItemGroupCard;
