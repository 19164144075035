import React, {useEffect, useMemo} from 'react';
import {Box, useMediaQuery} from '@mui/material';
import {CharterTripStatus} from "../../../../API/types";
import {GridColDef, GridValueGetterParams} from '@mui/x-data-grid';
import {filterByValue} from "../../../../utils/utils";
import theme from "../../../../theme";
import {Permission} from "../../../../types";
import Visible from '../../../../components/Visible/Visible';
import DataGrid from "../../../../components/DataGrid/DataGrid";
import {useNavigate, useOutletContext} from 'react-router-dom';
import routes from "../../../../routes";
import {useAppDispatch, useAppSelector, useSearchRegions} from "../../../../hooks";
import NavLink from "../../../../components/NavLink/NavLink";
import NavIcon from "../../../../components/Icon/NavIcon";
import {SearchContextType} from "../../../../layouts/SearchLayoutWrapper";
import ListView from "../../../../layouts/ListViewWrapper";
import {
    deleteCharterTrip,
    fetchCharterTrips,
    selectCharterTripsWithSearchFields
} from "../../../../store/charterTripsSlice";
import ActionIcon from "../../../../components/Icon/ActionIcon";
import StatusWithProgress from "./components/StatusWithProgress";
import CopyInvoiceButton from "./components/CopyInvoiceButton";
import InvoiceCheck from "./components/InvoiceCheck";
import FilterToolbar from "../../../../components/FilterToolbar/FilterToolbar";
import CopyInfoButton from "./components/CopyInfoButton";


export default function CharterTrips() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {searchInput, setSearchInput, paginationModel, setPaginationModel} = useOutletContext<SearchContextType>();
    const charterTrips = useAppSelector(selectCharterTripsWithSearchFields);
    const isScreenSmall = useMediaQuery(theme.breakpoints.down('xl'));
    const [searchRegions] = useSearchRegions();

    useEffect(() => {
        dispatch(fetchCharterTrips());
    }, []);

    const filteredRows = useMemo(() =>
        filterByValue(charterTrips, searchInput)
            .filter(charterTrip =>
                searchRegions.length === 0 || searchRegions.find((region => region.name === charterTrip.transportContract.regionName))
            )
        , [charterTrips, searchInput, searchRegions]
    );

    const handleAddTripDefinition = () => navigate(routes.AUTHENTICATED.CHARTER_TRIPS.ADD);

    const columns: GridColDef[] = [
        {
            field: 'tripNumber',
            headerName: 'Sõidu nr',
            sortable: true,
            disableColumnMenu: true,
            width: isScreenSmall ? 70 : 80,
            renderCell: (params) =>
                <NavLink
                    id={params.row.id}
                    value={params.row.id}
                    displayValue={params.value}
                    route={routes.AUTHENTICATED.CHARTER_TRIPS.EDIT}
                    navPermission={Permission.UpdateCharterTrips}
                />
        },
        {
            field: 'orderNumber',
            headerName: 'Tellimuse nr',
            sortable: true,
            disableColumnMenu: true,
            width: isScreenSmall ? 90 : 100,
        },
        {
            field: 'displayStatus',
            headerName: 'Staatus',
            sortable: true,
            disableColumnMenu: true,
            width: isScreenSmall ? 50 : 105,
            renderCell: (params) => <StatusWithProgress status={params.row.status} showCompactVersion={isScreenSmall} />
        },
        {
            field: 'isInvoiced',
            headerName: 'Arve',
            sortable: true,
            disableColumnMenu: true,
            width: 55,
            renderCell: params => <InvoiceCheck isInvoiced={!!params.value} />
        },
        {
            field: 'client',
            headerName: 'Klient',
            sortable: true,
            disableColumnMenu: true,
            width: isScreenSmall ? 90 : 120,
        },
        {
            field: 'route',
            headerName: 'Marsruut',
            sortable: true,
            disableColumnMenu: true,
            width: isScreenSmall ? 160 : 200,
        },
        {
            field: 'displayDateTime',
            headerName: 'Aeg',
            sortable: true,
            disableColumnMenu: true,
            width: 160,
        },
        {
            field: 'numberOfPassengers',
            headerName: 'Pax',
            sortable: true,
            disableColumnMenu: true,
            width: isScreenSmall ? 50 : 60,
        },
        {
            field: 'transportContract',
            headerName: 'Piirkond',
            sortable: true,
            disableColumnMenu: true,
            width: isScreenSmall ? 100 : 110,
            valueGetter: (params: GridValueGetterParams) => params.value.regionName,
        },
        {
            field: 'driver',
            headerName: 'Juht',
            sortable: true,
            disableColumnMenu: true,
            width: isScreenSmall ? 100 : 120,
            valueGetter: (params: GridValueGetterParams) => params.value ? params.value.lastName : '',
        },
        {
            field: 'bus',
            headerName: 'Buss',
            sortable: true,
            disableColumnMenu: true,
            width: 80,
            valueGetter: (params: GridValueGetterParams) => params.value?.licencePlateNumber,
        },
        {
            field: 'comment',
            headerName: 'Lisainfo',
            sortable: true,
            disableColumnMenu: true,
            flex: 1,
            minWidth: isScreenSmall ? 100 : 200,
        },
        {
            field: 'otherSearchFields',
            headerName: '',
            sortable: false,
            disableColumnMenu: true,
            align: 'right',
            minWidth: 160,
            renderCell: (params) => {
                const isDeleteDisabled = params.row.status === CharterTripStatus.PLANNED || params.row.status === CharterTripStatus.DONE;

                return (
                    <Visible permission={Permission.UpdateCharterTrips}>
                        <Box>
                            <CopyInfoButton charterTripId={params.row.id} />
                            <CopyInvoiceButton charterTripId={params.row.id} />
                            <NavIcon
                                type="COPY"
                                tooltipTitle="Kopeeri andmed uue sõidu lisamiseks"
                                id={params.row.id}
                                route={routes.AUTHENTICATED.CHARTER_TRIPS.ADD}
                                copyState={{copiedCharterTrip: params.row}}
                            />
                            <NavIcon type="EDIT" id={params.row.id} route={routes.AUTHENTICATED.CHARTER_TRIPS.EDIT} />
                            <ActionIcon
                                type="DELETE"
                                id={params.row.id}
                                tooltipTitle={isDeleteDisabled ? 'Planeeritud sõitu ei saa kustutada' : 'Kustuta'}
                                disabled={isDeleteDisabled}
                                handleClick={() => {
                                    if (confirm(
                                        (params.row.isInvoiced ? 'Sõidu arve on juba väljastatud. ' : '')
                                        + `Kas oled kindel, et soovid sõidu #${params.row.id} kustutada?`
                                    )) {
                                        dispatch(deleteCharterTrip({id: params.row.id}));
                                    }
                                }}
                            />
                        </Box>
                    </Visible>
                );
            }
        }
    ];

    return (
        <ListView
            headerProps={{
                title: 'Tellimusveod',
                buttonProps: {
                    title: 'Lisa sõit',
                    onClick: handleAddTripDefinition,
                    permission: Permission.UpdateCharterTrips
                },
            }}
            isLoading={!charterTrips}
        >
            <DataGrid
                rows={filteredRows}
                columns={columns}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                filterToolbar={
                    <FilterToolbar inputSearch={{value: searchInput, updateValue: setSearchInput, height: 40}} showRegionsSearch/>
                }
            />
        </ListView>
    );
}
