import React from 'react';
import {Button as MuiButton, ButtonProps as MuiButtonProps, SxProps} from '@mui/material';
import {ReactJSXElement} from '@emotion/react/types/jsx-namespace';
import {Theme} from "@mui/material/styles";


export interface ButtonProps extends MuiButtonProps {
    text: string | ReactJSXElement;
    size?: 'small' | 'medium' | 'large',
    disabled?: boolean;
    onClick?: () => void;
    startIcon?: ReactJSXElement;
    styles?:  SxProps<Theme>;
}

export default function TextButton({text, size, disabled, onClick, startIcon, styles}: ButtonProps) {
    return (
        <MuiButton
            variant="text"
            size={size}
            startIcon={startIcon}
            onClick={onClick}
            sx={{width: {xs: '100%', sm: 'fit-content', minWidth: 'fit-content', whiteSpace: 'noWrap'}, ...styles}}
            disabled={disabled}
        >
            {text}
        </MuiButton>
    );
}
