import {useAppDispatch, useAppSelector} from "../../../../../../../hooks";
import React, {useEffect} from "react";
import TooltipRow from "./TooltipRow";
import {fetchDefectById, selectDefectById} from "../../../../../../../store/defectsSlice";
import {PriorityIconText} from "../../../../../../../scenes/authenticated/defects/detailsView/components/DefectFields";
import {Box, Typography} from "@mui/material";
import {getDisplayValidDatesRange} from "../../../../../../../utils/dateUtils";
import NavLink from "../../../../../../../components/NavLink/NavLink";
import routes from "../../../../../../../routes";
import {Permission} from "../../../../../../../types";


const Defect = ({defectId, comment}: { defectId?: number | string | null, comment?: string | null }) => {
    const dispatch = useAppDispatch();
    const defect = useAppSelector(state => selectDefectById(state, defectId ?? 0));

    useEffect(() => {
        if (defectId && !defect) {
            dispatch(fetchDefectById({id: defectId, ignoreAccessError: true}));
        }
    }, []);

    if (!defect && !comment) return null;

    return (
        <TooltipRow padTop>
            {defect &&
                <>
                    <Typography variant="body2" sx={{color: 'inherit', pt: 1}}>Rikke info:</Typography>
                    <Box key={defect.id} sx={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
                        <PriorityIconText key={defect.id} priority={defect.priority} priorityDisplayStr="" showCompactView />
                        <Typography variant="body2" sx={{color: 'inherit'}}>
                            <NavLink
                                id={defect.id.toString()}
                                route={routes.AUTHENTICATED.DEFECTS.EDIT}
                                navPermission={Permission.ReadDefects}
                                openInNewWindow
                                value={getDisplayValidDatesRange({
                                    validFrom: defect.createdAt,
                                    validTo: defect.repairedAt ?? defect.expectedRepairFinishedDateTime,
                                    useEllipsisFallback: true
                                })}
                                color="inherit"
                                textDecoration
                            />
                            {` (${defect.description})`}
                        </Typography>
                    </Box>
                </>
            }
            {comment &&
                <Typography variant="body2" sx={{color: 'inherit'}}>Kommentaar juhile: {`"${comment}"`}</Typography>
            }
        </TooltipRow>
    );
};

export default Defect;
