import {Box, Tooltip, useMediaQuery} from "@mui/material";
import {Refresh} from "@mui/icons-material";
import {getTimeString} from "../../../utils/dateUtils";
import React, {useEffect, useState} from "react";
import dayjs, {Dayjs} from "dayjs";
import {Theme} from "@mui/material/styles";
import Button from "../../Button/Button";

export interface DataRefreshProps {
    date: Dayjs,
    handleRefresh: (date: Dayjs) => void,
    color?: string;
}

const DataRefresh = ({date, handleRefresh, color}: DataRefreshProps) => {
    const [refreshTime, setRefreshTime] = useState<Dayjs | null>(null);

    const handleRefreshClicked = () => {
        handleRefresh(date);
        setRefreshTime(dayjs());
    }

    useEffect(() => {
        setRefreshTime(dayjs());
    }, [date]);

    const showLabel = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

    return (
        <Tooltip title={refreshTime && `Värskendatud ${getTimeString(refreshTime)}`} placement="top" arrow>
            <Box width={'fit-content'} height={'fit-content'}>
                <Button
                    onClick={handleRefreshClicked}
                    size="small"
                    color="secondary"
                    styles={{
                        height: '30px',
                        lineHeight: 'normal',
                        width: 'fit-content',
                        minWidth: 'fit-content',
                        color: color,
                        borderColor: color,
                        ':hover': {
                            borderColor: color
                        },
                    }}
                    startIcon={<Refresh fontSize="small"/>}
                    showLabel={showLabel}
                    variant="outlined"
                    text={'värskenda'}
                />
            </Box>
        </Tooltip>
    );
};

export default DataRefresh;