import React, {useContext} from 'react';
import {Box, Paper} from '@mui/material';
import {Outlet} from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import { styled } from '@mui/material/styles';
import { SidebarContext } from '../../contexts/SidebarContext';
import { RegionProvider } from '../../contexts/RegionContext';


interface MainWrapperProps {
    open: boolean;
}

const MainWrapper = styled(Box)(() => ({
    height: '100vh',
    width: 'fit-parent',
    background: '#f0f2f0',
}));

const PageWrapper = styled(Box, {
    shouldForwardProp: prop => prop !== 'open',
})<MainWrapperProps>(({ theme, open }) => ({
    transition: theme.transitions.create('padding-left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    display: 'flex',
    '@media not print': { height: '100%' },
    width: 'fit-parent',
    ...(!open && {
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '57px',
        }
    }),
    ...(open && {
        paddingLeft: '225px',
    }),
    [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingTop: '50px',
        height: 'calc(100% - 50px)'
    },
    '@media print': {
        paddingLeft: 0,
    },
}));

const ContentWrapper = styled(Paper)(({ theme }) => ({
    flex: 1,
    height: 'fit-parent',
    width: 'fit-parent',
    overflowX: 'auto',
    padding: theme.spacing(3),
    margin: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1.5),
        margin: theme.spacing(1),
    },
    '@media print': {
        overflowX: 'visible',
        padding: 0,
        margin: 0,
        border: 'none',
        boxShadow: 'none',
    }
}));

export default function DashboardLayout() {
    const { isSidebarOpen } = useContext(SidebarContext);

    return (
        <RegionProvider>
            <MainWrapper>
                <Sidebar />
                <PageWrapper open={isSidebarOpen}>
                    <ContentWrapper>
                        <Outlet />
                    </ContentWrapper>
                </PageWrapper>
            </MainWrapper>
        </RegionProvider>
    );
}
