import {DefectPriority, DefectSimple} from "../../../../../../../API/defects/types";
import {Alert, Box, Collapse, Typography} from "@mui/material";
import React, {useMemo, useState} from "react";
import {
    getDefectsSortedByPriority,
    getHighestPriorityDefect
} from "../../../../../workSheets/listView/BusesWithDefects";
import {useAppSelector} from "../../../../../../../hooks";
import {selectAllCurrentDriverBuses} from "../../../../../../../store/currentDriverWorkSheetsSlice";
import {PriorityIcon, PriorityIconText} from "../../../../../defects/detailsView/components/DefectFields";
import {ChevronLeft} from "@mui/icons-material";
import {getDisplayValidDatesRange} from "../../../../../../../utils/dateUtils";
import {BusWithDefectsAndHighestPriority} from "../../../../../workSheets/types";
import {BusUsageWithDistance} from "../../../types";


export const Defect = ({defect}: {defect: DefectSimple}) => {
    return (
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', py: '2px'}}>
            <PriorityIconText key={defect.id} priority={defect.priority} priorityDisplayStr="" showCompactView />
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                <Typography variant="body2" color="text.primary">{defect.description}</Typography>
                <Typography variant="body2" color="text.secondary" fontSize="12px">
                    {getDisplayValidDatesRange({validFrom: defect.from, validTo: defect.to, useEllipsisFallback: true})}
                </Typography>
            </Box>
        </Box>
    )
}

const BusDefects = ({bus, defects, showHeader}: {bus: BusWithDefectsAndHighestPriority, defects: DefectSimple[], showHeader: boolean}) => {
    return defects.length > 0 && (
        <Box>
            {showHeader &&
                <Typography variant="body2" color="text.primary" fontWeight="bold" mt={1}>
                    {bus.licencePlateNumber}
                </Typography>
            }
            {getDefectsSortedByPriority(defects).map(defect => <Defect key={defect.id} defect={defect} />)}
        </Box>
    );
};

interface DefectAlertProps {
    defects: DefectSimple[];
    busUsages: BusUsageWithDistance[];
}

const DefectAlert = ({defects, busUsages}: DefectAlertProps) => {
    const buses = useAppSelector(selectAllCurrentDriverBuses);
    const [expandDefects, setExpandDefects] = useState(false);
    const busesWithDefects: BusWithDefectsAndHighestPriority[] = useMemo(() => {
        const result: BusWithDefectsAndHighestPriority[] = [];

        buses.forEach(bus => {
            const defectsOfTheBus = defects.filter(defect => defect.busId === bus.id);
            if (defectsOfTheBus.length > 0) {
                result.push({
                    ...bus,
                    defects: defectsOfTheBus,
                    highestPriorityDefect: getHighestPriorityDefect(defectsOfTheBus)
                });
            }
        });

        return result.sort((a,b) =>
            busUsages.findIndex(usage => usage.busId === a.id) < busUsages.findIndex(usage => usage.busId === b.id) ? -1 : 1
        );
    }, [defects]);

    return (busesWithDefects.length > 0 ?
        <Alert
            severity={getHighestPriorityDefect(defects).priority === DefectPriority.LOW ? 'info' : 'error'}
            variant="outlined"
            onClick={() => setExpandDefects(prevState => !prevState)}
            sx={{mt: 1, cursor: 'pointer', borderWidth: '2px', borderRadius: '10px', '.MuiAlert-message': {flex: 1}}}
        >
            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start'}}>
                <Box sx={{display: 'flex', flexWrap: 'wrap',  gap: 0.5}}>
                    <Typography variant="body2" color="text.primary" style={{textWrap: 'nowrap'}}>
                        {'Esineb rikkeid: '}
                    </Typography>
                    <Box sx={{display: 'flex', flexWrap: 'nowrap'}}>
                        {busesWithDefects.map((bus, index) =>
                            <Typography variant="body2" color="text.primary" key={bus.id} component="span" sx={{display: 'flex', pr: 0.5}}>
                                {bus.licencePlateNumber}
                                <PriorityIcon priority={bus.highestPriorityDefect.priority} sx={{ml: '2px'}} />
                                {index < busesWithDefects.length - 1 ? ', ' : ' '}
                            </Typography>
                        )}
                    </Box>
                </Box>
                <ChevronLeft sx={{transform: expandDefects ? 'rotate(90deg)' : 'rotate(-90deg)', mt: '-2px'}} />
            </Box>
            <Collapse in={expandDefects}>
                {busesWithDefects.map(bus =>
                    <BusDefects key={bus.id} bus={bus} defects={defects.filter(defect => defect.busId === bus.id)} showHeader={busesWithDefects.length > 1} />
                )}
            </Collapse>
        </Alert>
        :
        <></>
    );
};

export default DefectAlert;
