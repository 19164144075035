import {Box, Typography, useTheme} from '@mui/material';
import React, {useEffect} from 'react';
import {useAppSelector, useCustomRef} from '../../../../hooks';
import {selectPlannerTimelineMemoized} from '../../../../store/plannerSlice';
import {PLANNER_SIDEBAR_WIDTH} from "../../constants";
import {ContentScroll} from "../../types";


interface HeaderProps {
    contentScrollX: ContentScroll | undefined;
}

const TimelineHeader = ({ contentScrollX }: HeaderProps) => {
    const theme = useTheme();
    const plannerTimeline = useAppSelector(selectPlannerTimelineMemoized);

    const [timelineHeaderRef] = useCustomRef();

    useEffect(() => {
        updateScroll();
    }, [timelineHeaderRef.current, contentScrollX]);

    const updateScroll = () => {
        if (timelineHeaderRef.current && contentScrollX) {
            timelineHeaderRef.current.scrollLeft = contentScrollX.value;
        }
    };

    return (
        <Box
            ref={timelineHeaderRef}
            sx={{
                display: 'flex',
                borderBottom: `2px solid ${theme.palette.divider}`,
                maxWidth: '100%',
                overflowX: 'hidden',
                overflowY: 'scroll',
                flexShrink: 0
            }}
        >
            <Box sx={{
                position: 'absolute',
                maxWidth: `calc(${PLANNER_SIDEBAR_WIDTH}px - 20px)`,
                minWidth: `calc(${PLANNER_SIDEBAR_WIDTH}px - 20px)`,
                height: '20px',
                backgroundColor: theme.palette.common.white,
                boxShadow: '3px 0 3px #ffffff'
            }} />
            <Box sx={{
                pl: `${PLANNER_SIDEBAR_WIDTH + 2}px`,
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                pb: 0.5,
                boxSizing: 'border-box',
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    minWidth: `${plannerTimeline.minWidth}px`
                }}>
                    {plannerTimeline.displayedHours.map((hour, index) =>
                        <Box key={index} sx={{flexGrow: 1, width: `${100 / plannerTimeline.duration}%`}}>
                            <Typography sx={{fontSize: '12px', ml: '-16px'}}>{hour}</Typography>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default TimelineHeader;
