import {WorkGroupItemType} from "../../API/workGroup/types";
import {validateActivityLengthNullable} from "../../utils/formValidation";
import {Alert} from "@mui/material";
import React from "react";
import {Dayjs} from "dayjs";
import {WorkGroupItemTypeWithCharterTrip} from "../../API/workSheets/types";
import {MIN_DRIVER_REST_TIME_HOURS} from "../../constants";


const TimeBetweenShiftsLengthAlert = ({values}: {
    values: {
        type: WorkGroupItemTypeWithCharterTrip,
        startTime: Dayjs | null,
        endTime: Dayjs | null,
        startTimeIsOnNextDay: boolean,
        endTimeIsOnNextDay: boolean
    }
}) => {
    if (values.type !== WorkGroupItemType.TIME_BETWEEN_SHIFTS) return null;
    const isActivityLengthValid = validateActivityLengthNullable(
        values.startTime, values.endTime, values.startTimeIsOnNextDay, values.endTimeIsOnNextDay, MIN_DRIVER_REST_TIME_HOURS
    );
    if (isActivityLengthValid) return null;

    return (
        <Alert severity="warning" variant="outlined" sx={{mb: 2}}>
            Vahetustevahelise aja kestvus on alla 9 tunni
        </Alert>
    );
};

export default TimeBetweenShiftsLengthAlert;
