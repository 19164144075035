import React from 'react';
import {Box} from "@mui/material";
import TimePickerWithModifier from "./TimePickerWithModifier";


interface ModifierStartAndEndTimeProps {
    prefix?: string;
    disabled?: boolean;
}

const ModifierStartAndEndTime = ({ prefix, disabled }: ModifierStartAndEndTimeProps) => {
    return (
        <Box sx={{display: 'flex', flexDirection: {xs: 'column', sm: 'row'}}}>
            <Box sx={{width: {xs: '100%', sm: '50%'}, pr: {xs: 0, sm: 1}}}>
                <TimePickerWithModifier
                    timeName={`${prefix ? prefix + '.' : ''}startTime`}
                    modifierName={`${prefix ? prefix + '.' : ''}startTimeIsOnNextDay`}
                    label="Algusaeg*"
                    disabled={disabled}
                />
            </Box>
            <Box sx={{width: {xs: '100%', sm: '50%'}, pl: {xs: 0, sm: 1}}}>
                <TimePickerWithModifier
                    timeName={`${prefix ? prefix + '.' : ''}endTime`}
                    modifierName={`${prefix ? prefix + '.' : ''}endTimeIsOnNextDay`}
                    label="Lõpuaeg*"
                    disabled={disabled}
                />
            </Box>
        </Box>
    );
};

export default ModifierStartAndEndTime;
