import React from 'react';
import {useAppSelector} from "../../../../../hooks";
import {selectDisplayWorkItemById} from "../../../store/selectors";
import {EntityId} from "@reduxjs/toolkit";
import {shallowEqual} from "react-redux";
import {ResourceType, WorkGroupItemType} from "../../../../../API/workGroup/types";
import TripCard from "../TripCard";
import ActivityCard from "../ActivityCard";
import {selectIsPlannerRowDisabled} from "../../../../../store/workScheduleItemSlice";
import {selectSelectedRegion} from "../../../../../store/regionSlice";
import CharterTripCard from "../CharterTripCard";
import {selectToggledResourceType} from "../../../../../store/viewSlice";


interface WorkItemCardWrapperProps {
    workItemId: EntityId;
    currentGroupId: EntityId;
}

const WorkItemCardWrapper = ({workItemId, currentGroupId}: WorkItemCardWrapperProps) => {
    const selectedRegion = useAppSelector(selectSelectedRegion);
    const resourceType = useAppSelector(selectToggledResourceType);
    const workItem = useAppSelector(state => selectDisplayWorkItemById(state, workItemId), shallowEqual);
    const workSheetOfOppositeTypeId = workItem?.workSheetsOfOppositeType && workItem.workSheetsOfOppositeType.length > 0
        ? workItem.workSheetsOfOppositeType[0] : undefined;
    const isRowDisabled = useAppSelector(state => selectIsPlannerRowDisabled(state, currentGroupId));
    const isItemDraggingDisabled = workItem?.type === WorkGroupItemType.MAINTENANCE && !!workItem.defectId && resourceType === ResourceType.VEHICLE;

    return (
        <>
            {workItem && workItem.type === WorkGroupItemType.TRIP_DEFINITION && workItem.tripId !== undefined &&
				<TripCard
					trip={workItem}
					tripSegmentId={workItem.tripSegmentId ?? 0}
                    isFromOtherRegion={workItem.regionId !== selectedRegion?.id}
					isRowDisabled={isRowDisabled}
					currentGroupId={currentGroupId}
					groupOfOppositeTypeId={workSheetOfOppositeTypeId}
				/>
            }
            {workItem && workItem.type === WorkGroupItemType.TRIP_DEFINITION && workItem.charterTripId !== undefined &&
                <CharterTripCard
                    charterTrip={workItem}
                    isFromOtherRegion={workItem.regionId !== selectedRegion?.id}
                    isRowDisabled={isRowDisabled}
                    currentGroupId={currentGroupId}
                    groupOfOppositeTypeId={workSheetOfOppositeTypeId}
                />
            }
            {workItem && workItem.type !== WorkGroupItemType.TRIP_DEFINITION &&
                <ActivityCard
                    activity={workItem}
                    currentGroupId={currentGroupId}
                    isRowDisabled={isRowDisabled}
                    groupOfOppositeTypeId={workSheetOfOppositeTypeId}
					isItemDraggingDisabled={isItemDraggingDisabled}
                />
            }
        </>
    )
};

export default WorkItemCardWrapper;
