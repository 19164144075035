import React from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import {Box} from "@mui/material";
import NavigationButton from "../../../../components/Button/NavigationButton";
import routes from "../../../../routes";
import Planner from "../../../../features/Planner";
import {PlannerType} from "../../../../features/Planner/types";
import {useResourceTypeToggle} from "../../../../store/viewSlice";
import {Permission, ResourceGroupToggleButtonType, resourceGroupToggleButtonTypeValues} from "../../../../types";
import {setDialogData} from "../../../../store/plannerSlice";
import {useAppDispatch} from "../../../../hooks";


export default function WorkSheetsPlanningView() {
    const [resourceTypeToggleButtonValue, handleResourceTypeChange] = useResourceTypeToggle<ResourceGroupToggleButtonType>();
    const dispatch = useAppDispatch();

    const handleOpenAddWorkSheetDialog = () => {
        dispatch(setDialogData({addWorkSheet: true}));
    };

    return (
        <Box sx={{position: 'relative', display: 'flex', flexDirection: 'column', height: '100%', maxHeight: '100%'}}>
            <PageHeader
                title="Päevaplaan"
                toggleProps={{
                    options: [...resourceGroupToggleButtonTypeValues],
                    value: resourceTypeToggleButtonValue,
                    handleChange: handleResourceTypeChange,
                }}
                buttonProps={{
                    title: 'Lisa sõiduleht',
                    onClick: handleOpenAddWorkSheetDialog,
                    permission: Permission.UpdateWorkGroups
                }}
                secondaryButton={
                    <NavigationButton text="Nimekiri" route={routes.AUTHENTICATED.WORK_SHEETS.ROOT} />
                }
                showRegionSelect
            />
            <Planner type={PlannerType.WORK_SHEET} />
        </Box>
    );
}
