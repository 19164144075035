import dayjs from "dayjs";
import {AxiosError} from "axios";
import {ApiError} from "./types";

const dateKeys = [
    'date',
    'startDate',
    'endDate',
    'validFrom',
    'validTo',
    'contractStartDate',
    'contractEndDate',
    'confirmedAt',
];

export const handleDates = (data: any) => {
    if (data === null || data == undefined || typeof data !== 'object') {
        return data
    }
    for (const key of Object.keys(data)) {
        const value = data[key];
        if (dateKeys.includes(key) && value !== null) {
            data[key] = dayjs(value);
        } else if (typeof value === 'object') {
            handleDates(value);
        }
    }
};

export const handleError = (axiosError: AxiosError) => {
    if (axiosError.response?.data) {
        if (axiosError.status) {
            throw {...axiosError.response?.data, status: axiosError.status} as ApiError;
        } else {
            throw axiosError.response?.data as ApiError;
        }
    }

    throw axiosError;
};