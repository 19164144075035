import {WorkSheet, WorkSheetWorkItem} from "../../../API/workSheets/types";
import {getOtherRegionId, getStartAndEndPointLabel} from "../../../utils/utils";
import {Region} from "../../../API/region/types";

export const getTripLabel = (item: WorkSheetWorkItem): string => {
    const routeName = getStartAndEndPointLabel(item.route);
    const lineNumber = item.trip?.lineNumber && `(liin ${item.trip.lineNumber})`;

    return `${item.trip?.code}${lineNumber ? ' ' + lineNumber : ''}${routeName ? ' ' + routeName : ''}`;
};

export const getCharterTripLabel = (item: WorkSheetWorkItem): string => {
    const routeName = item.charterTrip?.route;

    return `S${item.charterTrip?.id}${routeName ? ' ' + routeName : ''}`;
};

export interface CodedRegionalItem {
    workGroupCode?: string;
    otherRegionId: number | null;
}

export interface TimedRegionalItem {
    startTime?: string;
    otherRegionId: number | null;
}

export const compareRegionalItemsByCode = (a: CodedRegionalItem, b: CodedRegionalItem) => {
    return (a.otherRegionId ?? 0) - (b.otherRegionId ?? 0) || (a.workGroupCode ?? '').localeCompare(b.workGroupCode ?? '');
};

export const compareByRegionalItemsByStartTime = (a: TimedRegionalItem, b: TimedRegionalItem) => {
    return (a.otherRegionId ?? 0) - (b.otherRegionId ?? 0) || (a.startTime ?? '').localeCompare(b.startTime ?? '');
};

export const compareWorkSheetsByRegionAndWorkGroupCode = (a: WorkSheet, b: WorkSheet, selectedRegion: Region | undefined) => {
    return compareRegionalItemsByCode(
        {
            workGroupCode: a.workGroupCode,
            otherRegionId: getOtherRegionId(a.regionId, selectedRegion),
        },
        {
            workGroupCode: b.workGroupCode,
            otherRegionId: getOtherRegionId(b.regionId, selectedRegion),
        }
    );
};

export const compareWorkSheetsByRegionAndStartTime = (a: WorkSheet, b: WorkSheet, selectedRegion: Region | undefined) => {
    return compareByRegionalItemsByStartTime(
        {
            startTime: a.startTime,
            otherRegionId: getOtherRegionId(a.regionId, selectedRegion),
        },
        {
            startTime: b.startTime,
            otherRegionId: getOtherRegionId(b.regionId, selectedRegion),
        }
    );
};
