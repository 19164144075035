import {DefectSimple} from "../../../../../../API/defects/types";
import {Alert} from "@mui/material";
import React from "react";
import {DefectsTooltipContent} from "../../../../workSheets/listView/BusesWithDefects";


interface DefectAlertProps {
    defects: DefectSimple[];
}

const DefectAlert = ({defects}: DefectAlertProps) => {

    return (
        <Alert severity="warning" variant="outlined" sx={{mt: 1}}>
            <DefectsTooltipContent defects={defects} textColor="black" />
        </Alert>
    );
};

export default DefectAlert;
