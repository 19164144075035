import React from 'react';
import IconButton from "@mui/material/IconButton";
import {useWorkSheetsSortType} from "../../../../hooks";
import {Box, Tooltip} from "@mui/material";
import {ArrowDownwardRounded} from "@mui/icons-material";
import {WorkSheetsSortType} from "../../../../store/workScheduleItemSlice";


const SortButton = ({parentSortType}: {parentSortType: WorkSheetsSortType}) => {
    const [sortType, setSortType] = useWorkSheetsSortType();
    const isSelected = parentSortType === sortType;

    const handleSortClick = () => {
        if (!isSelected) setSortType(parentSortType);
    }

    return (
        <Box displayPrint="none">
            <Tooltip placement="top" arrow title={`Sorteeri sõidulehed ${parentSortType === 'code' ? 'koodi' : 'algusaja'} alusel`}>
                <IconButton
                    size="small"
                    onClick={handleSortClick}
                    sx={{
                        ml: 0.5,
                        ':hover': isSelected ? {} : { '& .sort-arrow-icon': { color: 'text.secondary' }}
                    }}
                >
                    <ArrowDownwardRounded
                        fontSize="small"
                        className="sort-arrow-icon"
                        sx={{
                            color: isSelected ? 'primary.light' : 'action.disabled',
                            ':hover': isSelected ? {} : {color: 'text.secondary'}
                        }}
                    />
                </IconButton>
            </Tooltip>
        </Box>
    );
};

export default SortButton;
