import {Form, useFormikContext} from "formik";
import {Box, Typography, useTheme} from "@mui/material";
import TextField from "../../../../components/Form/TextField/TextField";
import DatePicker from "../../../../components/Form/DateTimePicker/DatePicker";
import Select from "../../../../components/Form/Select/Select";
import Checkbox from "../../../../components/Form/Checkbox/Checkbox";
import React, { useEffect } from "react";
import {Region, TransportContract} from "../../../../API/types";
import {TransportContractForm} from "./types";
import {getDisplayDate} from "../../../../utils/dateUtils";
import DetailViewButtons from "../../../../components/Button/DetailViewButtons";
import routes from "../../../../routes";
import NumberTextField from "../../../../components/Form/NumberTextField/NumberTextField";
import {useAuth} from "../../../../contexts/AuthContext";
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import {fetchTransportContractsByUser, selectProfitCenterOptions} from "../../../../store/transportContractsSlice";
import SelectFreeInput from "../../../../components/Form/Select/SelectFreeInput";


interface TransportContractFormProps {
    transportContract: TransportContract | undefined;
    id: string | undefined;
    defaultFormValues: TransportContractForm;
    regions: Region[];
}

export default function TransportContractEditForm({ transportContract, id, defaultFormValues, regions }: TransportContractFormProps) {
    const theme = useTheme();
    const formikProps = useFormikContext<TransportContractForm>();
    const { authenticatedUser } = useAuth();
    const dispatch = useAppDispatch();
    const transportContractOptions = useAppSelector(selectProfitCenterOptions);

    useEffect(() => {
        dispatch(fetchTransportContractsByUser(authenticatedUser));
    }, []);

    useEffect(() => {
        if (formikProps.isSubmitting) {
            formikProps.setSubmitting(false);
        }
    }, [formikProps.initialValues]);

    const renderDetailViewButtons = () => {
        return (
            <DetailViewButtons
                id={id}
                defaultFormValues={defaultFormValues}
                formikProps={formikProps}
                listViewPath={routes.AUTHENTICATED.TRANSPORT_CONTRACTS.ROOT}
            />
        );
    };

    return  <Form>
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2}}>
            <Box>
                <Typography variant="h5" pt={{xs: 1, sm: 0}}>
                    {transportContract ? transportContract.contractId : 'Uus veoleping'}
                </Typography>
                {transportContract?.validFrom &&
                    <Typography variant="body2" color="text.secondary">
                        {getDisplayDate(transportContract.validFrom)}
                        {transportContract?.validTo ?  ` - ${getDisplayDate(transportContract.validTo)}` : ''}
                    </Typography>
                }
            </Box>
            <Box sx={{[theme.breakpoints.down('sm')]: {display: 'none'}}}>
                {renderDetailViewButtons()}
            </Box>
        </Box>
        <Box sx={{
            display: 'flex', flexDirection: 'column', width: '400px', maxWidth: '100%',
            [theme.breakpoints.down('sm')]: {width: '100%'}
        }}>
            <TextField name="contractId" label="Lepingu number*" />
            <SelectFreeInput name="profitCenter" label="Tulemusüksus*" options={transportContractOptions} sx={{ mb: 0 }} />
            {formikProps.values.profitCenter !== '' && !transportContractOptions.includes(formikProps.values.profitCenter) &&
				<Typography variant="body2" fontSize="12px" color="warning.main" mt={0.5} mb={0.5} ml={1.5}>
                    Uue tulemusüksuse sisestamisel tuleb enne konsulteerida finantsosakonnaga, et tagada koodi ühtimine Directoga.
				</Typography>
            }
            <Checkbox name="appliesToCharterTrip" label="Saab määrata tellimusveole" />
            <DatePicker name="dateOfSigning" label="Sõlmimise kuupäev*" />
            <TextField name="partnerName" label="Partneri nimi*" />
            <NumberTextField name="monthlyPay" label="Kuupalk*" decimals={2} />
            <NumberTextField name="hourlyRate" label="Käsunduslepingu tunnitasu*" decimals={2} />
            <DatePicker name="validFrom" label="Kehtivuse alguskuupäev*" />
            <DatePicker name="validTo" label="Kehtivuse lõppkuupäev" />
            <Select name="regionName" label="Piirkond*" options={regions.map(region => region.name).sort()} />
            <TextField name="comment" label="Kommentaar" multiline minRows={2} maxRows={5} />
            <Checkbox name="active" label="Aktiivne" />
            <Box sx={{[theme.breakpoints.up('sm')]: {display: 'none'}}}>
                {renderDetailViewButtons()}
            </Box>
        </Box>
    </Form>;
}