import React from 'react';
import {TextField} from "@mui/material";
import theme from "../../../theme";

export interface InputSearchProps {
    value: string;
    updateValue: (searchInput: string) => void;
    height?: number;
}

const InputSearch = ({value, updateValue, height = 40}: InputSearchProps) => {
    return (
        <TextField
            value={value}
            label="Otsing"
            onChange={(event) => updateValue(event.target.value)}
            InputLabelProps={{shrink: true}}
            InputProps={{sx: {height: height, backgroundColor: theme.palette.background.paper}}}
            sx={{minWidth: {xs: '100%', sm: '40%', md: 200}, maxWidth: '100%'}}
        />
    );
};

export default InputSearch;
