import InputSearch, {InputSearchProps} from "../../../components/FilterToolbar/components/InputSearch";
import FilterChip, {FilterChipProps} from "../../../components/FilterToolbar/components/FilterChip";
import DataRefresh from "../../../components/FilterToolbar/components/DataRefresh";
import {Box} from "@mui/material";
import React, {useEffect} from "react";
import {useDayNavigation} from "../../../store/viewSlice";
import {Dayjs} from "dayjs";
import ToolbarContainer from "../../../components/FilterToolbar/components/ToolbarContainer";
import DateNavigator from "../../../components/DateNavigator/DateNavigator";


interface FilterToolbarProps {
    inputSearch: InputSearchProps,
    filterChips: FilterChipProps[],
    fetchData: (date: Dayjs) => void,
}

const FilterToolbar = ({inputSearch, filterChips, fetchData}: FilterToolbarProps) => {

    const [date, handleChangeDate] = useDayNavigation();
    const color = 'secondary.main';

    useEffect(() => {
        fetchData(date);
    }, [date]);

    return (
        <ToolbarContainer sx={{
            flexDirection: 'row',
            gap: 1,
            flexWrap: {xs: 'wrap-reverse', lg: 'nowrap'},
        }}>
            <Box sx={{
                order: 1,
            }}>
                <DateNavigator
                    date={date}
                    handleSelectedDateChange={handleChangeDate}
                    unit={'day'}
                />
            </Box>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                columnGap: 1,
                rowGap: 1.5,
                flexWrap: {xs: 'wrap-reverse', md: 'nowrap'},
                width: '100%',
                order: {xs: 3, lg: 2},
            }}>
                <Box>
                    <InputSearch {...inputSearch} height={30} />
                </Box>
                <Box sx={{
                    display: 'flex',
                    gap: 0.4,
                    flexWrap: {xs: 'wrap', sm: 'nowrap'},
                }}>
                    {filterChips.map((filterChip, index) => <FilterChip key={index} {...filterChip} />)}
                </Box>
            </Box>
            <Box sx={{
                order: {xs: 2, lg: 3},
            }}>
                <DataRefresh
                    handleRefresh={fetchData}
                    date={date}
                    color={color}
                />
            </Box>
        </ToolbarContainer>
    );
};

export default FilterToolbar;