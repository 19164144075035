import React from "react";
import {EntityId} from "@reduxjs/toolkit";
import {shallowEqual} from "react-redux";
import {useAppSelector} from "../../../../../hooks";
import {selectDisplayCharterTripWorkItemById} from "../../../store/selectors";
import {selectSelectedRegion} from "../../../../../store/regionSlice";
import CharterTripCard from "../CharterTripCard";


const CharterTripCardWrapper = ({id}: {id: EntityId, currentGroupId?: EntityId}) => {
    const selectedRegion = useAppSelector(selectSelectedRegion);
    const workItem = useAppSelector(state => selectDisplayCharterTripWorkItemById(state, id), shallowEqual);

    if (!workItem) {
        return null;
    }

    const workSheetOfOppositeTypeId = workItem?.workSheetsOfOppositeType && workItem.workSheetsOfOppositeType.length > 0
        ? workItem.workSheetsOfOppositeType[0] : undefined;

    return (workItem &&
        <CharterTripCard
            currentGroupId={0}
            charterTrip={workItem}
            isRowDisabled={false}
            isFromOtherRegion={selectedRegion?.id !== workItem.regionId}
            groupOfOppositeTypeId={workSheetOfOppositeTypeId}
        />
    );
};

export default CharterTripCardWrapper;