import * as Yup from 'yup';
import {WorkGroupItemType} from "../API/workGroup/types";
import dayjs, {Dayjs} from "dayjs";
import {WorkGroupItemTypeCharterTrip, WorkGroupItemTypeWithCharterTrip} from "../API/workSheets/types";

const MILLISECONDS_IN_ONE_HOUR = 3600000;
const MILLISECONDS_IN_ONE_DAY = MILLISECONDS_IN_ONE_HOUR * 24;

export const validationSchema = (name: string) => Yup.object({
	integerTextFieldRequired: Yup.string()
		.max(10, `${name} ei tohi ületada 10 kohta`)
		.required(`${name} on kohustuslik väli`),
	numberTextField: Yup.string()
		.max(10, `${name} ei tohi ületada 10 kohta`)
		.notRequired(),
	numberTextFieldRequired: Yup.string()
		.max(10, `${name} ei tohi ületada 10 kohta`)
		.required(`${name} on kohustuslik väli`),
	numberRequired: Yup.number()
		.required(`${name} on kohustuslik väli`),
	textFieldRequired: Yup.string()
		.required(`${name} on kohustuslik väli`),
	shortTextFieldRequired: Yup.string()
		.max(100, `${name} ei tohi ületada 100 tähemärki`)
		.required(`${name} on kohustuslik väli`),
	varcharTextField: Yup.string()
		.max(255, `${name} ei tohi ületada 255 tähemärki`)
		.notRequired(),
	varcharTextFieldRequired: Yup.string()
		.max(255, `${name} ei tohi ületada 255 tähemärki`)
		.required(`${name} on kohustuslik väli`),
	longTextField: Yup.string()
		.max(1000, `${name} ei tohi ületada 1000 tähemärki`)
		.notRequired(),
	longTextFieldRequired: Yup.string()
		.max(1000, `${name} ei tohi ületada 1000 tähemärki`)
		.required(`${name} on kohustuslik väli`),
	date: Yup.date()
		.typeError('Vigane kuupäev')
		.notRequired(),
	dateRequired: Yup.date()
		.typeError('Vigane kuupäev')
		.required(`${name} on kohustuslik väli`),
	timeRequired: Yup.date()
		.typeError('Vigane kellaaeg')
		.required(`${name} on kohustuslik väli`),
	phoneRequired: Yup.string()
		.max(15, `${name} ei tohi ületada 15 kohta`)
		.required(`${name} on kohustuslik väli`),
	email: Yup.string()
		.email('Vigane e-post'),
	emailRequired: Yup.string()
		.email('Vigane e-post')
		.required(`${name} on kohustuslik väli`),
	personalIdRequired: Yup.string()
		.max(11, `${name} ei tohi ületada 11 kohta`)
		.required(`${name} on kohustuslik väli`),
	arrayRequired: Yup.array()
		.min(1, `${name} on kohustuslik väli`).required(`${name} on kohustuslik väli`),
	objectRequired: Yup.object()
		.required(`${name} on kohustuslik väli`),
});

const toDateNotBeforeFromDateBuilder = (name: string, validFromFieldName: string, withTime: boolean = false) => {
	return (values: any[], schema: Yup.DateSchema<Date | null | undefined>) => {
		return schema.test({
			test: function () {
				const validTo: Date | null | undefined = values[0];
				const validFrom: Date | null | undefined = this.resolve(Yup.ref(validFromFieldName));
				if (validTo && validFrom) {
					if (!withTime) {
						validFrom.setHours(0, 0, 0, 0);
						validTo.setHours(0, 0, 0, 0);
					}
					if (dayjs(validFrom).isAfter(dayjs(validTo), 'm')) {
						return this.createError({
							message: `${name} ei tohi olla enne alguskuupäeva`
						})
					}
				}
				return true;
			}
		});
	}
};

export const dateValidTo = (name: string, validFromFieldName: string, withTime?: boolean) => Yup.date()
	.typeError('Vigane kuupäev')
	.nullable()
	.when(toDateNotBeforeFromDateBuilder(name, validFromFieldName, withTime));

export const dateValidToRequired = (name: string, validFromFieldName: string, withTime?: boolean) => Yup.date()
	.typeError('Vigane kuupäev')
	.required(`${name} on kohustuslik väli`)
	.when(toDateNotBeforeFromDateBuilder(name, validFromFieldName, withTime));

export const routePointNameRequired = (name: string) => Yup.array().of(
	Yup.object().shape({
		geoPoint: Yup.object().nullable().required(`${name} on kohustuslik väli`),
	})
);

export const fuelCardNameAndPinRequired = (nameFieldName: string, pinFieldName: string) => Yup.array().of(
	Yup.object().shape({
		name: Yup.string().required(`${nameFieldName} on kohustuslik väli`),
		pin: Yup.string().required(`${pinFieldName} on kohustuslik väli`),
	})
);

export const validateActivityLengthNullable = (
	startTime: Dayjs | null,
	endTime: Dayjs | null,
	startTimeIsOnNextDay: boolean,
	endTimeIsOnNextDay: boolean,
	minDurationHours?: number,
	maxDurationHours?: number
) => {
	if (!startTime || !endTime || !startTime.isValid() || !endTime.isValid()) return true;

	return validateActivityLength(startTime.toDate(), endTime.toDate(), startTimeIsOnNextDay, endTimeIsOnNextDay, minDurationHours, maxDurationHours);
};

const validateActivityLength = (
	startTime: Date,
	endTime: Date,
	startTimeIsOnNextDay: boolean,
	endTimeIsOnNextDay: boolean,
	minDurationHours?: number,
	maxDurationHours?: number
) => {
	const startTimeMillis = +startTime + (startTimeIsOnNextDay ? MILLISECONDS_IN_ONE_DAY : 0);
	const endTimeMillis = +endTime + (endTimeIsOnNextDay ? MILLISECONDS_IN_ONE_DAY : 0);
	const durationHours = (endTimeMillis - startTimeMillis) / MILLISECONDS_IN_ONE_HOUR;

	return (!minDurationHours || durationHours >= minDurationHours) && (!maxDurationHours || durationHours <= maxDurationHours);
};

export const workGroupActivityValidationSchema = Yup.object().shape({
	type: validationSchema('Tegevuse tüüp').fields.textFieldRequired,
	trip: Yup.object().nullable().when('type', (type, schema) => {
		const workGroupItemType = type[0] as WorkGroupItemTypeWithCharterTrip;
		if (workGroupItemType === WorkGroupItemType.TRIP_DEFINITION) {
			return schema.required('Reis on kohustuslik väli');
		}

		return schema;
	}),
	charterTrip: Yup.object().nullable().when('type', (type, schema) => {
		const workGroupItemType = type[0] as WorkGroupItemTypeWithCharterTrip;
		if (workGroupItemType === WorkGroupItemTypeCharterTrip.CHARTER_TRIP) {
			return schema.required('Tellimusvedu on kohustuslik väli');
		}

		return schema;
	}),
	route: routePointNameRequired('Teekonna punkti nimi'),
	startTime: validationSchema('Algusaeg').fields.timeRequired,
	endTime: Yup.date()
		.typeError('Vigane kellaaeg')
		.required('Lõpuaeg on kohustuslik väli')
		.when('type', (type, schema) => {
			const workGroupItemType = type[0] as WorkGroupItemType;

			if (
				workGroupItemType === WorkGroupItemType.LUNCH_BREAK
				|| workGroupItemType === WorkGroupItemType.DISRUPTION
				|| workGroupItemType === WorkGroupItemType.TIME_BETWEEN_SHIFTS
			) {
				return schema.test({
					test: function () {
						const startTime = this.parent.startTime;
						const endTime = this.parent.endTime;
						const startTimeIsOnNextDay = this.parent.startTimeIsOnNextDay;
						const endTimeIsOnNextDay = this.parent.endTimeIsOnNextDay;

						if (startTime && endTime) {
							if (workGroupItemType === WorkGroupItemType.DISRUPTION) {
								return validateActivityLength(startTime, endTime, startTimeIsOnNextDay, endTimeIsOnNextDay, 3)
									? true
									: this.createError({
										message: 'Katkestuse kestvus peab olema vähemalt 3 tundi',
									});
							}
							if (workGroupItemType === WorkGroupItemType.LUNCH_BREAK) {
								return validateActivityLength(startTime, endTime, startTimeIsOnNextDay, endTimeIsOnNextDay, 0.5, 1)
									? true
									: this.createError({
										message: 'Lõuna kestvus peab olema 30-60 minutit',
									});
							}
						}

						return true;
					},
				});
			}

			return schema;
		})
		.when('startTime', (_, schema) => schema.test({
			test: function () {
				const startTime = this.parent.startTime;
				const endTime = this.parent.endTime;
				if (startTime && endTime) {
					const startTimeIsOnNextDay = this.parent.startTimeIsOnNextDay;
					const endTimeIsOnNextDay = this.parent.endTimeIsOnNextDay;
					const startTimeDayjs = dayjs(startTime).add(startTimeIsOnNextDay ? 1 : 0, 'day');
					const endTimeDayjs = dayjs(endTime).add(endTimeIsOnNextDay ? 1 : 0, 'day');
					if (endTimeDayjs < startTimeDayjs) {
						return this.createError({
							message: 'Lõpuaeg ei tohi olla enne algust',
						})
					}
					return endTimeDayjs.diff(startTimeDayjs, 'minutes') >= 3
						? true
						: this.createError({
							message: 'Tegevuse pikkus ei tohi olla alla 3 minuti',
						});
				}

				return true;
			},
		})),
	distance: validationSchema('Pikkus').fields.numberTextField,
});

export const workItemChangesValidationSchema= (isAvlVersion: boolean) => Yup.object().shape({
	startTime: Yup.date()
		.typeError('Vigane kellaaeg')
		.notRequired()
		.when('isAvlVersion', (_, schema) => schema.test({
			test: function () {
				return !isAvlVersion && !this.parent.startTime
					? this.createError({message: 'Algusaeg on kohustuslik väli'})
					: true;
			},
		})),
	endTime: Yup.date()
		.typeError('Vigane kellaaeg')
		.notRequired()
		.when('isAvlVersion', (_, schema) => schema.test({
			test: function () {
				if (!isAvlVersion) {
					const startTime = this.parent.startTime;
					const endTime = this.parent.endTime;

					if (!endTime) return this.createError({message: 'Lõpuaeg on kohustuslik väli'});

					if (startTime && endTime) {
						const startTimeDayjs = dayjs(startTime).add(this.parent.startTimeIsOnNextDay ? 1 : 0, 'day');
						const endTimeDayjs = dayjs(endTime).add(this.parent.endTimeIsOnNextDay ? 1 : 0, 'day');
						if (endTimeDayjs < startTimeDayjs) return this.createError({message: 'Lõpuaeg ei tohi olla enne algust'});
					}
				}
				return true;
			},
		})),
	distance: validationSchema('Pikkus').fields.numberTextFieldRequired,
	splitRoutePoint: Yup.object()
		.notRequired()
		.when('isAvlVersion', (_, schema) => schema.test({
			test: function () {
				return isAvlVersion && !this.parent.splitRoutePoint
					? this.createError({message: 'Reisi osa alguspunkt on kohustuslik väli'})
					: true;
			},
		})),
});

export const timeValidToRequiredMax1Day = (name: string) => Yup.date()
	.typeError('Vigane kuupäev')
	.required(`${name} on kohustuslik väli`)
	.when('startTime', (_, schema) => schema.test({
		test: function () {
			const startTime = this.parent.startTime;
			const endTime = this.parent.endTime;
			const date = this.parent.date;

			if (date && startTime && endTime) {
				const startTimeIsOnNextDay = this.parent.startTimeIsOnNextDay;
				const endTimeIsOnNextDay = this.parent.endTimeIsOnNextDay;
				const dateStr = dayjs(date).format('YYYY-MM-DD');
				const startTimeStr = dayjs(startTime).format('HH:mm');
				const endTimeStr = dayjs(endTime).format('HH:mm');

				const startDateTimeDayjs = dayjs(`${dateStr} ${startTimeStr}`).add(startTimeIsOnNextDay ? 1 : 0, 'day');
				const endDateTimeDayjs = dayjs(`${dateStr} ${endTimeStr}`).add(endTimeIsOnNextDay ? 1 : 0, 'day');

				if (endDateTimeDayjs < startDateTimeDayjs) {
					return this.createError({
						message: 'Lõpuaeg ei tohi olla enne algust',
					})
				}

				if (Math.abs(dayjs(startDateTimeDayjs).diff(dayjs(endDateTimeDayjs), 'm')) > 24 * 60) {
					return this.createError({
						message: `Kestvus ei tohi olla pikem kui 1 päev`
					})
				}
			}

			return true;
		},
	}));
