import React, {memo} from 'react';
import Comments from "../../../../../components/Comments/Comments";
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {
    createDefectComment,
    deleteDefectComment,
    selectCommentsByDefectId,
    selectIsLoading,
    updateDefectComment
} from "../../../../../store/defectsSlice";
import {Comment, CommentFormData} from "../../../../../components/Comments/types"
import {FormikHelpers} from "formik";


interface DefectCommentsProps {
    defectId: number;
    showTableView?: boolean;
}

const DefectComments = ({defectId, showTableView}: DefectCommentsProps) => {
    const dispatch = useAppDispatch();
    const comments: Comment[] = useAppSelector(selectCommentsByDefectId(defectId));
    const isLoading = useAppSelector(selectIsLoading);

    const handleSave = (form: CommentFormData, formHelpers: FormikHelpers<CommentFormData>, id?: number) => {
        if (id) {
            dispatch(updateDefectComment({form, formHelpers, defectId, id}));
        } else {
            dispatch(createDefectComment({form, formHelpers, defectId}));
        }
    };

    const handleDelete = (id: number) => {
        if (confirm('Kas oled kindel, et soovid kommentaari kustutada?')) dispatch(deleteDefectComment({id, defectId}));
    };

    return <Comments comments={comments} handleSave={handleSave} handleDelete={handleDelete} isLoading={isLoading} showTableView={showTableView} />;
};

export default memo(DefectComments);
