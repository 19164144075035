import React, {ReactNode, useEffect, useState} from "react";
import {Box, Collapse, IconButton, Typography} from "@mui/material";
import {useAppSelector} from "../../../../../../hooks";
import {AddComment, Comment, StickyNote2Outlined} from "@mui/icons-material";
import {
    selectCurrentDriverSelectedWorkSheet,
    selectCurrentDriverWorkGroupById,
    selectCurrentDriverWorkSheetItems,
} from "../../../../../../store/currentDriverWorkSheetsSlice";
import ActivityCard from "./components/ActivityCard";
import BusChangeCard from "./components/BusChangeCard";
import {calculateHoursFromWorkSheetWorkItems, formatName} from "../../../../workSchedule/utils";
import {getBusChanges, getBusUsageDistance} from "../../utils";
import CommentForm from "./components/CommentForm";
import TimeLabel from "../../components/TimeLabel";
import {useNavigate, useParams} from "react-router-dom";
import routes from "../../../../../../routes";
import BusHandoverCard from "./components/BusHandoverCard";
import BackButton from "../../components/BackButton";
import OdometerReadingCard from "./components/OdometerReadingCard";
import {BusChange, BusUsageWithDistance} from "../../types";
import {getDisplayDayAndWrittenMonth} from "../../../../../../utils/dateUtils";
import DefectAlert from "./components/DefectAlert";
import {WorkSheetWorkItem} from "../../../../../../API/workSheets/types";


interface CardItem {
    endDateTime: string;
    card: ReactNode;
}

const composeCards = (
    workItems: WorkSheetWorkItem[],
    busChanges: BusChange[],
    busUsages: BusUsageWithDistance[],
): CardItem[] => {
    const cards: CardItem[] = [];
    workItems.forEach(item => {
        cards.push({
            endDateTime: item.endDateTime,
            card: <ActivityCard id={item.id} />,
        })
    });
    busChanges.forEach(busChange => {
        cards.push({
            endDateTime: busChange.previousBusUsage.endDateTime,
            card: <OdometerReadingCard busUsage={busChange.previousBusUsage} type="end" />
        });
        cards.push({
            endDateTime: busChange.nextBusUsage.startDateTime,
            card: <BusChangeCard busChange={busChange} />,
        });
        cards.push({
            endDateTime: busChange.nextBusUsage.startDateTime,
            card: <OdometerReadingCard busUsage={busChange.nextBusUsage} type="start" />
        });
    });
    if (busUsages.length) {
        const firstUsage = busUsages[0];
        if (firstUsage.previousDriverFirstName) {
            const name = formatName({firstName: firstUsage.previousDriverFirstName ?? '', lastName: firstUsage.previousDriverLastName ?? ''});
            const card = <BusHandoverCard busHandover={{ previousDriverName: name, busId: firstUsage.busId }} />;
            if (card) {
                cards.push({endDateTime: firstUsage.startDateTime, card: card});
            }
        }
        cards.push({
            endDateTime: firstUsage.startDateTime,
            card: <OdometerReadingCard busUsage={firstUsage} type="start" />
        });

        const lastUsage = busUsages[busUsages.length - 1];
        cards.push({
            endDateTime: lastUsage.endDateTime,
            card: <OdometerReadingCard busUsage={lastUsage} type="end" />
        });
        if (lastUsage.nextDriverFirstName) {
            const name = formatName({firstName: lastUsage.nextDriverFirstName ?? '', lastName: lastUsage.nextDriverLastName ?? ''});
            const card = <BusHandoverCard busHandover={{ nextDriverName: name, busId: lastUsage.busId }} />;
            if (card) {
                cards.push({endDateTime: lastUsage.endDateTime, card: card});
            }
        }
    }

    return cards.sort((a, b) => a.endDateTime.localeCompare(b.endDateTime));
};

const DetailView = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const workSheet = useAppSelector(state => selectCurrentDriverSelectedWorkSheet(state, id));
    const workGroup = useAppSelector(state => selectCurrentDriverWorkGroupById(state, workSheet?.workGroupId ?? 0));
    const workItems = useAppSelector(state => selectCurrentDriverWorkSheetItems(state, workSheet?.id ?? 0));
    const [cards, setCards] = useState<CardItem[]>([]);
    const [commentOpen, setCommentOpen] = useState(false);
    const {startDateTime, endDateTime, distance} = calculateHoursFromWorkSheetWorkItems(workItems);

    const busUsages: BusUsageWithDistance[] = workSheet?.busUsages.map(usage => {
        return {...usage, totalDistance: getBusUsageDistance(workItems, usage)};
    }) ?? [];
    const busChanges = getBusChanges(busUsages);

    useEffect(() => {
        setCards(composeCards(workItems, busChanges, busUsages));
    }, [workItems, workSheet]);

    const handleClose = () => {
        navigate(routes.AUTHENTICATED.AGENDA.ROOT)
    };

    if (!id || !workSheet) return <Typography variant="h6">Sõidulehte ei leitud</Typography>;

    return (
        <Box sx={{maxWidth: {'xs': '100%', 'sm': '400px'}}}>
            <Box display="flex" justifyContent="space-between">
                <BackButton onClick={handleClose} />
                <Box>
                    <IconButton color="primary" aria-label="Lisa kommentaar" size="small"
                                onClick={() => setCommentOpen(prevState => !prevState)}>
                        {workSheet.driverComment && workSheet.driverComment.length > 0
                            ? <Comment sx={{fontSize: '20px'}} />
                            : <AddComment sx={{fontSize: '20px'}} />
                        }
                    </IconButton>
                </Box>
            </Box>
            <Collapse in={commentOpen}>
                <CommentForm
                    workSheetId={workSheet.id ?? 0}
                    driverComment={workSheet.driverComment ?? ''}
                    handleClose={() => setCommentOpen(false)}
                />
            </Collapse>
            <Box sx={{my: 1, mx: {xs: 0, s: 1}}}>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', pb: 0.5}}>
                    <Typography fontWeight="bold">
                        {getDisplayDayAndWrittenMonth(workSheet.startDate)}
                    </Typography>
                    <Typography fontWeight="bold" sx={{display: 'flex', justifyContent: 'flex-end', pl: 2}}>
                        <TimeLabel startDateTime={startDateTime} endDateTime={endDateTime} />
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Typography variant="body2" sx={{display: 'flex', alignItems: 'center'}}>
                        {workGroup?.code &&
                            <><StickyNote2Outlined sx={{fontSize: '16px', pr: 0.5, color: 'text.secondary'}} />{workGroup.code}</>
                        }
                    </Typography>
                    <Typography variant="body2">
                        Läbisõit {distance.toFixed(0)} km
                    </Typography>
                </Box>
                <Box>
                    {workGroup?.comment && workGroup.comment.length > 0 &&
                        <Typography variant="body2" color="text.secondary" sx={{display: 'flex', alignItems: 'center'}}>
                            <Comment sx={{fontSize: '16px', pr: 0.5, color: 'text.secondary'}}/>{workGroup?.comment}
                        </Typography>
                    }
                </Box>
                <DefectAlert defects={workSheet.defectsOnDay} busUsages={busUsages} />
            </Box>
            {cards.map((item, index) => <Box key={index}>{item.card}</Box>)}
        </Box>
    );
};

export default DetailView;
