import React, {useCallback} from 'react';
import {WorkGroupItemType} from "../../../../API/types";
import {green} from '@mui/material/colors';
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import {
    selectPlannerType,
    updateTripItemWorkSheet,
    updateTripSegmentWorkGroup
} from '../../../../store/plannerSlice';
import {
    DisplayTripDefinitionWorkItem,
    DisplayWorkGroupTripDefinition,
    DisplayWorkItem,
    PlannerType
} from "../../types";
import {EntityId} from '@reduxjs/toolkit';
import StartAndEndPoint from "./components/StartAndEndPoint";
import {getEndPoint, getStartPoint} from "../../../../utils/utils";
import TripTooltipContent from "./components/Tooltip/Content/TripTooltipContent";
import ActionIcon from "../../../../components/Icon/ActionIcon";
import ItemCard from "./components/ItemCard";
import OppositeGroupMarker from "./components/OppositeGroupMarker";
import AddOppositeGroupButton from "./components/Tooltip/components/AddOppositeGroupButton";
import {openAddOppositeGroupDialog} from "../../store/actions";


interface TripCardProps {
    tripSegmentId: EntityId;
    trip: DisplayWorkGroupTripDefinition | DisplayWorkItem | DisplayTripDefinitionWorkItem;
    isFromOtherRegion: boolean;
    isRowDisabled?: boolean;
    currentGroupId?: EntityId;
    groupOfOppositeTypeId?: number;
    handleTripSplit?: () => void;
    handleTripMerge?: () => void;
}

const TripCard = ({
    tripSegmentId,
    trip,
    isFromOtherRegion,
    isRowDisabled = false,
    currentGroupId = 0,
    groupOfOppositeTypeId,
    handleTripSplit,
    handleTripMerge,
}: TripCardProps) => {
    const dispatch = useAppDispatch();
    const plannerType = useAppSelector(selectPlannerType);

    const onDrop = useCallback((itemId: number, targetGroupId: number) => {
        if (plannerType === PlannerType.WORK_GROUP) {
            dispatch(updateTripSegmentWorkGroup({
                movedTripSegmentId: tripSegmentId,
                originWorkGroupId: currentGroupId,
                targetWorkGroupId: targetGroupId,
            }));
        }
        if (plannerType === PlannerType.WORK_SHEET) {
            dispatch(updateTripItemWorkSheet({
                movedTripSegmentId: itemId,
                originWorkSheetId: currentGroupId,
                targetWorkSheetId: targetGroupId,
            }));
        }
    }, [currentGroupId, plannerType]);

    const handleAddOpposite = () => dispatch(openAddOppositeGroupDialog(currentGroupId, trip.id, WorkGroupItemType.TRIP_DEFINITION));

    const color = isFromOtherRegion ? `repeating-linear-gradient(
        -45deg,
        ${green[600]},
        ${green[600]} 10px,
        ${green[700]} 10px,
        ${green[700]} 15px
    )` : green[600];

    const tooltipActions = (
        <>
            <AddOppositeGroupButton
                currentGroupId={currentGroupId}
                oppositeGroupId={groupOfOppositeTypeId}
                onClick={handleAddOpposite}
            />
            {handleTripMerge && <ActionIcon type="MERGE" id={trip.tripId ?? 0} handleClick={handleTripMerge} color="common.white" />}
            {handleTripSplit && <ActionIcon type="SPLIT" id={trip.tripId ?? 0} handleClick={handleTripSplit} color="common.white" />}
        </>
    );

    return (trip ?
        <ItemCard
            isRowDisabled={isRowDisabled}
            tooltipContent={
                <TripTooltipContent
                    trip={{
                        id: trip.tripId ?? 0,
                        code: trip.code ?? '',
                        route: typeof trip.route === 'string' ? null : trip.route ?? null,
                        startTime: trip.startTime,
                        startTimeIsOnNextDay: trip.startTimeIsOnNextDay,
                        endTime: trip.endTime,
                        endTimeIsOnNextDay: trip.endTimeIsOnNextDay,
                        seasonalStartDate: 'seasonalStartDate' in trip ? trip.seasonalStartDate : null,
                        seasonalEndDate: 'seasonalEndDate' in trip ? trip.seasonalEndDate : null,
                        validFrom: 'validFrom' in trip ? trip.validFrom : null,
                        validTo: 'validTo' in trip ? trip.validTo : null,
                        distance: trip.distance ?? 0,
                        repetition: 'repetition' in trip && trip.repetition ? trip.repetition
                            : 'tripDefinitionRepetition' in trip && trip.tripDefinitionRepetition ? trip.tripDefinitionRepetition : undefined,
                        workSheetsOfOppositeType: 'workSheetsOfOppositeType' in trip ? trip.workSheetsOfOppositeType : undefined,
                        workGroupsOfOppositeType: 'workGroupsOfOppositeType' in trip ? trip.workGroupsOfOppositeType : undefined,
                    }}
                />
            }
            tooltipActions={tooltipActions}
            color={color}
            title={trip.code ?? 'TG'}
            subtitle={
                <StartAndEndPoint
                    start={getStartPoint(trip.route ?? null)?.name}
                    end={getEndPoint(trip.route ?? null)?.name}
                />
            }
            currentGroupId={currentGroupId}
            item={{
                id: trip.id,
                type: WorkGroupItemType.TRIP_DEFINITION,
                xPos: trip.xPos,
                width: trip.width,
                startTime: trip.startTime,
                startTimeIsOnNextDay: trip.startTimeIsOnNextDay,
                endTime: trip.endTime,
                endTimeIsOnNextDay: trip.endTimeIsOnNextDay,
            }}
            oppositeGroupMarker={trip.width > 10 &&
                <OppositeGroupMarker
                    itemId={trip.id}
                    type={WorkGroupItemType.TRIP_DEFINITION}
                    isRowDisabled={isRowDisabled}
                    currentGroupId={currentGroupId}
                    groupOfOppositeTypeId={groupOfOppositeTypeId}
                />
            }
            onDrop={onDrop}
        />
        :
        <></>
    );
};

export default TripCard;
