import React, {useEffect} from 'react';
import {Box} from '@mui/material';
import {useParams} from 'react-router-dom';
import Loader from "../../../../components/Loader/Loader";
import {useNavigate} from "react-router";
import routes from '../../../../routes';
import {fetchBuses} from "../../../../store/busSlice";
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import DefectComments from "./components/DefectComments";
import {fetchDefectById, selectDefectById} from "../../../../store/defectsSlice";
import DefectFormWrapper from "./components/DefectFormWrapper";


export default function DefectDetails() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { id } = useParams<{ id: string }>();
    const defect = useAppSelector(state => selectDefectById(state, id ?? 0));

    useEffect(() => {
        dispatch(fetchBuses());
        if (id) dispatch(fetchDefectById({id, handleDefectNotFound}));
    }, []);

    const handleDefectNotFound = () => navigate(routes.AUTHENTICATED.DEFECTS.ROOT);

    return (defect || !id ?
        <Box p={{xs: 1, sm: 0}}>
            <DefectFormWrapper defect={defect} />
            {defect && <DefectComments defectId={defect.id} />}
        </Box>
        :
        <Loader />
    );
}
