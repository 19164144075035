import React, {useEffect, useState} from "react";
import StaticIcon from "../../../../../components/Icon/StaticIcon";
import {Check} from "@mui/icons-material";
import {useTheme} from "@mui/material";


const InvoiceCheck = ({isInvoiced}: {isInvoiced: boolean}) => {
    const theme = useTheme();
    const [checkedData, setCheckedData] = useState<{isInvoiced: boolean, showBoldChecked: boolean}>(
        {isInvoiced: isInvoiced, showBoldChecked: false}
    );

    useEffect(() => {
        if (isInvoiced !== checkedData.isInvoiced) {
            setCheckedData({
                isInvoiced: isInvoiced,
                showBoldChecked: isInvoiced,
            });
            setTimeout(() => setCheckedData(prevState => ({...prevState, showBoldChecked: false})), 3000);
        }
    }, [isInvoiced]);

    return isInvoiced ? (
        <StaticIcon
            type="CHECK"
            tooltipTitle="Arve väljastatud"
            noPadding
            replaceIcon={
                checkedData.showBoldChecked ? (
                    <Check fontSize="small" sx={{
                        color: theme.palette.secondary.main,
                        stroke: theme.palette.secondary.main,
                        strokeWidth: '2px'
                    }} />
                ) : undefined
            }
        />
    ) : <></>;
};

export default InvoiceCheck;
