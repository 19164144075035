import React from "react";
import {Box, useMediaQuery} from "@mui/material";
import {EntityId} from "@reduxjs/toolkit";
import {useAppSelector} from "../../../../../../../hooks";
import {
    selectActiveBusIds,
    selectCurrentMonthDays,
    selectFilteredBusIds,
    selectIsRowActive
} from "../../../../store/selectors";
import CustomCell from "./CustomCell";
import ItemCell from "./ItemCell";
import PrevWeekItems from "./PrevWeekItems";
import {selectBusById} from "../../../../../../../store/busSlice";
import {disabledBackground, NAME_CELL_WIDTH} from "../index";
import {selectDisruptiveDefectsByBusId} from "../../../../../../../store/workScheduleItemSlice";
import {isOnDay} from "../../../../../../../utils/dateUtils";
import {Virtuoso} from "react-virtuoso";


const BusRow = ({id}: {
    id: EntityId,
}) => {
    const bus = useAppSelector(state => selectBusById(state, id));
    const currentMonthDays = useAppSelector(selectCurrentMonthDays);
    const isRowActive = useAppSelector(state => selectIsRowActive(state, id));
    const defects = useAppSelector(state => selectDisruptiveDefectsByBusId(state, id));

    if (!bus) {
        return null;
    }

    return (
        <Box role="row" sx={{display: 'flex', backgroundColor: isRowActive ? undefined : disabledBackground}}>
            <CustomCell
                content={bus.licencePlateNumber}
                width={NAME_CELL_WIDTH}
                sx={{textAlign: 'left', height: '38px', px: 0.5}}
            />
            <PrevWeekItems resourceId={id} />
            {currentMonthDays.map(day => {
                const defectsOnDay = defects.filter(defect => isOnDay(day, defect.from, defect.to));
                return <ItemCell key={day.toString()} rowId={parseInt(id.toString())} day={day} defects={defectsOnDay} />
            })}
        </Box>
    );
};

export default function BusRows({tableRef}: {tableRef: HTMLDivElement}) {
    const ids = useAppSelector(selectActiveBusIds);
    const filteredBusIds = useAppSelector(selectFilteredBusIds);
    const isPrintView = useMediaQuery('print');

    if (isPrintView) {
        return <>{ids.map((id) => <BusRow key={id} id={id} />)}</>;
    }

    const data = ids.filter(id => filteredBusIds.includes(id));

    return (
        <Virtuoso
            data={data}
            customScrollParent={tableRef}
            itemContent={(_, id) => <BusRow id={id} />}
        />
    )
}