import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import {CharterTrip} from '../../../../API/types';
import {CharterTripForm} from './types';
import {useParams} from 'react-router-dom';
import Loader from "../../../../components/Loader/Loader";
import {Formik, FormikHelpers, FormikProps} from 'formik';
import {useLocation, useNavigate} from "react-router";
import * as Yup from "yup";
import {timeValidToRequiredMax1Day, validationSchema} from "../../../../utils/formValidation";
import routes from '../../../../routes';
import CharterTripEditForm from "./components/CharterTripEditForm";
import {decimalToDisplayStr} from "../../../../utils/utils";
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import {getDateFromTimeStr, getStartAndEndTimeWithModifierFromDateTimeStr} from "../../../../utils/dateUtils";
import {
    fetchCharterTrips,
    saveCharterTrip,
    selectAllCharterOrders,
    selectCharterTripByIdWithOrder,
} from "../../../../store/charterTripsSlice";
import dayjs from "dayjs";
import {CharterTripZone} from "../../../../API/charterTrip/types";


interface StateData {
    copiedCharterTrip?: CharterTrip;
}

const charterTripValidationSchema = (options: {orderNumber: string}[]) => Yup.object().shape({
    orderNumber: Yup.string()
        .test({
            test: function () {
                const input = this.parent.orderNumber;
                const orderNumbers = options.map(option => option.orderNumber);

                return input && input.length > 0 && !orderNumbers.includes(input) && input.substring(0,1) === 'T'
                    ? this.createError({message: 'Uut tähega T algavat tellimuse numbrit ei saa lisada. Tähega T algavad ainult automaatselt genereeritud tellimuse numbrid.'})
                    : true;
            },
        }),
    route: validationSchema('Marsruut').fields.textFieldRequired,
    departurePoint: validationSchema('Lähtekoht').fields.shortTextFieldRequired,
    destination: validationSchema('Sihtkoht').fields.shortTextFieldRequired,
    client: validationSchema('Kliendi nimi').fields.shortTextFieldRequired,
    startTime: validationSchema('Algusaeg').fields.timeRequired,
    endTime: timeValidToRequiredMax1Day('Lõpuaeg'),
    date: validationSchema('Kuupäev').fields.dateRequired,
    distance: validationSchema('Pikkus').fields.numberTextFieldRequired,
    numberOfPassengers: validationSchema('Reisijate arv').fields.numberTextFieldRequired,
    price: validationSchema('Hind').fields.numberTextFieldRequired,
    transportContractId: validationSchema('Veoleping').fields.numberTextFieldRequired,
    comment: validationSchema('Lisainfo').fields.longTextField,
});

const defaultFormValues: CharterTripForm = {
    orderNumber: '',
    client: '',
    route: '',
    departurePoint: '',
    destination: '',
    startTime: null,
    startTimeIsOnNextDay: false,
    endTime: null,
    endTimeIsOnNextDay: false,
    date: null,
    distance: '',
    numberOfPassengers: '',
    transportContractId: null,
    isCancelled: false,
    isInvoiced: false,
    price: '',
    comment: '',
    zone: CharterTripZone.DOMESTIC,
};

export default function CharterTripDetails() {
    const { id } = useParams<{ id: string }>();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const stateData = location.state as StateData;
    const charterTrip = useAppSelector(state => selectCharterTripByIdWithOrder(state, id ?? 0));
    const charterOrders = useAppSelector(selectAllCharterOrders);
    const charterOrderOptions = useAppSelector(selectAllCharterOrders);
    const [initialValues, setInitialValues] = useState<CharterTripForm | undefined>(undefined);

    useEffect(() => {
        dispatch(fetchCharterTrips());
    }, []);

    useEffect(() => {
        if (!id) {
            if (stateData?.copiedCharterTrip) {
                setInitialValues(getFormValuesFromCharterTrip(stateData.copiedCharterTrip));
            } else {
                setInitialValues(defaultFormValues);
            }
        }
    }, [id]);

    useEffect(() => {
        if (charterTrip) {
            setInitialValues(getFormValuesFromCharterTrip(charterTrip));
        }
    }, [charterTrip]);

    const getFormValuesFromCharterTrip = (charterTrip: CharterTrip): CharterTripForm => {
        const {startTime, startTimeIsOnNextDay, endTime, endTimeIsOnNextDay} =
            getStartAndEndTimeWithModifierFromDateTimeStr(charterTrip.startDateTime, charterTrip.endDateTime, dayjs(charterTrip.date));

        return {
            ...charterTrip,
            startTime: getDateFromTimeStr(startTime),
            startTimeIsOnNextDay: startTimeIsOnNextDay,
            endTime: getDateFromTimeStr(endTime),
            endTimeIsOnNextDay: endTimeIsOnNextDay,
            date: dayjs(charterTrip.date),
            distance: decimalToDisplayStr(charterTrip.distance) ?? '',
            numberOfPassengers: decimalToDisplayStr(charterTrip.numberOfPassengers) ?? '',
            price: decimalToDisplayStr(charterTrip.price) ?? '',
            comment: charterTrip.comment ?? '',
            transportContractId: charterTrip.transportContract.id,
        };
    };

    const onSubmit = async (form: CharterTripForm, formHelpers: FormikHelpers<CharterTripForm>) => {
        dispatch(saveCharterTrip({id, form, formHelpers, handleCreateSuccess}));
    };

    const handleCreateSuccess = (newId: number) => navigate(routes.AUTHENTICATED.CHARTER_TRIPS.ROOT, {state: {highlightId: newId}});

    return (initialValues ?
        <Box p={{xs: 1, sm: 0}}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={charterTripValidationSchema(charterOrderOptions)}
                onSubmit={onSubmit}
            >
                {(formikProps: FormikProps<CharterTripForm>) => {
                    useEffect(() => {
                        if (formikProps.values.orderNumber !== '' && formikProps.values.client === '') {
                            const existingCharterOrder = charterOrders.find(charterOrder => charterOrder.orderNumber === formikProps.values.orderNumber);
                            if (existingCharterOrder) void formikProps.setFieldValue('client', existingCharterOrder.client);
                        }
                    }, [formikProps.values.orderNumber]);

                    return (
                        <CharterTripEditForm
                            id={id}
                            formikProps={formikProps}
                            defaultFormValues={defaultFormValues}
                            hasCopiedContent={!!stateData?.copiedCharterTrip}
                            existingCharterTrip={charterTrip}
                        />
                    );
                }}
            </Formik>
        </Box>
        :
        <Loader />
    );
}
