import {Form, Formik, FormikProps} from "formik";
import {Alert, Box} from "@mui/material";
import Button from "../../../../components/Button/Button";
import {Clear} from "@mui/icons-material";
import React from "react";
import * as Yup from "yup";
import {validationSchema} from "../../../../utils/formValidation";
import {FormikHelpers} from "formik/dist/types";
import TextField from "../../../../components/Form/TextField/TextField";


export interface ConfirmFormData {
    confirmationCode: string;
}

const confirmFormValidation = Yup.object().shape({
    confirmationCode: validationSchema('Kinnituskood').fields.textFieldRequired,
});

const ConfirmCodeForm = ({phoneNumber, handleSubmit, handleCancel}: {
    phoneNumber: string;
    handleSubmit: (values: ConfirmFormData, formikHelpers: FormikHelpers<ConfirmFormData>) => void;
    handleCancel: () => void;
}) => {
    return (
        <>
            <Alert severity="info" sx={{mb: 1}}>SMS kinnituskoodiga on saadetud numbrile {phoneNumber}.</Alert>
            <Formik
                initialValues={{confirmationCode: ''}}
                onSubmit={handleSubmit}
                validationSchema={confirmFormValidation}
                validateOnBlur={false}
                validateOnChange={false}
            >{(formikProps: FormikProps<ConfirmFormData>) => <Form>
                <TextField
                    margin="normal"
                    fullWidth
                    label="Kinnituskood"
                    name="confirmationCode"
                    size="small"
                    autoComplete="off"
                    autoFocus
                />
                <Box sx={{display: 'flex', gap: 1, justifyContent: 'center', mt: .5}}>
                    <Button color="secondary" startIcon={<Clear/>} text="Loobu" onClick={handleCancel} style={{width:'100%'}}/>
                    <Button type="submit" color="primary" text="Kinnita" style={{width:'100%'}} disabled={formikProps.isSubmitting} />
                </Box>
            </Form>
            }</Formik>
        </>
    );
};

export default ConfirmCodeForm;