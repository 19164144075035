import React from 'react';
import {CSSObject} from "@mui/material/styles";
import {PAGE_HEADER_ITEM_HEIGHT} from "../PageHeader";
import RegionSelect from "../../../features/InputFields/RegionSelect";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {selectAllRegions, selectSelectedRegion, setSelectedRegion} from "../../../store/regionSlice";
import {Region} from "../../../API/region/types";
import {useAuth} from "../../../contexts/AuthContext";


interface RegionSelectProps {
    styles?: CSSObject;
}

const PageHeaderRegionSelect = ({ styles }: RegionSelectProps) => {
    const dispatch = useAppDispatch();
    const selectedRegion = useAppSelector(selectSelectedRegion);
    const { authenticatedUser } = useAuth();
    const regions = useAppSelector(selectAllRegions);

    const updateSelectedRegion = (region: Region) => {
        dispatch(setSelectedRegion(region));
    };
    const regionChoices = authenticatedUser?.regionalFilteringApplies
        ? authenticatedUser.regions
        : regions;

    if (selectedRegion && regionChoices.length > 0 && !regionChoices.find(region => region.id === selectedRegion.id)) {
        dispatch(setSelectedRegion(regionChoices[0]));
    }

    return (regions && selectedRegion ?
        <RegionSelect
            regions={regionChoices}
            selectedRegion={selectedRegion}
            updateSelectedRegion={updateSelectedRegion}
            InputProps={{
                style: {height: PAGE_HEADER_ITEM_HEIGHT}
            }}
            sx={{
                my: 0.5,
                width: {xs: '100%', sm: '50%', md: '160px'},
                maxWidth: '100%',
                minWidth: '160px',
                ...styles
            }}
        />
        :
        <></>
    );
};

export default PageHeaderRegionSelect;
