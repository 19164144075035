import {DefectPriority, DefectSimple, PRIORITY_ORDER} from "../../../../API/defects/types";
import {useAppSelector} from "../../../../hooks";
import {selectBusesWithDefectsSortedByPriority} from "../../../../store/workScheduleItemSlice";
import {Box, SxProps, Tooltip, Typography} from "@mui/material";
import {PriorityIconText} from "../../defects/detailsView/components/DefectFields";
import React, {useState} from "react";
import NavLink from "../../../../components/NavLink/NavLink";
import routes from "../../../../routes";
import {Permission} from "../../../../types";
import {getDefectPriorityTranslation} from "../../../../utils/enumTranslations";
import {getDisplayValidDatesRange} from "../../../../utils/dateUtils";
import StaticIcon from "../../../../components/Icon/StaticIcon";


const DEFAULT_BUSES_COLLAPSE_LENGTH = 10;

export const busCardStyles: SxProps = {
    display: 'flex',
    flexDirection: 'row',
    wrap: 'no-wrap',
    alignItems: 'center',
    border: '1px solid',
    borderColor: 'divider',
    borderRadius: '5px',
    cursor: 'default',
    mr: 1,
    pl: 1,
    my: '2px'
};

export const isPriorityHigher = (a: DefectSimple, b: DefectSimple) => PRIORITY_ORDER[a.priority] < PRIORITY_ORDER[b.priority]  ? 1 : -1;

export const getDefectsSortedByPriority = (defects: DefectSimple[]) => [...defects].sort((a, b) => isPriorityHigher(a, b));

export const getHighestPriorityDefect = (defects: DefectSimple[]) => defects.reduce((max, defect) =>
    PRIORITY_ORDER[defect.priority] > PRIORITY_ORDER[max.priority] ? defect : max, defects[0]
);

export const DefectsTooltipContent = ({defects, textColor = 'white'}: {defects: DefectSimple[], textColor?: 'black' | 'white'}) => {
    return defects.length > 0 ?
        <Box>
            {getDefectsSortedByPriority(defects).map(defect =>
                <Box key={defect.id} sx={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', py: '2px'}}>
                    <PriorityIconText key={defect.id} priority={defect.priority} priorityDisplayStr="" showCompactView/>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        color: `common.${textColor}`
                    }}>
                        <NavLink
                            id={defect.id.toString()}
                            route={routes.AUTHENTICATED.DEFECTS.EDIT}
                            navPermission={Permission.ReadDefects}
                            openInNewWindow
                            value={getDefectPriorityTranslation(defect.priority) + ' (' + getDisplayValidDatesRange({validFrom: defect.from, validTo: defect.to ?? ''}) + ')'}
                            color="inherit"
                            textDecoration
                        />
                        <Box>{defect.description}</Box>
                    </Box>
                </Box>
            )}
        </Box>
        :
        undefined;
};

const BusesWithDefects = () => {
    const busesWithDefects = useAppSelector(selectBusesWithDefectsSortedByPriority);
    const [expandLowDefectBuses, setExpandLowDefectBuses] = useState(busesWithDefects.length > DEFAULT_BUSES_COLLAPSE_LENGTH);
    const numberOfLowPriorityDefectBuses = busesWithDefects.filter(bus => bus.highestPriorityDefect.priority === DefectPriority.LOW).length;
    const showExpandFunctionality = numberOfLowPriorityDefectBuses > 0 && busesWithDefects.length > DEFAULT_BUSES_COLLAPSE_LENGTH;

    return busesWithDefects.length > 0 ?
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', my: 0.5}}>
            {busesWithDefects.length > 0 && <Typography variant="body2" pr={1}>Riketega bussid:</Typography>}
            {busesWithDefects.map(bus =>
                !showExpandFunctionality || expandLowDefectBuses || bus.highestPriorityDefect.priority !== DefectPriority.LOW ?
                    <Tooltip key={bus.id} title={<DefectsTooltipContent defects={bus.defects} /> } arrow placement="top">
                        <Box key={bus.id} sx={busCardStyles}>
                            <Typography variant="body2" mr={0.5}>{bus.licencePlateNumber}</Typography>
                            <PriorityIconText priority={bus.highestPriorityDefect.priority} priorityDisplayStr="" showCompactView />
                        </Box>
                    </Tooltip>
                    :
                    <></>
            )}
            {showExpandFunctionality &&
				<Typography
                    variant="body2"
                    sx={{...busCardStyles, cursor: 'pointer', textWrap: 'nowrap', pr: expandLowDefectBuses ? 1 : 0, ':hover': {backgroundColor: 'action.hover'}}}
                    onClick={() => setExpandLowDefectBuses(prevState => !prevState)}
                >
                    <span style={{maxHeight: '20px', paddingRight: '5px'}}>
                        {expandLowDefectBuses ? <StaticIcon type="HIDE" noPadding /> : <StaticIcon type="VIEW" noPadding />}
                    </span>
					{expandLowDefectBuses ? 'vähem busse' : (
                        <span style={{textWrap: 'nowrap', display: 'flex', gap: '5px'}}>
                            {`veel ${numberOfLowPriorityDefectBuses} madala rikkega bussi `}
                            <PriorityIconText priority={DefectPriority.LOW} priorityDisplayStr="" showCompactView />
                        </span>
                    )}
				</Typography>
            }
        </Box>
        :
        <></>;
};

export default BusesWithDefects;
