import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import {UpdateTransportContractRequest} from '../../../../API/types';
import {TransportContractForm} from './types';
import {useParams} from 'react-router-dom';
import Loader from "../../../../components/Loader/Loader";
import { Formik, FormikHelpers } from 'formik';
import {useNavigate} from "react-router";
import * as Yup from "yup";
import {dateValidTo, validationSchema} from "../../../../utils/formValidation";
import routes from '../../../../routes';
import TransportContractEditForm from "./TransportContractEditForm";
import {decimalToDisplayStr, strToDecimal} from "../../../../utils/utils";
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import {selectAllRegions} from "../../../../store/regionSlice";
import {getDateString} from "../../../../utils/dateUtils";
import {
    fetchTransportContractsByUser,
    selectTransportContractById,
    setTransportContract
} from "../../../../store/transportContractsSlice";
import {createTransportContract, updateTransportContract} from "../../../../API/transportContracts/api";
import {useAuth} from "../../../../contexts/AuthContext";
import {setToast} from "../../../../store/toastSlice";


const transportContractValidationSchema = Yup.object().shape({
    contractId: validationSchema('Lepingu number').fields.shortTextFieldRequired,
    dateOfSigning: validationSchema('Kuupäev').fields.dateRequired,
    partnerName: validationSchema('Parteni nimi').fields.shortTextFieldRequired,
    profitCenter: validationSchema('Tulemusüksus').fields.varcharTextFieldRequired,
    monthlyPay: validationSchema('Kuupalk').fields.numberTextFieldRequired,
    hourlyRate: validationSchema('Tunnitasu').fields.numberTextFieldRequired,
    validFrom: validationSchema('Kuupäev').fields.dateRequired,
    validTo: dateValidTo('Lõppkuupäev', 'validFrom'),
    regionName: validationSchema('Piirkond').fields.textFieldRequired,
    comment: validationSchema('Kommentaar').fields.longTextField,
});

const defaultFormValues: TransportContractForm = {
    contractId: '',
    dateOfSigning: null,
    partnerName: '',
    profitCenter: '',
    monthlyPay: '',
    hourlyRate: '',
    validFrom: null,
    validTo: null,
    comment: '',
    regionName: '',
    active: true,
    appliesToCharterTrip: false,
}

export default function TransportContractDetails() {
    const navigate = useNavigate();
    const { authenticatedUser } = useAuth();
    const dispatch = useAppDispatch();
    const regions = useAppSelector(selectAllRegions);
    const { id } = useParams<{ id: string }>();
    const transportContract = useAppSelector(state => selectTransportContractById(state, id ?? 0));
    const [initialValues, setInitialValues] = useState<TransportContractForm | undefined>(undefined);

    useEffect(() => {
        dispatch(fetchTransportContractsByUser(authenticatedUser));
    }, []);

    useEffect(() => {
        if (id) {
            if (transportContract) {
                setInitialValues({
                    ...transportContract,
                    monthlyPay: decimalToDisplayStr(transportContract.monthlyPay),
                    hourlyRate: decimalToDisplayStr(transportContract.hourlyRate),
                    dateOfSigning: new Date(transportContract.dateOfSigning),
                    validFrom: new Date(transportContract.validFrom),
                    validTo: transportContract.validTo ? new Date(transportContract.validTo) : null,
                    regionName: transportContract.regionName ?? '',
                    comment: transportContract.comment ?? '',
                });
            } else {
                dispatch(setToast({type: 'error', text: 'Veolepingut ei leitud'}));
                navigate(routes.AUTHENTICATED.TRANSPORT_CONTRACTS.ROOT);
            }
        } else {
            setInitialValues(defaultFormValues);
        }
    }, [transportContract]);

    const onSubmit = (form: TransportContractForm, formHelpers: FormikHelpers<TransportContractForm>) => {
        if (form.dateOfSigning && form.validFrom) {
            const request: UpdateTransportContractRequest = {
                contractId: form.contractId,
                dateOfSigning: getDateString(form.dateOfSigning),
                partnerName: form.partnerName,
                profitCenter: form.profitCenter,
                monthlyPay: form.monthlyPay?.length ? strToDecimal(form.monthlyPay) : 0,
                hourlyRate: form.hourlyRate?.length ? strToDecimal(form.hourlyRate) : 0,
                validFrom: getDateString(form.validFrom),
                validTo: form.validTo ? getDateString(form.validTo) : null,
                regionName: form.regionName && form.regionName !== '' ? form.regionName : null,
                comment: form.comment ?? null,
                active: form.active,
                appliesToCharterTrip: form.appliesToCharterTrip,
            }

            if (id) {
                updateTransportContract(id, request)
                    .then(() => {
                        dispatch(setTransportContract({id: Number(id), ...request, regionName: request.regionName ?? ''}));
                        dispatch(setToast({type: 'success', text: 'Veoleping edukalt uuendatud'}));
                    })
                    .catch(() => {
                        dispatch(setToast({type: 'error', text: 'Veolepingu uuendamisel ilmnes viga'}));
                    })
                    .finally(() => formHelpers.setSubmitting(false));
            } else {
                createTransportContract(request)
                    .then(result => {
                        dispatch(setTransportContract(result));
                        dispatch(setToast({type: 'success', text: 'Veoleping edukalt loodud'}));
                        navigate(routes.AUTHENTICATED.TRANSPORT_CONTRACTS.ROOT);
                    })
                    .catch(() => {
                        dispatch(setToast({type: 'error', text: 'Veolepingu lisamisel ilmnes viga'}));
                    })
                    .finally(() => formHelpers.setSubmitting(false));
            }
        }
    };

    return (initialValues && regions ?
        <Box p={{xs: 1, sm: 0}}>
            <Formik enableReinitialize initialValues={initialValues} validationSchema={transportContractValidationSchema} onSubmit={onSubmit}>
                <TransportContractEditForm transportContract={transportContract} id={id} defaultFormValues={defaultFormValues} regions={regions} />
            </Formik>
        </Box>
        :
        <Loader />
    );
}