import React, {useEffect} from "react";
import {Box, Typography, useTheme} from "@mui/material";
import TextField from "../../../../../components/Form/TextField/TextField";
import {Bus, DefectPriority, DefectStatus} from "../../../../../API/types";
import {Form, FormikProps, useFormikContext} from "formik";
import {DefectFormData} from "../types";
import DetailViewButtons from "../../../../../components/Button/DetailViewButtons";
import routes from "../../../../../routes";
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {selectActiveBusSelectOptions} from "../../../../../store/busSlice";
import {
    getDefectPriorityTranslationFromStr,
    getDefectStatusTranslationFromStr
} from "../../../../../utils/enumTranslations";
import Select from "../../../../../components/Form/Select/Select";
import DateTimePicker from "../../../../../components/Form/DateTimePicker/DateTimePicker";
import {getDisplayDateTime} from "../../../../../utils/dateUtils";
import {useAuth} from "../../../../../contexts/AuthContext";
import {Defect, updateDefectStatus} from "../../../../../store/defectsSlice";
import {CreatorIconText, EditorIconText, OdometerReadingIconText} from "./DefectFields";
import Autocomplete from "../../../../../components/Form/Select/AutoComplete";
import {Permission} from "../../../../../types";
import {isEqual} from "lodash";
import Button from "../../../../../components/Button/Button";
import {KeyboardArrowRightRounded, KeyboardDoubleArrowRightRounded} from "@mui/icons-material";


const StatusButtons = ({initialDefect}: {initialDefect?: Defect}) => {
    const dispatch = useAppDispatch();

    return (initialDefect?.id && initialDefect.status !== DefectStatus.SOLVED ?
        <Box sx={{display: 'flex', height: 'fit-content', width: {xs: '100%', sm: '400px', md: 'fit-content'}, pt: {xs: 1, md: 0}}}>
            {initialDefect.status !== DefectStatus.IN_PROGRESS &&
                <Button
                    text="Töösse"
                    variant="outlined"
                    startIcon={<KeyboardArrowRightRounded />}
                    onClick={() => dispatch(updateDefectStatus({id: initialDefect.id, status: DefectStatus.IN_PROGRESS}))}
                    styles={{
                        mr: 1,
                        width: {xs: '50%', sm: 'fit-content'},
                        color: 'warning.main',
                        borderColor: 'warning.main',
                        ':hover': {
                            backgroundColor: 'action.hover',
                            borderColor: 'warning.main',
                        },
                    }}
                />
            }
            <Button
                text="Lahendatuks"
                variant="outlined"
                startIcon={initialDefect.status === DefectStatus.IN_PROGRESS ? <KeyboardArrowRightRounded /> : <KeyboardDoubleArrowRightRounded />}
                onClick={() => dispatch(updateDefectStatus({id: initialDefect.id, status: DefectStatus.SOLVED}))}
                styles={{
                    width: {xs: initialDefect.status !== DefectStatus.IN_PROGRESS ? '50%' : '100%', sm: 'fit-content'},
                    color: 'secondary.main',
                    borderColor: 'secondary.main',
                    ':hover': {
                        backgroundColor: 'action.hover',
                        borderColor: 'secondary.main',
                    },
                }}
            />
        </Box>
        :
        <></>
    );
};

export const showCommentsInput = (formikProps: FormikProps<any>, defaultFormValues: any, id?: string | number) =>
    !(isEqual(formikProps.initialValues, {...formikProps.values, comment: ''})
        || (id !== undefined && isEqual(formikProps.initialValues, defaultFormValues)));

export const defaultDefectFormValues = (defaultBus?: Bus): DefectFormData => ({
    bus: defaultBus ? {id: defaultBus.id, name: defaultBus.licencePlateNumber} : null,
    description: '',
    status: DefectStatus.NEW,
    priority: DefectPriority.LOW,
    expectedRepairDateTime: null,
    expectedRepairFinishedDateTime: null,
    comment: '',
});

interface DefectFormProps {
    defect: Defect | undefined;
    isDriverView: boolean;
    handleCancel?: () => void;
    defaultBus?: Bus;
}

export default function DefectForm({ defect, isDriverView, handleCancel, defaultBus }: DefectFormProps) {
    const theme = useTheme();
    const { authenticatedUser } = useAuth();
    const formikProps = useFormikContext<DefectFormData>();
    const buses = useAppSelector(state => selectActiveBusSelectOptions(state, authenticatedUser));
    const isViewer: boolean = !authenticatedUser?.permissions.includes(Permission.UpdateDefects);
    const canEdit: boolean = authenticatedUser?.id !== defect?.creatorUserId || defect?.status !== DefectStatus.NEW;

    const priorityTypes = Object.values(DefectPriority);
    const statusTypes = Object.values(DefectStatus);

    useEffect(() => {
        if (formikProps.isSubmitting) {
            formikProps.setSubmitting(false);
        }
    }, [formikProps.initialValues]);

    const renderDetailViewButtons = () => {
        return (
            <DetailViewButtons
                id={defect?.id}
                defaultFormValues={defaultDefectFormValues(defaultBus)}
                formikProps={formikProps}
                listViewPath={routes.AUTHENTICATED.DEFECTS.ROOT}
                handleCancelButtonPress={isDriverView ? handleCancel : undefined}
                savePermission={Permission.UpdateDefects}
            />
        );
    };

    return <Form>
        {!isDriverView &&
            <Box sx={{display: 'flex', flexDirection: {xs: 'column', sm: 'row'}, justifyContent: 'space-between', mb: 2}}>
                <Box>
                    <Typography variant="h5" pt={{xs: 1, sm: 0}}>
                        {defect ? defect.bus.licencePlateNumber + ' rike' : 'Uus rike'}
                    </Typography>
                    <CreatorIconText defect={defect} />
                    <EditorIconText defect={defect} />
                    {defect?.odometerReading && <OdometerReadingIconText odometerReading={defect.odometerReading} />}
                    <Box sx={{width: {xs: 0, md: 'fit-content'}, display: {xs: 'none', sm: 'flex', md: 'none'}}}>
                        <StatusButtons initialDefect={defect} />
                    </Box>
				</Box>
                <Box sx={{display: 'flex', flexDirection: 'row', height: 'fit-content'}}>
                    <Box sx={{width: {xs: '100%', sm: 'fit-content'}, display: {xs: 'flex', sm: 'none', md: 'flex'}}}>
                        <StatusButtons initialDefect={defect} />
                    </Box>
                    <Box sx={{[theme.breakpoints.down('sm')]: {display: 'none'}, ml: 5}}>
                        {renderDetailViewButtons()}
                    </Box>
                </Box>
            </Box>
        }
        <Box sx={{
            display: 'flex', flexDirection: 'column', width: '400px', maxWidth: '100%',
            [theme.breakpoints.down('sm')]: {width: '100%'}
        }}>
            {!(isDriverView && !defect) &&
                <Autocomplete
                    name="bus"
                    label="Buss*"
                    options={buses}
					getOptionLabel={option => option.name}
                    disabled={isViewer || defect?.id !== undefined && canEdit}
                />
            }
            <Select name="priority"
                    label="Olulisus"
                    options={priorityTypes}
                    translationFunction={getDefectPriorityTranslationFromStr}
                    translationEnumType={DefectPriority}
                    disabled={isViewer}
            />
            <TextField
                name="description"
                label="Kirjeldus*"
                multiline
                minRows={2}
                maxRows={5}
                disabled={isViewer || defect?.id !== undefined && canEdit}
            />
            {!isDriverView &&
                <Select name="status"
                        label="Staatus"
                        options={statusTypes}
                        translationFunction={getDefectStatusTranslationFromStr}
                        translationEnumType={DefectStatus}
                        disabled={isViewer}
                />
            }
            {defect?.markedInProgressAt && defect.markedInProgressByUserName &&
				<Typography variant="body2" color="text.secondary" pb={1} pl={1.5} mt={-0.5}>
                    {`Töösse märkis ${defect.markedInProgressByUserName} ${getDisplayDateTime(defect.markedInProgressAt)}`}
				</Typography>
            }
            {defect?.repairedAt && defect.markedAsRepairedByUserName &&
				<Typography variant="body2" color="text.secondary" pb={1} pl={1.5} mt={-1}>
                    {`Lahendatuks märkis ${defect.markedAsRepairedByUserName ?? ''} ${getDisplayDateTime(defect.repairedAt)}`}
				</Typography>
            }
            {!isDriverView &&
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    <DateTimePicker
                        name="expectedRepairDateTime"
                        label="Eeldatav remondiaeg"
                        disabled={isViewer || defect?.status === DefectStatus.SOLVED}
                    />
                    <DateTimePicker
                        name="expectedRepairFinishedDateTime"
                        label="Eeldatav remondiaja lõpp"
                        disabled={isViewer || defect?.status === DefectStatus.SOLVED}
                    />
                </Box>
            }
            {!isDriverView && defect?.id && !isViewer && showCommentsInput(formikProps, defaultDefectFormValues(defaultBus), defect?.id) &&
				<TextField
					name="comment"
					label="Kommentaar"
					multiline
					minRows={2}
					maxRows={5}
                    description="Vajadusel saab kohe rikke salvestamisel lisada ka kommentaari"
				/>
            }
        </Box>
        <Box sx={isDriverView ? {display: 'flex', justifyContent: 'center'} : {[theme.breakpoints.up('sm')]: {display: 'none'}}}>
            {renderDetailViewButtons()}
        </Box>
    </Form>
}
