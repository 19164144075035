import React, {useContext} from 'react';
import {CSSObject, styled, Theme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import {ChevronLeft, ChevronRight, Menu} from '@mui/icons-material';
import SidebarMenu from './components/SidebarMenu';
import {SidebarContext} from '../../contexts/SidebarContext';
import {Link, useMediaQuery} from '@mui/material';
import Profile from './components/Profile';
import routes from '../../routes';
import theme from "../../theme";
import logo from "../../assets/logo.png";
import {grey} from '@mui/material/colors';


const DRAWER_WIDTH = 225;
const TOGGLE_BUTTON_PADDING = 2.1;
const DRAWER_BACKGROUND_COLOR = grey[50];

const TopSection = styled(Box)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '90px',
    marginBottom: theme.spacing(2),
    padding: 0
}));

const openedMixin = (theme: Theme): CSSObject => ({
    width: DRAWER_WIDTH,
    background: DRAWER_BACKGROUND_COLOR,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    background: DRAWER_BACKGROUND_COLOR,
    overflowX: 'hidden',
    width: `calc(${theme.spacing(8)} + 1px)`,
});

const openedToggle = (theme: Theme): CSSObject => ({
    marginLeft: `calc(${DRAWER_WIDTH}px - ${theme.spacing(TOGGLE_BUTTON_PADDING)})`,
    transition: theme.transitions.create('margin-left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedToggle = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('margin-left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    marginLeft: `calc(${theme.spacing(8)} - ${theme.spacing(TOGGLE_BUTTON_PADDING)})`,
});

const DesktopDrawer = styled(MuiDrawer, {
    shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

const ToggleIconButton = styled(IconButton)<{ open: boolean }>(({ theme, open }) => ({
    position: 'fixed',
    backgroundColor: DRAWER_BACKGROUND_COLOR,
    zIndex: 1200,
    height: '30px',
    width: '30px',
    marginTop: theme.spacing(8),
    border: '1px solid rgba(0, 0, 0, 0.3)',
    overflow: 'hidden',
    '&:hover': {
        backgroundColor: '#F4F4F4',
    },
    ...(open && {...openedToggle(theme)}),
    ...(!open && {...closedToggle(theme)}),
}));

const MobileDrawer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    height: '50px',
    position: 'fixed',
    top: 0,
    backgroundColor: theme.palette.common.white,
    flexShrink: 0,
    zIndex: 999,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    borderBottom: '2px solid',
    borderColor: theme.palette.divider,
}));

const MobileDrawerMenu = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'fixed',
    top: '50px',
    bottom: 0,
    backgroundColor: theme.palette.common.white,
    zIndex: 1000,
    width: '100vw',
    boxSizing: 'border-box',
    borderColor: theme.palette.divider,
    padding: theme.spacing(0.5),
}));

export default function Sidebar() {
    const { isSidebarOpen, toggleSidebar } = useContext(SidebarContext);
    const isScreenSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const renderDrawerIcon = () => {
        return (
            <ToggleIconButton disableFocusRipple open={isSidebarOpen} onClick={toggleSidebar}>
                {isSidebarOpen ?
                    <ChevronLeft sx={{fontSize: '25px', paddingRight: '1px'}} />
                    :
                    <ChevronRight sx={{fontSize: '25px'}} />
                }
            </ToggleIconButton>
        );
    };

    return (isScreenSmall ?
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%'}}>
            <MobileDrawer>
                <Link href={routes.AUTHENTICATED.DASHBOARD}>
                    <img src={String(logo)} alt="small-logo" style={{maxHeight: '50px'}} />
                </Link>
                <Box sx={{display: 'flex', flexDirection: 'row', mr: 1}}>
                    <IconButton onClick={toggleSidebar}>
                        {isSidebarOpen ?
                            <ChevronLeft sx={{fontSize: '30px', transform: 'rotate(90deg)'}} />
                            :
                            <Menu sx={{fontSize: '30px'}} />
                        }
                    </IconButton>
                    <Profile />
                </Box>
            </MobileDrawer>
            <MobileDrawerMenu sx={{display: {xs: isSidebarOpen ? 'block' : 'none', sm: 'block', overflowY: 'scroll'}}}>
                <SidebarMenu />
            </MobileDrawerMenu>
        </Box>
        :
        <Box sx={{displayPrint: 'none'}}>
            <DesktopDrawer variant="permanent" open={isSidebarOpen}>
                <TopSection>
                    <Link href={routes.AUTHENTICATED.DASHBOARD}>
                        <img src={String(logo)} alt="small-logo" style={{width: '100%', maxWidth: '100px'}} />
                    </Link>
                </TopSection>
                <Box sx={{overflowY: 'auto', overflowX: 'hidden', maxHeight: 'calc(100% - 200px)', maxWidth: isSidebarOpen ? DRAWER_WIDTH : `calc(${theme.spacing(8)} + 1px)`,}}>
                    <SidebarMenu />
                </Box>
                <Box sx={{
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    height: '90px',
                    maxHeight: '90px',
                    width: isSidebarOpen ? DRAWER_WIDTH : `calc(${theme.spacing(8)} + 1px)`,
                    transition: 'width 0.3s ease',
                    borderTop: '1px solid',
                    borderColor: theme.palette.divider,
                    backgroundColor: DRAWER_BACKGROUND_COLOR
                }}>
                    <Profile />
                </Box>
            </DesktopDrawer>
            <Box sx={{width: 'fit-content', position: 'absolute'}}>
                {renderDrawerIcon()}
            </Box>
        </Box>
    );
}
