import React from "react";
import {RepetitionRules} from "../../../../../../../API/types";
import BaseRepetition from "../../../../../../RepetitionRules/RepetitionRules";
import TooltipRow from "./TooltipRow";

const Repetition = ({repetition}: {repetition?: RepetitionRules}) => {
    if (!repetition) {
        return null;
    }

    return (
        <TooltipRow>
            <BaseRepetition repetition={repetition} />
        </TooltipRow>
    );
};

export default Repetition;