import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import {Box} from "@mui/material";
import React from "react";


interface ButtonPairContainerProps {
    firstButton: ReactJSXElement;
    secondButton: ReactJSXElement;
}

const ButtonPairContainer = ({firstButton, secondButton}: ButtonPairContainerProps) => {
    return (
        <Box sx={{display: 'flex', flexDirection: 'row', gap: 1}}>
            <Box sx={{width: {xs: '50%', sm: 'fit-content'}}}>
                {firstButton}
            </Box>
            <Box sx={{width: {xs: '50%', sm: 'fit-content'}}}>
                {secondButton}
            </Box>
        </Box>
    )
}

export default ButtonPairContainer;
