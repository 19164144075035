import React from 'react';
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {selectDisplayWorkGroupTripDefinitionById} from "../../../store/selectors";
import {shallowEqual} from "react-redux";
import { EntityId } from '@reduxjs/toolkit';
import TripCard from "../TripCard";
import {selectRegion, selectSelectedRegion} from "../../../../../store/regionSlice";
import {selectIsPlannerRowDisabled} from "../../../../../store/workScheduleItemSlice";
import {mergeTrip, selectOrderedSegmentsByTripId, setDialogData} from "../../../../../store/plannerSlice";
import {setToast} from "../../../../../store/toastSlice";


interface TripCardWrapperProps {
    tripSegmentId: EntityId;
    currentGroupId?: EntityId;
}

const TripCardWrapper = ({tripSegmentId, currentGroupId = 0}: TripCardWrapperProps) => {
    const dispatch = useAppDispatch();
    const selectedRegion = useAppSelector(selectSelectedRegion);
    const trip = useAppSelector(state => selectDisplayWorkGroupTripDefinitionById(state, tripSegmentId), shallowEqual);
    const tripSegments = useAppSelector(state => selectOrderedSegmentsByTripId(state, trip?.tripId ?? 0), shallowEqual);
    const tripRegion = useAppSelector(state => selectRegion(state, trip?.regionId ?? 0));
    const isRowDisabled = useAppSelector(state => selectIsPlannerRowDisabled(state, currentGroupId));
    if (!trip) return null;

    const workGroupOfOppositeTypeId = trip.workGroupsOfOppositeType && trip.workGroupsOfOppositeType.length > 0
        ? trip.workGroupsOfOppositeType[0].id : undefined;

    const handleTripSplit = () => {
        if (tripRegion && selectedRegion && tripRegion.id !== selectedRegion?.id) {
            dispatch(setToast({type: 'warning', text: `Reisi saab osadeks jaotada piirkonnas ${tripRegion.name}`}))
        } else {
            dispatch(setDialogData({splitTrip: {tripId: trip.tripId}}));
        }
    };

    const handleTripMerge = () => {
        dispatch(mergeTrip({tripId: trip.tripId, originalSegments: tripSegments, confirmed: false}));
    };

    return (
        <TripCard
            trip={trip}
            tripSegmentId={tripSegmentId}
            isFromOtherRegion={trip.regionId !== selectedRegion?.id}
            isRowDisabled={isRowDisabled}
            currentGroupId={currentGroupId}
            groupOfOppositeTypeId={workGroupOfOppositeTypeId}
            handleTripSplit={handleTripSplit}
            handleTripMerge={tripSegments.length > 1 ? handleTripMerge : undefined}
        />
    );
};

export default TripCardWrapper;
