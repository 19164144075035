import React from "react";
import Dialog from "@mui/material/Dialog";
import {Box, Typography} from "@mui/material";
import {Contract, DriverContract} from "../../../../../API/types";
import DialogTitle from "@mui/material/DialogTitle";
import {Form, Formik, FormikHelpers, FormikProps} from "formik";
import DialogContent from "@mui/material/DialogContent";
import TextField from "../../../../../components/Form/TextField/TextField";
import NumberTextField from "../../../../../components/Form/NumberTextField/NumberTextField";
import DatePicker from "../../../../../components/Form/DateTimePicker/DatePicker";
import Select from "../../../../../components/Form/Select/Select";
import DialogActions from "@mui/material/DialogActions";
import Button from "../../../../../components/Button/Button";
import SubmitErrorListener from "../../../../../components/Form/SubmitErrorListener/SubmitErrorListener";
import {DriverContractForm} from "../types";
import * as Yup from 'yup';
import {getContractTranslationFromStr} from "../../../../../utils/enumTranslations";
import {dateValidTo, validationSchema} from "../../../../../utils/formValidation";
import {Check, Clear} from "@mui/icons-material";
import {decimalToDisplayStr} from "../../../../../utils/utils";

const contractValidationSchema = Yup.object().shape({
    type: validationSchema('Lepingu tüüp').fields.textFieldRequired,
    number: validationSchema('Lepingu number').fields.shortTextFieldRequired,
    startDate: validationSchema('Kehtivuse algus').fields.dateRequired,
    endDate: dateValidTo('Kehtivuse lõpp', 'startDate'),
    nominalWeeklyWorkingHours: validationSchema('Töötunde nädalas').fields.integerTextFieldRequired,
    hourlyRate: Yup.string().nullable().when('type', (type, schema) => {
        const contractType = type[0] as Contract;
        if (contractType === Contract.SERVICE_CONTRACT) {
            return schema.required('Tunnimäär on kohustuslik');
        }

        return schema;
    }),
    monthlyPay: Yup.string().nullable().when('type', (type, schema) => {
        const contractType = type[0] as Contract;
        if (contractType === Contract.EMPLOYMENT_CONTRACT || contractType === Contract.LABOR_LEASING_AGREEMENT) {
            return schema.required('Kuutasu on kohustuslik');
        }

        return schema;
    }),
});

const defaultContractFormValues: DriverContractForm = {
    number: '',
    type: '',
    startDate: null,
    endDate: null,
    nominalWeeklyWorkingHours: '',
    hourlyRate: '',
    monthlyPay: '',
}

interface DriverContractDialogProps {
    open: boolean,
    contract: DriverContract | null,
    handleCloseDialog: () => void,
    handleFormSubmit: (form: DriverContractForm, formHelpers: FormikHelpers<DriverContractForm>, id?: number) => void;
}

export default function DriverContractDialog({open, contract, handleCloseDialog, handleFormSubmit}: DriverContractDialogProps) {
    const initialContractValues: DriverContractForm = contract ? {
        number: contract.number,
        type: contract.type,
        startDate: contract?.startDate ? new Date(contract.startDate) : null,
        endDate: contract?.endDate ? new Date(contract.endDate) : null,
        nominalWeeklyWorkingHours: contract.nominalWeeklyWorkingHours.toString(),
        hourlyRate: contract.hourlyRate ? decimalToDisplayStr(contract.hourlyRate) : '',
        monthlyPay: contract.monthlyPay ? decimalToDisplayStr(contract.monthlyPay) : '',
    } : defaultContractFormValues;

    const handleFormSubmitClick = (form: DriverContractForm, formHelpers: FormikHelpers<DriverContractForm>) => {
        formHelpers.setSubmitting(true);
        handleFormSubmit(form, formHelpers, contract?.id ?? undefined);
    };

    return (
        <Dialog open={open} onClose={handleCloseDialog}>
            <Box sx={{width: '400px', maxWidth: '100%', p: {xs: 0, sm: 2}}}>
                <DialogTitle sx={{width: '300px', maxWidth: '100%'}}>
                    {contract ? getContractTranslationFromStr(contract.type) : 'Uus leping'}
                    {contract && <Typography variant="body2" color="textSecondary">{contract.number}</Typography>}
                </DialogTitle>
                <Formik initialValues={initialContractValues} validationSchema={contractValidationSchema} onSubmit={handleFormSubmitClick}>
                    {(formikProps: FormikProps<DriverContractForm>) =>
                        <Form>
                            <DialogContent sx={{display: 'flex', flexDirection: 'column', pt: 0}}>
                                <TextField name="number" label="Lepingu number*" />
                                <Select name="type"
                                        label="Lepingu tüüp*"
                                        options={Object.values(Contract)}
                                        translationFunction={getContractTranslationFromStr}
                                        translationEnumType={Contract}
                                />
                                <DatePicker name="startDate" label="Kehtivuse algus*" />
                                <DatePicker name="endDate" label="Kehtivuse lõpp" />
                                <NumberTextField name="nominalWeeklyWorkingHours" label="Töötunde nädalas*" />
                                {formikProps.values.type === Contract.SERVICE_CONTRACT && <NumberTextField name="hourlyRate" label="Tunnitasu*" decimals={2} />}
                                {
                                    (formikProps.values.type === Contract.EMPLOYMENT_CONTRACT || formikProps.values.type === Contract.LABOR_LEASING_AGREEMENT)
                                        && <NumberTextField name="monthlyPay" label="Kuutasu*" decimals={2} helperText="Kuutasu täistööajale taandatult" />
                                }
                            </DialogContent>
                            <DialogActions sx={{justifyContent: 'center', maxWidth: '100%', mb: 2}}>
                                <Box maxWidth="50%">
                                    <Button
                                        disabled={formikProps.isSubmitting}
                                        variant="outlined"
                                        text="Tagasi"
                                        onClick={handleCloseDialog}
                                        startIcon={<Clear />}
                                    />
                                </Box>
                                <Box maxWidth="50%">
                                    <Button
                                        disabled={formikProps.isSubmitting}
                                        text="Salvesta"
                                        type="submit"
                                        startIcon={<Check />}
                                    />
                                </Box>
                            </DialogActions>
                            <SubmitErrorListener
                                isValid={formikProps.isValid}
                                isValidating={formikProps.isValidating}
                                isSubmitting={formikProps.isSubmitting}
                            />
                        </Form>
                    }
                </Formik>
            </Box>
        </Dialog>
    );
}
