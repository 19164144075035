import React from "react";
import Dialog from "@mui/material/Dialog";
import {Box, DialogActions, Typography} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import {Form, Formik} from "formik";
import DialogContent from "@mui/material/DialogContent";
import Autocomplete from "../../../../../../components/Form/Select/AutoComplete";
import Button from "../../../../../../components/Button/Button";
import {Clear} from "@mui/icons-material";
import {useAppSelector} from "../../../../../../hooks";
import {selectDriversInSelectedRegion} from "../../../../../../store/driverSlice";
import DatePicker from "../../../../../../components/Form/DateTimePicker/DatePicker";
import {Driver} from "../../../../../../API/driver/types";
import dayjs, {Dayjs} from "dayjs";
import * as Yup from "yup";
import {validationSchema} from "../../../../../../utils/formValidation";
import {formatName} from "../../../../workSchedule/utils";

const finalPayslipValidationSchema = Yup.object().shape({
    driver: validationSchema('Juht').fields.objectRequired,
    date: validationSchema('Kuupäev').fields.dateRequired,
});

interface FinalPayslipFormData {
    driver?: Driver;
    date: Date;
}

interface FinalPayslipDialogProps {
    handleClose: () => void;
    handleSave: (driverId: number, date: Dayjs) => void;
}

const FinalPayslipDialog = ({handleClose, handleSave}: FinalPayslipDialogProps) => {
    const drivers = useAppSelector(selectDriversInSelectedRegion);

    const today = new Date();
    const initialValues: FinalPayslipFormData = {driver: undefined, date: today};

    const handleSubmit = (data: FinalPayslipFormData) => {
        if (data.driver) {
            handleSave(data.driver.id, dayjs(data.date));
        }
    };

    return (
        <Dialog open={true} onClose={handleClose}>
            <Box sx={{width: '400px', maxWidth: '100%', p: {xs: 0, sm: 2}}}>
                <DialogTitle sx={{maxWidth: '100%', display: 'flex', justifyContent: 'space-between'}}>
                    <Typography variant="h5" component="div">Juhi lõpparve</Typography>
                </DialogTitle>
                <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={finalPayslipValidationSchema}>
                    <Form>
                        <DialogContent sx={{display: 'flex', flexDirection: 'column', pt: 0}}>
                            <Autocomplete
                                options={drivers}
                                name="driver"
                                label="Juht"
                                getOptionLabel={(driver) => formatName(driver, 'lastNameFirst')}
                            />
                            <DatePicker name="date" label="Kuupäev" />
                        </DialogContent>
                        <DialogActions sx={{justifyContent: 'center', maxWidth: '100%'}}>
                            <Box maxWidth="50%">
                                <Button
                                    text="Loobu"
                                    variant="outlined"
                                    onClick={handleClose}
                                    startIcon={<Clear />}
                                />
                            </Box>
                            <Box maxWidth="50%">
                                <Button text="Koosta lõpparve" type="submit" />
                            </Box>
                        </DialogActions>
                    </Form>
                </Formik>
            </Box>
        </Dialog>
    );
};

export default FinalPayslipDialog;
