import Dialog from "@mui/material/Dialog";
import {Box, Tooltip, Typography} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import {Form, Formik, FormikHelpers, FormikProps} from "formik";
import DialogContent from "@mui/material/DialogContent";
import Select from "../../../../../components/Form/Select/Select";
import DialogActions from "@mui/material/DialogActions";
import Button from "../../../../../components/Button/Button";
import {Check, Clear} from "@mui/icons-material";
import React from "react";
import {getWorkGroupItemTypeTranslationFromStr} from "../../../../../utils/enumTranslations";
import {ResourceType, WorkGroupItemType} from "../../../../../API/types";
import ModifierStartAndEndTime from "../../../../../components/Form/DateTimePicker/ModifierStartAndEndTime";
import {ActivityForm} from "./types";
import NumberTextField from "../../../../../components/Form/NumberTextField/NumberTextField";
import {workGroupActivityValidationSchema} from "../../../../../utils/formValidation";
import {getTimeInHoursFromDate, isDurationOverDriverWorkDayLimitWithNewItem} from "../../../utils/utils";
import {MAX_DRIVER_WORK_GROUP_DURATION_HOURS} from "../../../../../constants";
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {
    addActivity,
    clearDialogData,
    editActivity,
    selectDialogData,
    selectPlannerType
} from "../../../../../store/plannerSlice";
import {selectAllItemTimesInPlannerItemGroupMemoized} from "../../../store/selectors";
import {selectToggledResourceType} from "../../../../../store/viewSlice";
import {shallowEqual} from "react-redux";
import {getDateFromTimeStr, getDateWithModifierFromHhNr} from "../../../../../utils/dateUtils";
import dayjs from "dayjs";
import TextField from "../../../../../components/Form/TextField/TextField";
import {PlannerType} from "../../../types";
import {workGroupItemTypesWithComment, workGroupItemTypesWithDistance} from "../../../../../API/workSheets/types";
import {getWorkGroupItemTypeOptions} from "../../../../../utils/formUtils";
import TimeBetweenShiftsLengthAlert from "../../../../Alert/TimeBetweenShiftsLengthAlert";
import DefectSelect from "../../../../InputFields/DefectSelect";
import {selectWorkScheduleItemById} from "../../../../../store/workScheduleItemSlice";


export default function AddOrEditActivityDialog() {
    const dispatch = useAppDispatch();
    const toggledResourceType = useAppSelector(selectToggledResourceType);
    const addActivityDialogData = useAppSelector(selectDialogData).addActivity;
    const editActivityDialogData = useAppSelector(selectDialogData).editActivity;
    const plannerType = useAppSelector(selectPlannerType);
    const groupId = addActivityDialogData?.groupId ?? editActivityDialogData?.groupId;
    const allItemTimesInPlannerGroup = useAppSelector(state => selectAllItemTimesInPlannerItemGroupMemoized(state, groupId ?? 0), shallowEqual);
    const busWorkSheet = useAppSelector(state => selectWorkScheduleItemById(state, toggledResourceType === ResourceType.VEHICLE ? (groupId ?? 0) : 0));
    if (!groupId || !allItemTimesInPlannerGroup) return <></>;

    const includesLunchOrDisruption = allItemTimesInPlannerGroup.some(item =>
        item.type === WorkGroupItemType.LUNCH_BREAK || item.type === WorkGroupItemType.DISRUPTION);

    const getDefaultFormData = (): ActivityForm | undefined => {
        if (addActivityDialogData) {
            const startTimeData = getDateWithModifierFromHhNr(addActivityDialogData.startHh);
            const endTimeData = getDateWithModifierFromHhNr(addActivityDialogData.endHh);

            return {
                type: toggledResourceType === ResourceType.DRIVER ? WorkGroupItemType.EXTRA_TIME : WorkGroupItemType.DEADHEADING,
                startTime: dayjs(startTimeData.date),
                startTimeIsOnNextDay: startTimeData.isOnNextDay,
                endTime: dayjs(endTimeData.date),
                endTimeIsOnNextDay: endTimeData.isOnNextDay,
                defectId: null,
                distance: '',
                comment: '',
            }
        }

        if (editActivityDialogData?.activity) return {
            ...editActivityDialogData.activity,
            startTime: getDateFromTimeStr(editActivityDialogData.activity.startTime),
            endTime: getDateFromTimeStr(editActivityDialogData.activity.endTime),
            comment: editActivityDialogData.activity.comment ?? '',
            defectId: editActivityDialogData.activity.defectId,
        };
    };

    const defaultFormData = getDefaultFormData();

    const getFilteredTypeOptions = (selectedType: WorkGroupItemType): WorkGroupItemType[] => {
        const excludedTypes: WorkGroupItemType[] = [WorkGroupItemType.TRIP_DEFINITION];
        if (plannerType === PlannerType.WORK_GROUP) {
            excludedTypes.push(WorkGroupItemType.MAINTENANCE);
        }

        return getWorkGroupItemTypeOptions(toggledResourceType, includesLunchOrDisruption, selectedType)
            .filter(type => !excludedTypes.includes(type) || type === selectedType);
    };

    const handleFormSubmitClick = (form: ActivityForm, formHelpers: FormikHelpers<ActivityForm>) => {
        if (form.startTime && form.endTime) {
            formHelpers.setSubmitting(true);

            if (addActivityDialogData && toggledResourceType === ResourceType.DRIVER && allItemTimesInPlannerGroup.length > 0
                && isDurationOverDriverWorkDayLimitWithNewItem(
                    allItemTimesInPlannerGroup[0].startHh,
                    allItemTimesInPlannerGroup[0].endHh,
                    getTimeInHoursFromDate(form.startTime, form.startTimeIsOnNextDay),
                    getTimeInHoursFromDate(form.endTime, form.endTimeIsOnNextDay)
                )
            ) {
                if (!window.confirm(`Tööpäev ületab lubatud ${MAX_DRIVER_WORK_GROUP_DURATION_HOURS} tunni piiri, kas soovid tegevuse siiski lisada?`)) {
                    formHelpers.setSubmitting(false);
                    return;
                }
            }

            if (addActivityDialogData) {
                dispatch(addActivity({groupId: groupId, form: form}))
                    .finally(() => formHelpers.setSubmitting(false));
            }
            if (editActivityDialogData) {
                dispatch(editActivity({groupId: groupId, activityId: editActivityDialogData.activityId, form: form}))
                    .finally(() => formHelpers.setSubmitting(false));
            }
        }
    };

    const handleChangeType = (formikProps: FormikProps<ActivityForm>) => (value: WorkGroupItemType) => {
        void formikProps.setValues({
            ...formikProps.values,
            type: value,
            distance: workGroupItemTypesWithDistance.includes(value) ? formikProps.values.distance : '',
            comment: workGroupItemTypesWithComment.includes(value) ? formikProps.values.comment : '',
        });
    };

    return (
        <Dialog open={!!groupId} onClose={() => dispatch(clearDialogData())}>
            <Box sx={{width: '450px', maxWidth: '100%', p: {xs: 0, sm: 2}}}>
                <DialogTitle>
                    <Typography variant="h5" component="div">
                        {addActivityDialogData ? 'Uus tegevus' : 'Tegevuse muutmine'}
                    </Typography>
                </DialogTitle>
                {defaultFormData &&
                    <Formik initialValues={defaultFormData} validationSchema={workGroupActivityValidationSchema} onSubmit={handleFormSubmitClick}>
                        {(formikProps: FormikProps<ActivityForm>) =>
                            <Form>
                                <DialogContent sx={{display: 'flex', flexDirection: 'column', pt: 0}}>
                                    <TimeBetweenShiftsLengthAlert values={formikProps.values} />
                                    <Select name="type"
                                            label="Tegevuse tüüp"
                                            options={getFilteredTypeOptions(formikProps.values.type)}
                                            translationFunction={getWorkGroupItemTypeTranslationFromStr}
                                            translationEnumType={WorkGroupItemType}
                                            disabled={!!editActivityDialogData}
                                            onChange={handleChangeType(formikProps)}
                                    />
                                    <ModifierStartAndEndTime />
                                    {formikProps.values.type === WorkGroupItemType.MAINTENANCE  && (busWorkSheet || formikProps.values.defectId) &&
                                        <Tooltip
                                            title={busWorkSheet ? undefined : 'Riket saab valida busside planeerja vaatest'}
                                            placement="top"
                                            arrow
											PopperProps={{
                                                modifiers: [
                                                    {name: 'offset', options: {offset: [0, -12]}}
                                                ],
                                            }}
                                        >
                                            <Box>
                                                <DefectSelect busId={busWorkSheet?.resourceId} date={busWorkSheet?.startDate} />
											</Box>
										</Tooltip>
                                    }
                                    {workGroupItemTypesWithDistance.includes(formikProps.values.type) &&
                                        <NumberTextField name="distance" label="Pikkus (km)" decimals={3} />
                                    }
                                    {workGroupItemTypesWithComment.includes(formikProps.values.type) && plannerType === PlannerType.WORK_SHEET &&
                                        <TextField
                                            name="comment"
                                            label="Kommentaar"
                                            multiline
                                            minRows={2}
                                            maxRows={5}
                                            required={false}
                                        />
                                    }
                                </DialogContent>
                                <DialogActions sx={{justifyContent: 'center', maxWidth: '100%', mb: 2}}>
                                    <Box maxWidth="50%">
                                        <Button
                                            text="Loobu"
                                            variant="outlined"
                                            onClick={() => dispatch(clearDialogData())}
                                            startIcon={<Clear />}
                                        />
                                    </Box>
                                    <Box maxWidth="50%">
                                        <Button disabled={formikProps.isSubmitting} text="Salvesta" type="submit" startIcon={<Check />} />
                                    </Box>
                                </DialogActions>
                            </Form>
                        }
                    </Formik>
                }
            </Box>
        </Dialog>
    );
}
