import {Box} from "@mui/material";
import {Role} from "../../../../types";
import {Region} from "../../../../API/region/types";
import React from "react";
import Checkbox from "../../../../components/Form/Checkbox/Checkbox";
import MultiSelect from "../../../../components/Form/Select/MultiSelect";
import {useAppSelector} from "../../../../hooks";
import {selectAllRegions} from "../../../../store/regionSlice";
import {getRoleTranslation} from "../../../../utils/enumTranslations";
import {useFormikContext} from "formik";

interface RoleOption {
    id: string;
    name: string;
}

export interface UserPermissionsFormData {
    roles: RoleOption[];
    allRegions: boolean;
    regions: Region[];
}

const UserPermissionsForm = () => {
    const regions = useAppSelector(selectAllRegions);
    const formikProps = useFormikContext<UserPermissionsFormData>();

    const roleOptions = Object.keys(Role).map((key) => ({
        id: key,
        name: getRoleTranslation(Role[key as keyof typeof Role]),
    }));

    return (
        <Box>
            <MultiSelect name="roles" label="Rollid" options={roleOptions} sx={{mb: 2}} />
            <MultiSelect name="regions" label="Piirkonnad" options={regions} disabled={formikProps.values.allRegions} sx={{mb: 0}} />
            <Checkbox name="allRegions" label="Kõik piirkonnad" />
        </Box>
    );
};

export default UserPermissionsForm;