import React, {useEffect} from 'react';
import {fetchTransportContractsByUser, selectTransportContractOptions} from "../../../../store/transportContractsSlice";
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import SelectWithId from "../../../../components/Form/Select/SelectWithId";
import {useAuth} from "../../../../contexts/AuthContext";

const TransportContractsSelect = () => {
    const dispatch = useAppDispatch();
    const { authenticatedUser } = useAuth();
    const transportContracts = useAppSelector(selectTransportContractOptions);

    useEffect(() => {
        dispatch(fetchTransportContractsByUser(authenticatedUser));
    }, []);

    return (
        <SelectWithId name="transportContractId" label="Veoleping*" options={transportContracts} />
    );
};

export default TransportContractsSelect;
