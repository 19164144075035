import {useAppDispatch, useAppSelector, useWorkScheduleSelectedDay} from "../../../../../../../hooks";
import {selectUniqueUnscheduledWorkGroups, selectUnscheduledWorkGroupsOnDay} from "../../../../store/selectors";
import {setIsEditModeActive, setSelectedEditModeWorkGroup} from "../../../../../../../store/workScheduleSlice";
import {UnscheduledWorkGroup} from "../../../../../../../store/workScheduleItemSlice";
import {Box, Switch, Tooltip, Typography} from "@mui/material";
import {green} from "@mui/material/colors";
import dayjs from "dayjs";
import {roundToDecimals} from "../../../../../../../utils/utils";
import {AutoFixHigh} from "@mui/icons-material";
import React, {useCallback} from "react";


const EditModeRow = () => {
    const {selectedDay} = useWorkScheduleSelectedDay();
    const isEditModeActive = useAppSelector(state => state.workSchedule.view.editModeData.isActive);
    const selectedEditModeWorkGroup = useAppSelector(state => state.workSchedule.view.editModeData.selectedWorkGroup);
    const unscheduledWorkGroupsOnDay = useAppSelector(state => selectUnscheduledWorkGroupsOnDay(state, selectedDay ?? ''));
    const unscheduledWorkGroups = useAppSelector(state => selectUniqueUnscheduledWorkGroups(state));

    const dispatch = useAppDispatch();

    const handleEditModeClick = () => dispatch(setIsEditModeActive(!isEditModeActive));

    const handleSelectEditModeWorkGroup = useCallback((unscheduledItem: UnscheduledWorkGroup) => {
        if (!isEditModeActive) {
            return;
        }
        dispatch(setSelectedEditModeWorkGroup(
            selectedEditModeWorkGroup && selectedEditModeWorkGroup.id === unscheduledItem.id
                ? undefined
                : unscheduledItem
        ));
    }, [isEditModeActive, selectedEditModeWorkGroup]);

    const renderUnscheduledWorkGroupCards = () => {
        const items: UnscheduledWorkGroup[] | undefined = selectedDay
            ? unscheduledWorkGroupsOnDay
            : isEditModeActive
                ? unscheduledWorkGroups
                : undefined;

        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                flexGrow: 1,
                alignContent: 'flex-start',
                maxHeight: '110px',
                overflowY: 'scroll'
            }}>
                {items?.map(item =>
                    <Box key={item.id + item.date} onClick={() => handleSelectEditModeWorkGroup(item)}
                         sx={{
                             m: .5,
                             px: 1,
                             py: .5,
                             backgroundColor: green[100],
                             border: '3px solid',
                             borderColor: selectedEditModeWorkGroup?.id === item.id ? 'secondary.main' : 'divider',
                             fontSize: 'small',
                             color: 'text.primary',
                             borderRadius: 1,
                             height: 'fit-content',
                             width: 'fit-content',
                             cursor: isEditModeActive ? 'pointer' : ''
                         }}
                    >
                        <Typography component="div" sx={{fontSize: '.8rem', lineHeight: 1.2}}>
                            {item.code}
                            {item.workScheduleItemStartDateTime && item.workScheduleItemEndDateTime && item.hours ?
                                ' (' + dayjs(item.workScheduleItemStartDateTime).format('HH:mm')
                                + ' - ' + dayjs(item.workScheduleItemEndDateTime).format('HH:mm') + ')'
                                + ' ' + roundToDecimals(item.hours, 1) + 'h'
                                : null}
                        </Typography>
                    </Box>
                )}
            </Box>
        );
    };

    return (
        <Box sx={{
            displayPrint: 'none',
            display: 'flex',
            alignItems: 'flex-start',
            maxHeight: '110px',
        }}>
            <Box sx={{display: 'flex', alignItems: 'center', width: '100px'}}>
                <Switch checked={isEditModeActive} onChange={handleEditModeClick} sx={{ml: -0.5}}/>
                <Tooltip title={`Lihtne lisamine ${isEditModeActive ? 'sisse' : 'välja'} lülitatud`} arrow
                         placement="top">
                    <AutoFixHigh fontSize="small" sx={{color: isEditModeActive ? 'primary.main' : 'text.secondary'}}/>
                </Tooltip>
            </Box>
            {(isEditModeActive || selectedDay) && renderUnscheduledWorkGroupCards()}
        </Box>
    );
};

export default EditModeRow;