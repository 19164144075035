import React, {useEffect} from "react";
import {Box, Typography, useTheme} from "@mui/material";
import {BusDetails, OdometerReadingWithBusId} from "../../../../../API/types";
import {Form, useFormikContext} from "formik";
import DetailViewButtons from "../../../../../components/Button/DetailViewButtons";
import routes from "../../../../../routes";
import {BusForm} from "../types";
import TextField from "../../../../../components/Form/TextField/TextField";
import MultiSelect from "../../../../../components/Form/Select/MultiSelect";
import Checkbox from "../../../../../components/Form/Checkbox/Checkbox";
import NumberTextField from "../../../../../components/Form/NumberTextField/NumberTextField";
import DatePicker from "../../../../../components/Form/DateTimePicker/DatePicker";
import {useAppSelector} from "../../../../../hooks";
import {selectAllRegions} from "../../../../../store/regionSlice";
import FuelCards from "./FuelCards";
import MultiAutocomplete from "./FuelAutoComplete";
import BusOdometerReadings from "./BusOdometerReadings";
import SelectWithId from "../../../../../components/Form/Select/SelectWithId";
import {updateAccountingRegionOnRegionsChange} from "../../../../../utils/formUtils";
import {useAuth} from "../../../../../contexts/AuthContext";
import {getRegionChoicesForUser, userCanEditResource} from "../../../../../utils/utils";
import Button from "../../../../../components/Button/Button";
import {Add} from "@mui/icons-material";

interface BudDetailsEditFormProps {
    bus: BusDetails | undefined;
    id: string | undefined;
    defaultFormValues: BusForm;
    handleAddOdometerReading: () => void;
    handleEditOdometerReading: (reading: OdometerReadingWithBusId) => void;
    handleDeleteOdometerReading: (reading: OdometerReadingWithBusId) => void;
}

const BusDetailsEditForm = ({
    bus,
    id,
    defaultFormValues,
    handleAddOdometerReading,
    handleEditOdometerReading,
    handleDeleteOdometerReading
}: BudDetailsEditFormProps) => {
    const theme = useTheme();
    const formikProps = useFormikContext<BusForm>();
    const regions = useAppSelector(selectAllRegions);
    const { authenticatedUser } = useAuth();
    const canEdit = userCanEditResource(authenticatedUser, bus);

    useEffect(() => {
        if (formikProps.isSubmitting) {
            formikProps.setSubmitting(false);
        }
    }, [formikProps.initialValues]);

    useEffect(() => {
        updateAccountingRegionOnRegionsChange(formikProps);
    }, [formikProps.values.regions]);

    const renderDetailViewButtons = () => {
        return (
            <DetailViewButtons
                id={id}
                defaultFormValues={defaultFormValues}
                formikProps={formikProps}
                listViewPath={routes.AUTHENTICATED.BUSES.ROOT}
            />
        );
    };

    const [regionChoices, accountingRegionChoices] = getRegionChoicesForUser(regions, authenticatedUser, bus);

    return (
        <Form>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2}}>
                <Box>
                    <Typography variant="h5" pt={{xs: 1, sm: 0}}>
                        {bus ? bus.licencePlateNumber : 'Uus buss'}
                    </Typography>
                </Box>
                <Box sx={{[theme.breakpoints.down('sm')]: {display: 'none'}}}>
                    {renderDetailViewButtons()}
                </Box>
            </Box>
            <Box sx={{
                display: 'flex', flexDirection: 'column', width: '400px', maxWidth: '100%',
                [theme.breakpoints.down('sm')]: {width: '100%'}
            }}>
                <TextField name="licencePlateNumber" label="Registrikood*" disabled={!canEdit} />
                <TextField name="make" label="Mark*" disabled={!canEdit} />
                <TextField name="model" label="Mudel*" disabled={!canEdit} />
                <TextField name="type" label="Masinmark*" disabled={!canEdit} />
                <NumberTextField
                    name="seatingCapacity"
                    label="Istekohad*"
                    disabled={!canEdit}
                    description="Vastavalt registreerimistunnistusele koos juhi- ja giidiistmega"
                />
                <NumberTextField name="standingCapacity" label="Seisukohad*" disabled={!canEdit} />
                <DatePicker name="registrationDate" label="Esmaregistreerimine*" disabled={!canEdit} />
                <MultiSelect name="regions" label="Planeerimispiirkond*" options={regionChoices} disabled={!canEdit} />
                <SelectWithId
                    name="accountingRegionId"
                    label="Arvelduspiirkond*"
                    options={accountingRegionChoices}
                    disabled={!canEdit}
                />
                <MultiAutocomplete name="fuelTypes" disabled={!canEdit} />
                <FuelCards name="fuelCards" disabled={!canEdit} />
                <Checkbox name="active" label="Aktiivne" disabled={!canEdit} />
            </Box>
            <Box sx={{[theme.breakpoints.up('sm')]: {display: 'none'}}}>
                {renderDetailViewButtons()}
            </Box>
            {bus && bus.odometerReadings.length > 0 &&
                <Box sx={{my: 1}}>
					<Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end', mb: 2}}>
                        <Typography variant="h6">Odomeetri näidud ja tankimised</Typography>
						<Button text="Lisa näit" startIcon={<Add/>} onClick={handleAddOdometerReading} />
                    </Box>
                    <BusOdometerReadings
                        odometerReadings={bus?.odometerReadings}
                        handleEditOdometerReading={handleEditOdometerReading}
                        handleDeleteOdometerReading={handleDeleteOdometerReading}
                    />
                </Box>
            }
        </Form>
    );
};

export default BusDetailsEditForm;
