import React from 'react';
import {Box, Typography} from '@mui/material';
import {useAuth} from "../../../contexts/AuthContext";
import {AuthenticatedUser, Role} from "../../../types";
import ListView from "./DriverDashboard/views/listView";
import {useFetchDriverAgenda} from "../../../hooks";


export const renderUserGreeting = (authenticatedUser: AuthenticatedUser | null) => {
    return (
        <Typography variant="h6">
            Tere{authenticatedUser?.firstName ? `, ${authenticatedUser?.firstName}!`: '!'}
        </Typography>
    );
};

const DriverDashboard = () => {
    useFetchDriverAgenda();

    return (
        <Box sx={{maxWidth: {'xs': '100%', 'sm': '400px'}}}>
            <ListView />
        </Box>
    );
};

export default function Dashboard() {
    const { authenticatedUser } = useAuth();

    return (
        <Box>
            {authenticatedUser?.roles.includes(Role.DRIVER) ?
                <DriverDashboard />
                :
                renderUserGreeting(authenticatedUser)
            }
        </Box>
    );
}
