import React, {useEffect, useState} from 'react';
import SelectWithId from "../../components/Form/Select/SelectWithId";
import {DefectSimple} from "../../API/defects/types";
import {SelectOptionWithId, SelectOptionWithIdAndPermission} from "../../types";
import {getDisplayDate} from "../../utils/dateUtils";
import {getRelevantDefects} from "../../API/bus/api";
import {useField} from "formik";
import {getDefect} from "../../API";
import {mapErrors} from "../../utils/errorMapping";
import {useAppDispatch} from "../../hooks";
import {getSimpleDefect} from "../../utils/formUtils";


interface DefectSelectProps {
    busId?: number | null;
    date?: string;
}

const DefectSelect = ({ busId, date }: DefectSelectProps) => {
    const dispatch = useAppDispatch();
    const [relatedDefects, setRelatedDefects] = useState<DefectSimple[]>([]);
    const [field] = useField('defectId');

    useEffect(() => {
        if (relatedDefects.length) setRelatedDefects([]);

        if (busId && date) {
            getRelevantDefects(busId, date)
                .then(resultDefects => {
                    handleSetDefects(resultDefects);
                })
                .catch((error) => {
                    dispatch({type: 'error', text: mapErrors(error) ?? 'Rikete pärimisel ilmnes viga'});
                });
        } else if (field.value) {
            handleSetDefects([]);
        }
    }, [busId]);

    const handleSetDefects = (resultDefects: DefectSimple[]) => {
        if (field.value && !resultDefects.find(defect => defect.id === field.value)) {
            getDefect(field.value)
                .then(resultDefect => {
                    setRelatedDefects([...resultDefects, getSimpleDefect(resultDefect)]);
                })
                .catch((error) => {
                    dispatch({type: 'error', text: mapErrors(error) ?? 'Valitud rikke pärimisel ilmnes viga'});
                });
        } else {
            setRelatedDefects(resultDefects);
        }
    };

    const getDefectOptions = (relatedDefects: DefectSimple[]): SelectOptionWithIdAndPermission[] => relatedDefects
        .sort((a,b) => a.from.localeCompare(b.from))
        .map<SelectOptionWithId>(d => ({ id: d.id, name: getDisplayDate(d.from), description: d.description }));

    if (busId && !date) return null;

    // handling external users not having access to the resource
    if (field.value && !relatedDefects.find(relatedDefect => relatedDefect.id === field.value)) return null;

    return (
        <SelectWithId
            name="defectId"
            label="Rike"
            options={getDefectOptions(relatedDefects)}
            noOptionsText={`Kuupäeval${date ? ` ${getDisplayDate(date)}` : ''} bussile aktiivseid rikkeid ei leitud`}
            disabled={!busId}
        />
    );
};

export default DefectSelect;
