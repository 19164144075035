import {Box, useTheme} from '@mui/material';
import React, {useContext, useState} from 'react';
import {PLANNER_ROW_HEIGHT} from "../../constants";
import {AddCircleOutline} from "@mui/icons-material";
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import {selectPlannerTimelineMemoized, setDialogData} from "../../../../store/plannerSlice";
import {getFirstAvailableTimeWithoutCollisions} from "../../utils/collisionUtils";
import {ToastContext} from "../../../../contexts/ToastContext";
import { EntityId } from '@reduxjs/toolkit';
import {PlannerItemStartAndEndHourWithTypeAndId} from "../../types";


interface TimelineBackgroundCellProps {
    hour: string;
    hourIndex: number;
    groupId: EntityId;
    itemTimesInGroup?: PlannerItemStartAndEndHourWithTypeAndId[];
}

const TimelineBackgroundCell = ({ hour, hourIndex, groupId, itemTimesInGroup }: TimelineBackgroundCellProps) => {
    const theme = useTheme();
    const { addToast } = useContext(ToastContext);
    const dispatch = useAppDispatch();

    const plannerTimeline = useAppSelector(selectPlannerTimelineMemoized);

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const handleOpenActivityDialog = () => {
        if (groupId && itemTimesInGroup) {
            const startAndEndHh = getFirstAvailableTimeWithoutCollisions(
                plannerTimeline.startTime + hourIndex,
                itemTimesInGroup
            );

            if (startAndEndHh) {
                dispatch(setDialogData({
                    addActivity: {
                        groupId: groupId,
                        startHh: startAndEndHh.startHh,
                        endHh: startAndEndHh.endHh
                    }
                }));
            } else {
                addToast({type: 'error', text: 'Valitud aeg on tegevuse lisamiseks liiga lühike'});
            }
        }
    };

    return (
        <Box
            key={hour}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleOpenActivityDialog}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRight: `1px dashed`,
                borderColor: hourIndex === plannerTimeline.duration - 1 ? 'transparent' : theme.palette.action.selected,
                flexGrow: 1,
                width: `${100 / plannerTimeline.duration}%`,
                height: `${PLANNER_ROW_HEIGHT - 2}px`,
                zIndex: 'inherit',
                ':hover': groupId ? {backgroundColor: 'action.hover', cursor: 'pointer'} : {}
            }}
        >
            {Number(groupId) > 0 && isHovered && <AddCircleOutline sx={{color: 'common.white'}} />}
        </Box>
    );
};

export default TimelineBackgroundCell;
