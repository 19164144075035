import React from "react";
import {clearDialogData, mergeTrip, selectDialogData} from "../../../../../store/plannerSlice";
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {Check, Clear} from "@mui/icons-material";
import Dialog from "../../../../../components/Dialog/Dialog";
import {Typography} from "@mui/material";

export default function ConfirmMergeTrip() {
    const dispatch = useAppDispatch();
    const mergeTripDialogData = useAppSelector(selectDialogData).mergeTrip;

    if (!mergeTripDialogData) return null;

    const onConfirm = () => {
        dispatch(mergeTrip({tripId: mergeTripDialogData.tripId, originalSegments: mergeTripDialogData.originalSegments, confirmed: true}));
    };

    const onCancel = () => dispatch(clearDialogData());

    return <Dialog
        open={!!mergeTripDialogData}
        onClose={onCancel}
        buttons={[
            {onClick: onCancel, text: 'Loobu', color: 'secondary', startIcon: <Clear />},
            {onClick: onConfirm, text: 'Kinnita', color: 'primary', startIcon: <Check />},
        ]}
    >
        <Typography>{`Kokkuliidetud reis ${mergeTripDialogData.tripCode} eemaldatakse töögruppidest:`}</Typography>
        <ul>
        {mergeTripDialogData.removedFromWorkGroupVersions.map((rfwgv, index) =>
            <li key={index}>
                <Typography>{rfwgv.code}</Typography>
            </li>
        )}
        </ul>
    </Dialog>;
}