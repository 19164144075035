import Dialog from "@mui/material/Dialog";
import {Box, Typography} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import React, {useEffect, useState} from "react";
import {Region, WorkGroupItemType} from "../../../../../API/types";
import RegionSelect from "../../../../InputFields/RegionSelect";
import {FormikHelpers} from "formik";
import {TripForm} from "./WorkGroupFormWrapper/types";
import OtherRegionWorkGroupTripDefinitionForm from "./WorkGroupFormWrapper";
import {
    addUnassignedOtherRegionWorkGroupTripDefinition,
    clearDialogData,
    selectDialogData,
    selectPlannerType,
} from "../../../../../store/plannerSlice";
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {selectAllRegions, selectSelectedRegion} from "../../../../../store/regionSlice";
import {PlannerType} from "../../../types";
import OtherRegionWorkSheetWorkItemForm from "./WorkSheetFormWrapper";
import {WorkItemForm} from "./WorkSheetFormWrapper/types";
import {addOtherRegionUnassignedWorkItem} from "../../../store/actions";
import {selectToggledResourceType} from "../../../../../store/viewSlice";
import {selectSelectedDay} from "../../../../../scenes/authenticated/workSheets/store/selectors";
import {appliesOnDate} from "../../../../../utils/repetitionRulesUtils";
import {fetchCalendarEntries, selectAllCalendarEntries} from "../../../../../store/calendarEntriesSlice";


export default function AddOtherRegionTripDialog() {
    const dispatch = useAppDispatch();
    const selectedRegion = useAppSelector(selectSelectedRegion);
    const regions = useAppSelector(selectAllRegions);
    const plannerType = useAppSelector(selectPlannerType);
    const toggledResourceType = useAppSelector(selectToggledResourceType);
    const selectedDay = useAppSelector(selectSelectedDay);
    const addOtherRegionTripDialogData = useAppSelector(selectDialogData).addOtherRegionTrip;
    const calendarEntries = useAppSelector(selectAllCalendarEntries);
    const [selectedOtherRegion, setSelectedOtherRegion] = useState<Region | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!calendarEntries) dispatch(fetchCalendarEntries());
        if (selectedRegion) {
            const firstUnselectedRegion = regions.find(region => region.id !== selectedRegion?.id);
            if (firstUnselectedRegion) setSelectedOtherRegion(firstUnselectedRegion);
        }
    }, []);

    if (!addOtherRegionTripDialogData) return <></>;

    const handleUpdateSelectedRegion = (region: Region) => {
        setIsLoading(true);
        setSelectedOtherRegion(region);
    };

    const handleWorkGroupTripDefinitionFormSubmitClick = (form: TripForm, formikHelpers: FormikHelpers<TripForm>) => {
        if (form.trip) {
            dispatch(addUnassignedOtherRegionWorkGroupTripDefinition({
                ...form.trip,
                seasonalStartDate: form.trip.startDate,
                seasonalEndDate: form.trip.endDate,
                type: WorkGroupItemType.TRIP_DEFINITION,
                workGroupsOfOppositeType: form.trip.workGroups.filter(wg => wg.type !== toggledResourceType && appliesOnDate(wg, selectedDay, calendarEntries))
            }));
            formikHelpers.setSubmitting(false);
            dispatch(clearDialogData());
        }
    };

    const handleTripDefinitionWorkItemFormSubmitClick = (form: WorkItemForm, formikHelpers: FormikHelpers<WorkItemForm>) => {
        if (form.workItem) {
            dispatch(addOtherRegionUnassignedWorkItem(form.workItem));
            formikHelpers.setSubmitting(false);
            dispatch(clearDialogData());
        }
    };

    return (
        <Dialog open={addOtherRegionTripDialogData} onClose={() => dispatch(clearDialogData())}>
            <Box sx={{width: '400px', maxWidth: '100%', p: {xs: 0, sm: 2}}}>
                <DialogTitle sx={{maxWidth: '100%', display: 'flex', justifyContent: 'space-between'}}>
                    <Typography variant="h5" component="div">Piirkonnaväline reis</Typography>
                </DialogTitle>
                <DialogContent sx={{display: 'flex', flexDirection: 'column', pt: 0}}>
                    {selectedOtherRegion &&
                        <RegionSelect
                            regions={regions.filter(region => region.id !== selectedRegion?.id)}
                            selectedRegion={selectedOtherRegion}
                            updateSelectedRegion={handleUpdateSelectedRegion}
                            sx={{width: '100%', my: 1}}
                        />
                    }
                    {plannerType === PlannerType.WORK_GROUP &&
                        <OtherRegionWorkGroupTripDefinitionForm
                            selectedOtherRegion={selectedOtherRegion}
                            onSubmit={handleWorkGroupTripDefinitionFormSubmitClick}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                        />
                    }
                    {plannerType === PlannerType.WORK_SHEET &&
                        <OtherRegionWorkSheetWorkItemForm
                            selectedOtherRegion={selectedOtherRegion}
                            onSubmit={handleTripDefinitionWorkItemFormSubmitClick}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                        />
                    }
                </DialogContent>
            </Box>
        </Dialog>
    );
}
