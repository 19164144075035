import React, {useContext, useState} from "react";
import {Box, Typography, useTheme} from "@mui/material";
import {Contract, DriverContract} from "../../../../../API/types";
import DataGrid from "../../../../../components/DataGrid/DataGrid";
import {GridColDef} from "@mui/x-data-grid";
import {Add} from "@mui/icons-material";
import {getContractTranslationFromStr} from "../../../../../utils/enumTranslations";
import {sortByStartDate, strToDecimal} from "../../../../../utils/utils";
import Button from "../../../../../components/Button/Button";
import DriverContractDialog from "./DriverContractDialog";
import {FormikHelpers} from "formik";
import {DriverContractForm} from "../types";
import {getEnumFromStr} from "../../../../../utils/formUtils";
import {ToastContext} from "../../../../../contexts/ToastContext";
import {mapErrors} from "../../../../../utils/errorMapping";
import {createDriverContract, updateDriverContract} from "../../../../../API/driver/api";
import {GridValueGetterParams} from "@mui/x-data-grid/models/params/gridCellParams";
import ActionIcon from "../../../../../components/Icon/ActionIcon";
import {Permission} from "../../../../../types";
import {getDateString, getDisplayDate} from "../../../../../utils/dateUtils";

interface DriverContractsProps {
    driverId: number;
    contracts: DriverContract[];
    canEdit: boolean;
}

export default function DriverContracts({driverId, contracts, canEdit}: DriverContractsProps) {
    const theme = useTheme();
    const { addToast } = useContext(ToastContext);
    const [rows, setRows] = useState<DriverContract[]>(contracts);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedContract, setSelectedContract] = useState<DriverContract | null | undefined>(undefined);
    const canAddNew = !rows.find(contract => contract.endDate === null);

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedContract(undefined);
    }

    const handleAddNewContract = () => {
        setSelectedContract(null);
        setOpenDialog(true);
    }

    const handleEditClick = (id: number) => {
        const contract = rows.find(contract => contract.id === id);
        if (contract) {
            setSelectedContract(contract)
            setOpenDialog(true);
        }
    }
    const handleFormSubmit = (form: DriverContractForm, formHelpers: FormikHelpers<DriverContractForm>, id?: number) => {
        const contractType = getEnumFromStr(form.type, Contract);

        if (contractType && form.startDate) {
            const request = {
                number: form.number,
                type: contractType,
                startDate: getDateString(form.startDate),
                endDate: form.endDate ? getDateString(form.endDate) : null,
                nominalWeeklyWorkingHours: strToDecimal(form.nominalWeeklyWorkingHours),
                hourlyRate: (form.hourlyRate !== '') ? strToDecimal(form.hourlyRate) : null,
                monthlyPay: (form.monthlyPay !== '') ? strToDecimal(form.monthlyPay) : null,
            }
            if (id) {
                const selectedRowIndex = rows.findIndex(row => row.id === id)
                updateDriverContract( {id: id, ...request})
                    .then(() => {
                        setRows(prevState => {
                            const updatedRows = prevState ? [...prevState] : [];
                            updatedRows[selectedRowIndex] = {id: id, ...request};
                            return updatedRows;
                        });
                        addToast({type: 'success', text: 'Töölepingu andmed edukalt uuendatud'});
                        handleCloseDialog();
                    })
                    .catch(error => {
                        addToast({type: 'error', text: mapErrors(error) ?? 'Töölepingu uuendamisel esines tõrge'});
                    })
                    .finally(() => {
                        formHelpers.setSubmitting(false);
                    });
            } else {
                createDriverContract(driverId, {id: null, ...request})
                    .then(result => {
                        setRows([...rows, result])
                        addToast({type: 'success', text: 'Uus tööleping edukalt loodud'});
                        handleCloseDialog();
                    })
                    .catch(error => {
                        addToast({type: 'error', text: mapErrors(error) ?? 'Töölepingu loomisel esines tõrge'});
                    })
                    .finally(() => {
                        formHelpers.setSubmitting(false);
                    });
            }
        }
    }

    function getPayDisplayValue(params: GridValueGetterParams<DriverContract>) {
        if ((params.row.type === Contract.EMPLOYMENT_CONTRACT || params.row.type === Contract.LABOR_LEASING_AGREEMENT) && params.row.monthlyPay !== null) {
            return `${params.row.monthlyPay.toLocaleString('et-EE')} € / kuus`
        } else if (params.row.type === Contract.SERVICE_CONTRACT && params.row.hourlyRate !== null) {
            return `${params.row.hourlyRate.toLocaleString('et-EE')} € / h`
        } else {
            return '';
        }
    }

    const columns: GridColDef[] = [
        {
            field: 'number',
            headerName: 'Lepingu number',
            flex: 1,
            minWidth: 140,
        },
        {
            field: 'type',
            headerName: 'Lepingu tüüp',
            flex: 1,
            minWidth: 140,
            valueFormatter: (params) => getContractTranslationFromStr(params.value),
        },
        {
            field: 'startDate',
            headerName: 'Kehtivuse algus',
            flex: 1,
            minWidth: 140,
            valueFormatter: (params) => getDisplayDate(params.value),
        },
        {
            field: 'endDate',
            headerName: 'Kehtivuse lõpp',
            flex: 1,
            minWidth: 140,
            valueFormatter: (params) => params.value ? getDisplayDate(params.value) : null,
        },
        {
            field: 'nominalWeeklyWorkingHours',
            headerName: 'Töötunde nädalas',
            flex: 1,
            minWidth: 140,
        },
        {
            field: 'pay',
            headerName: 'Töötasu',
            valueGetter: getPayDisplayValue,
            flex: 1,
            minWidth: 110,
        },
        {
            field: 'Menüü',
            headerName: '',
            align: 'right',
            flex: 1,
            minWidth: 50,
            renderCell: (params) =>
                canEdit
                    ? <ActionIcon type="EDIT" id={params.row.id} handleClick={handleEditClick} navPermission={Permission.UpdateDrivers} />
                    : null
        },
    ];

    return (
        <Box sx={{mt: 2}}>
            <Box sx={{
                mb: 1,
                [theme.breakpoints.up('sm')]: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                }
            }}>
                <Typography variant="h6">Töölepingud</Typography>
                {canEdit &&
                    <Box textAlign="right">
                        <Button
                            text="Lisa tööleping"
                            onClick={handleAddNewContract}
                            startIcon={<Add />}
                            disabled={!canAddNew}
                        />
                        {!canAddNew && <Typography variant="body2" color="textSecondary">Uue töölepingu lisamiseks tuleb eelnev leping lõpetada</Typography>}
                    </Box>
                }
            </Box>
            {rows && rows.length >0 && <DataGrid columns={columns} rows={[...rows].sort(sortByStartDate)} />}
            {selectedContract !== undefined &&
                <DriverContractDialog
                    open={openDialog}
                    contract={selectedContract}
                    handleCloseDialog={handleCloseDialog}
                    handleFormSubmit={handleFormSubmit}
                />
            }
        </Box>
    );
}