import {WorkSheetDetails as ApiWorkSheetDetails} from "../../../../../API/workSheets/types";
import {OdometerReadingWithBusId} from "../../../../../API/bus/types";
import {GridColDef, GridRenderCellParams, GridValueFormatterParams} from "@mui/x-data-grid";
import {decimalToDisplayStr, decimalToFixedDisplayStr} from "../../../../../utils/utils";
import {Box, IconButton, Tooltip, Typography} from "@mui/material";
import {Add, Check, Delete, Edit, WarningAmberRounded} from "@mui/icons-material";
import {getFuelTranslation} from "../../../../../utils/enumTranslations";
import Button from "../../../../../components/Button/Button";
import DataGrid from "../../../../../components/DataGrid/DataGrid";
import React from "react";
import {OdometerReadingRow} from "./WorkSheetDetails";
import {formatTimeAtDate} from "../../../../../utils/dateUtils";
import {ResourceType} from "../../../../../API/workGroup/types";

interface OdometerReadingsProps {
    workSheet: ApiWorkSheetDetails,
    secondaryWorkSheets: ApiWorkSheetDetails[],
    odometerReadingRows: OdometerReadingRow[];
    handleEditOdometerReading: (reading: OdometerReadingWithBusId) => void;
    handleAddOdometerReading: () => void;
    handleDeleteOdometerReading: (reading: OdometerReadingWithBusId) => void;
}

const OdometerReadings = ({
    workSheet,
    secondaryWorkSheets,
    odometerReadingRows,
    handleEditOdometerReading,
    handleAddOdometerReading,
    handleDeleteOdometerReading,
}: OdometerReadingsProps) => {
    const readingColumns: GridColDef[] = [
        {
            field: 'type',
            headerName: 'Tüüp',
            sortable: false,
            disableColumnMenu: true,
            width: 100,
        },
        {
            field: 'licencePlateNumber',
            headerName: 'Reg-nr',
            sortable: false,
            disableColumnMenu: true,
            width: 80,
        },
        {
            field: 'dateTime',
            headerName: 'Aeg',
            sortable: false,
            disableColumnMenu: true,
            width: 90,
            valueFormatter: (params: GridValueFormatterParams) => formatTimeAtDate(params.value, workSheet.startDate),
        },
        {
            field: 'reading',
            headerName: 'Näit',
            sortable: false,
            disableColumnMenu: true,
            width: 100,
            valueFormatter: (params: GridValueFormatterParams) => decimalToDisplayStr(params.value) + ' km'
        },
        {
            field: 'expectedTotalDistance',
            headerName: 'Planeeritud läbisõit',
            sortable: false,
            disableColumnMenu: true,
            width: 160,
            valueFormatter: (params: GridValueFormatterParams) => decimalToFixedDisplayStr(params.value) + (params.value ? ' km' : '')
        },
        {
            field: 'distanceDiff',
            headerName: 'Läbisõidu erinevus',
            sortable: false,
            disableColumnMenu: true,
            width: 160,
            renderCell: (params: GridRenderCellParams) =>
                params.row.type === 'Lõppnäit' ?
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        {params.row.distanceDiff !== undefined && Math.abs(params.row.distanceDiff) < 2 ?
                            <Check color="success" fontSize="small" sx={{pr: 1}}/>
                            :
                            <WarningAmberRounded color="warning" fontSize="small" sx={{pr: 1}}/>
                        }
                        {params.row.distanceDiff === undefined ?
                            <Typography variant="body2" fontStyle="italic">algnäit puudub</Typography>
                            :
                            decimalToFixedDisplayStr(Math.abs(params.value)) + ' km ' + (params.value === 0 ? '' : params.value < 0 ? 'väiksem' : 'suurem')
                        }
                    </Box>
                    :
                    <></>
        },
        {
            field: 'fuelType',
            headerName: 'Kütuse liik',
            sortable: false,
            disableColumnMenu: true,
            width: 100,
            valueFormatter: (params) => getFuelTranslation(params.value)
        },
        {
            field: 'fuelAmount',
            headerName: 'Tangitud kogus',
            sortable: false,
            disableColumnMenu: true,
            width: 130,
            valueFormatter: (params) => decimalToFixedDisplayStr(params.value, 2)
        },
        {
            field: 'menu',
            headerName: '',
            sortable: false,
            disableColumnMenu: true,
            align: 'right',
            minWidth: 80,
            flex: 1,
            renderCell: (params: GridRenderCellParams) =>
                <>
                    <Tooltip title="Muuda" arrow placement="top">
                        <IconButton size="small" onClick={() => handleEditOdometerReading(params.row)}>
                            <Edit sx={{height: '20px', width: '20px'}}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Kustuta" arrow placement="top">
                        <IconButton size="small" onClick={() => handleDeleteOdometerReading(params.row)}>
                            <Delete sx={{height: '20px', width: '20px'}}/>
                        </IconButton>
                    </Tooltip>
                </>
        },
    ];

    const showOdometerReadings = workSheet.resourceType === ResourceType.DRIVER && secondaryWorkSheets.length > 0;

    return (showOdometerReadings &&
        <Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: {xs: 'start', sm: 'end'},
                flexDirection: {xs: 'column', sm: 'row'},
                pt: 3,
                pb: 1
            }}>
                <Typography variant="h6">
                    Odomeetri näidud ja tankimised
                </Typography>
                <Button text="Lisa näit" startIcon={<Add/>} onClick={handleAddOdometerReading}/>
            </Box>
            <DataGrid rows={odometerReadingRows} columns={readingColumns}/>
        </Box>
    );
};

export default OdometerReadings;
