import React from 'react';
import {IconButton, Tooltip, useTheme} from "@mui/material";
import {MultipleStop} from "@mui/icons-material";
import {EntityId} from "@reduxjs/toolkit";
import {addPreparationAndFinishingTime} from "../../../../../store/plannerSlice";
import {useAppDispatch} from "../../../../../hooks";


interface WorkSheetCardMenuProps {
    groupId: EntityId;
}

const WorkSheetCardMenu = ({groupId}: WorkSheetCardMenuProps) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();

    return (
        <Tooltip title="Lisa ettevalmistus- ja lõpetusaeg" arrow placement="top">
            <IconButton
                onClick={() => dispatch(addPreparationAndFinishingTime(groupId))}
                sx={{
                    height: '21px',
                    width: '21px',
                    border: '1px solid',
                    borderColor: theme.palette.divider,
                    boxShadow: '0px 0px 3px lightgrey',
                }}
            >
                <MultipleStop sx={{fontSize: '15px'}} />
            </IconButton>
        </Tooltip>
    );
};

export default WorkSheetCardMenu;
