import {Dayjs} from "dayjs";
import {EntityId} from "@reduxjs/toolkit";
import {useAppSelector} from "../../../../../../../hooks";
import {selectActivityByDayAndId, selectPrevWeekDays, selectRequestByDayAndId} from "../../../../store/selectors";
import {shallowEqual} from "react-redux";
import React from "react";
import CustomCell from "./CustomCell";
import {collapsedColumnSx, DEFAULT_CELL_WIDTH, disabledBackground} from "../index";
import {useLocalStorage} from "usehooks-ts";
import {getCellLabel} from "./ItemCell";
import {selectSelectedRegion} from "../../../../../../../store/regionSlice";

const PrevWeekItemCell = ({day, resourceId, isFirst, isLast}: {
    day: Dayjs,
    resourceId: EntityId,
    isFirst: boolean,
    isLast: boolean,
}) => {
    const activity = useAppSelector(selectActivityByDayAndId(resourceId, day), shallowEqual);
    const request = useAppSelector(selectRequestByDayAndId(resourceId, day), shallowEqual);
    const selectedRegion = useAppSelector(selectSelectedRegion);

    const item = activity ? activity : request;
    const content = getCellLabel(item, selectedRegion);

    return (
        <CustomCell key={day.toString()}
            sx={{
                backgroundColor: disabledBackground,
                borderLeftWidth: isFirst ? '2px' : undefined,
                borderRightWidth: isLast ? '3px' : undefined,
                displayPrint: 'none',
                flexGrow: 0, minWidth: DEFAULT_CELL_WIDTH+'px', maxWidth: DEFAULT_CELL_WIDTH+'px'
            }}
            content={content}
            tooltip={item?.comment}
        />
    );
};

const PrevWeekItems = ({resourceId}: {
    resourceId: EntityId,
}) => {
    const prevWeekDays = useAppSelector(selectPrevWeekDays);
    const [showPrevWeek] = useLocalStorage('workScheduleShowPrevWeek', false);

    return (
        <>
            {showPrevWeek
                ? prevWeekDays.map((day, dayIndex) =>
                    <PrevWeekItemCell
                        key={day.format('YYYY-MM-DD')}
                        day={day}
                        resourceId={resourceId}
                        isFirst={0 === dayIndex}
                        isLast={prevWeekDays.length === dayIndex + 1}
                    />)
                : <CustomCell
                    content=""
                    sx={collapsedColumnSx}
                />
            }
        </>
    )
};

export default PrevWeekItems;
